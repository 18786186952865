<template>
    <div class="article-body" :class="`article-body--text-${textAlign}`">
        <div v-for="(block, i) in blocks" :key="i">
            <!-- headings -->
            <div
                v-if="block.type === 'header'"
                class="article-title"
                :class="{ 'article-title--no-mb': hasListAfter(i) }"
            >
                <a v-if="block.data.anchor" :id="block.data.anchor" />
                <component :is="getTitleElement(block)" :id="block.id">
                    <a-dangerous-html :content="block.data.text" tag="span" />
                </component>
            </div>

            <!-- old paragraphs came from drupal -->
            <div v-else-if="block.type === 'div'" class="article-paragraph">
                <a-dangerous-html :content="block.data.text" />
            </div>

            <!-- paragraphs -->
            <p v-else-if="block.type === 'paragraph'" class="article-paragraph">
                <a-dangerous-html :content="block.data.text" tag="span" />
            </p>

            <!-- images -->
            <div v-else-if="block.type === 'image'">
                <figure
                    :class="[
                        'article-image',
                        {
                            'article-image--with-border': block.data.withBorder,
                            'article-image--stretched': block.data.stretched,
                            'article-image--with-background': block.data.withBackground,
                        }
                    ]"
                >
                    <a-picture :link="block.data.file.url" fit="contain" />
                </figure>
                <div v-if="block.data.caption" class="article-image-caption">
                    {{ block.data.caption }}
                </div>
            </div>

            <!-- ordered/unordered lists -->
            <component
                :is="getListElement(block)"
                v-else-if="block.type === 'list'"
                class="article-list"
            >
                <li v-for="(listItem, j) in block.data.items" :key="j">
                    <a-dangerous-html :content="listItem" tag="span" />
                </li>
            </component>

            <!-- highlights snippet -->
            <div
                v-else-if="block.type === 'highlighttool'"
                :key="`${block.id}-highlighttool`"
                class="highlight-tool"
                :class="{'left-align': block.data?.alignment === 'left'}"
            >
                <a-label v-if="block.data?.is_labeled" v-bind="block.data?.label">
                    {{ block.data.label.text }}
                </a-label>
                <a-dangerous-html :content="replaceHighlightBlockLinks(block.data.text)" />
            </div>

            <!-- product review -->
            <div v-else-if="block.type === 'quotetool'" class="article-review">
                <div v-if="block.data.text" class="article-review-text">
                    {{ block.data.text }}
                </div>
                <div v-if="block.data.author" class="article-review-author">
                    {{ block.data.author }}
                </div>
            </div>

            <!-- asset/cross link snippet -->
            <dynamic-link
                v-else-if="block.type === 'asset'"
                class="article-asset"
                :to="block.data.link"
                native-type="a"
            >
                <div class="article-asset-text">
                    <div v-if="block.data.type" class="article-asset-type">
                        {{ block.data.type }}
                    </div>
                    <div v-if="block.data.title" class="article-asset-title">
                        {{ block.data.title }}
                    </div>
                </div>
                <a-picture v-if="block.data.image" :link="block.data.image" fit="cover" />
            </dynamic-link>

            <!-- product snippet -->
            <div v-else-if="block.type === 'product'" class="article-cta">
                <div class="article-cta-text">
                    <div class="article-cta-title">
                        <a-logo type="full" />
                        | {{ block.data.title }}
                    </div>
                    <div class="article-cta-label">
                        | {{ block.data.label }}
                    </div>
                </div>
                <div class="article-cta-button-wrap">
                    <a-button
                        v-if="block.data.button"
                        class="article-cta-button"
                        v-bind="block.data.button"
                    />
                </div>
            </div>

            <!-- link/download snippet -->
            <div v-else-if="block.type === 'button'" class="article-action">
                <div class="article-action-text">
                    {{ block.data.text }}
                </div>
                <div class="article-action-button-wrap">
                    <a-button
                        v-if="block.data.button"
                        class="article-action-button"
                        v-bind="block.data.button"
                    />
                </div>
            </div>

            <!-- products snippet -->
            <div v-else-if="block.type === 'products'" class="article-products">
                <div
                    v-for="(product, j) in block.data.products"
                    :key="j"
                    class="article-product-item"
                >
                    <a-product-card
                        class="article-product-card"
                        v-bind="getProductCardProps(product)"
                    >
                        {{ product.text }}
                    </a-product-card>
                </div>
            </div>

            <!-- embed video -->
            <div v-else-if="block.type === 'embed'" class="article-video">
                <iframe
                    :id="block.id"
                    class="article-video-frame"
                    type="text/html"
                    width="100%"
                    :src="`https://www.youtube.com/embed/${block.data.id}?modestbranding=1`"
                />
            </div>

            <!-- table -->
            <div
                v-else-if="block.type === 'table'"
                :key="block.id"
                class="article-table"
                :class="getTableClass(block)"
            >
                <div v-for="(row, j) in block.data.content" :key="j" class="article-table-row">
                    <a-dangerous-html
                        v-for="(cell, k) in row"
                        :key="k"
                        class="article-table-cell"
                        :content="cell"
                    />
                </div>
            </div>

            <!-- cta 1 2 3 products -->
            <div v-else-if="block.type === 'ctaoneproducttool'" :key="block.id">
                <a-product-card
                    class="cta-product cta-one"
                    v-bind="{ ...getProductToolData(block.data) }"
                >
                    {{ block.data.text }}
                </a-product-card>
            </div>

            <div v-else-if="hasMultipleProducts(block)" :key="block.id" class="cta-products">
                <div v-for="(product, j) in block.data.cards" :key="j" class="post-product-item">
                    <a-product-card
                        class="post-product"
                        v-bind="{ ...getProductToolData(product) }"
                    >
                        {{ product.text }}
                    </a-product-card>
                </div>
            </div>

            <!-- code block -->
            <div v-else-if="['codeblocktool', 'code'].includes(block.type)" :key="block.id" class="codeblock-tool">
                <pre v-html="highlightCode(block.data)" />
            </div>

            <!-- highlight product card -->
            <dynamic-link
                v-else-if="block.type === 'highlightproductcard'"
                :key="block.id"
                class="highlight-product"
                :class="[`highlight-product--${block.data.type}`]"
                :to="block.data.target_url"
            >
                <div v-if="!isMobile" class="picture-cover">
                    <a-picture :link="block.data.file.url" fit="cover" />
                </div>
                <div class="highlight-product__content">
                    <div class="highlight-product__content-wrap">
                        <a-label>
                            {{ block.data.label_title }}
                        </a-label>
                        <a-dangerous-html :content="block.data.label_text" />
                    </div>
                    <a-picture v-if="isMobile" :link="block.data.file.url" />
                    <a-button type="action" :text="block.data.button_text" />
                </div>
            </dynamic-link>

            <!-- delimiter -->
            <div v-else-if="block.type === 'delimiter'" class="delimiter" />
        </div>
        <div class="additional-content">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import hljs from 'highlight.js';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALabel from '@core/components/label/label.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCard from '@core/components/product-card/product-card.vue';
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'ResourceArticleBody',

    components: {
        ALabel,
        AProductCard,
        AButton,
        ALogo,
        DynamicLink,
        ASvgSprite,
        APicture,
        ADangerousHtml,
    },

    mixins: [breakpoint],

    props: {
        blocks: {
            type: Array,
            required: true,
        },
        textAlign: {
            type: String,
            default: 'center',
        },
    },

    methods: {
        highlightCode(data) {
            return hljs.highlight(data.code, { language: data.language }).value;
        },

        hasMultipleProducts(block) {
            return block.type === 'ctatwoproductstool' || block.type === 'ctathreeproductstool';
        },

        getProductToolData(data) {
            const productData = { ...data };

            if (data.links.learnLink.text && data.links.learnLink.to) {
                productData.learnLink = data.links.learnLink;
            }

            if (data.links.tryLink.text && data.links.tryLink.to) {
                productData.tryLink = data.links.tryLink;
            }

            return productData;
        },

        replaceHighlightBlockLinks(str) {
            const regex = /\[(.*?)]\((https?:\/\/[^\s]+)\)/gi;
            return str.replaceAll(regex, '<a href="$2" title="$1" target="_blank">$1</a>');
        },

        getTableClass(block) {
            return block.data.withHeadings ? 'with-heading' : null;
        },

        hasListAfter(blockIndex) {
            return this.blocks[blockIndex + 1]?.type === 'list';
        },

        getTitleElement(block) {
            const level = Math.max(block.data?.level || 2, 2);
            return `h${level}`;
        },

        getListElement(block) {
            const level = block.data?.style || 'unordered';
            return level === 'ordered' ? 'ol' : 'ul';
        },
        getProductCardProps(product) {
            const labelTarget = product?.label || null;

            let extra = {};
            if (product.buttons && product.buttons.length) {
                if (product.buttons.length === 1) {
                    extra = {
                        tryLink: product.buttons[0],
                    };
                } else {
                    extra = {
                        learnLink: product.buttons[0],
                        tryLink: product.buttons[1],
                    };
                }
            } else {
                extra = {
                    class: 'no-buttons',
                };
            }

            return {
                title: `Acronis ${product.title}`,
                labelTarget,
                ...extra,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.article-body {
    max-width: 328px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    text-align: center;

    &--text-left {
        text-align: start;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    @media (--viewport-mobile-wide) {
        max-width: 704px;
        text-align: start;
    }

    @media (--viewport-tablet) {
        max-width: initial;
    }

    @media (--viewport-desktop) {
        max-width: 763px;
    }
}

.article-title {
    font-weight: 700;
    color: var(--av-fixed-primary);
}

.article-title h2 {
    margin-top: 24px;
    font-size: 24px;
    line-height: 32px;
}

.article-title h3 {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -16px;
}

.article-title--no-mb h3 {
    margin-bottom: 0;
}

.article-paragraph {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--av-fixed-primary);
}

.article-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 352px;
    max-width: 100%;
    margin: 24px 0 16px;
    background: var(--av-solid-brand-lightest);

    &:deep(img) {
        max-height: 352px;
        filter: drop-shadow(0 10px 20px rgba(36, 49, 67, 0.2));
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

        @media (--viewport-mobile-wide) {
            min-height: 312px;
        }
    }
}

.article-image-caption {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--av-fixed-lighter);
}

.article-list {
    padding-inline-start: 18px;
    text-align: start;
    color: var(--av-fixed-primary);
}

ol.article-list {
    list-style: decimal;
}

ul.article-list {
    list-style: square;
}

.article-video-frame {
    border: 0;
}

.article-review {
    margin: 40px auto;
    gap: 8px;
    max-width: 328px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (--viewport-tablet) {
        max-width: 524px;
    }

    @media (--viewport-desktop) {
        max-width: 544px;
        margin: 24px auto;
    }

    &-text {
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        padding: 4px 16px;
        text-align: center;
        color: #002a61;
        background: #dfeffd;
    }

    &-author {
        font-size: 14px;
        line-height: 24px;
        color: #384a63;
    }
}

.article-table {
    position: relative;
    height: 100%;
    display: grid;
    margin: 0 -16px 24px;
    overflow-x: scroll;

    @media (--viewport-tablet) {
        margin: 0 0 24px;
        overflow-x: auto;
    }

    &-row {
        @mixin paragraph;

        display: flex;
        position: relative;
        padding-inline-start: 16px;
        line-break: anywhere;

        @media (--viewport-tablet) {
            padding-inline-start: 0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
            column-gap: 16px;
            border-bottom: 1px solid var(--av-brand-secondary-light);
        }
    }

    &-cell {
        flex: 0 0 calc(50vw - 8px);
        padding: 16px 16px;
        overflow: hidden;
        outline: none;
        line-break: anywhere;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            height: 1px;
            background: var(--av-brand-secondary-light);
        }

        &:first-child {
            padding-inline-start: 0;
        }

        &:last-child {
            &::after {
                inset-inline-end: 16px;
            }
        }

        @media (--viewport-tablet) {
            flex: none;
            padding: 24px 0;

            &::after {
                display: none;
            }
        }
    }

    &.with-heading {
        .article-table-row:first-child {
            @mixin lead-accent;

            z-index: 1;
            padding-bottom: 6px;
            color: var(--av-nav-primary);

            @media (--viewport-tablet) {
                padding-bottom: 0;
            }
        }
    }
}

.additional-content {
    margin-top: 16px;

    &:empty {
        margin-top: 0;
    }
}

.highlight-tool {
    position: relative;
    @mixin paragraph-accent;
    margin: 16px 0;
    padding: 16px;
    text-align: center;
    border-radius: 4px;
    color: var(--av-fixed-primary);
    background: var(--av-brand-accent);
    @media (--viewport-mobile-wide) {
        margin: 32px;
        padding: 32px;
        @mixin lead;
    }
    &.left-align {
        text-align: start;
    }
    &:deep(.a-label) {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        &.size-l {
            top: -12px;
        }
    }
}

.cta-product {
    &.no-buttons.a-product-card {
        height: auto;
    }
}

.cta-products {
    margin: 40px 0;
    display: grid;
    column-gap: 16px;
    row-gap: 16px;

    @media (--viewport-tablet) {
        grid-template-columns: 1fr 1fr;
    }
}

.codeblock-tool pre {
    font-family: ui-monospace, monospace;
    font-size: 14px;
    padding: 10px;
    background-color: var(--av-solid-brand-lightest);
    border: 1px solid var(--av-inversed-primary-darkest);
    border-radius: 10px;
    overflow: auto;
}

.highlight-product {
    display: flex;
    margin-inline-start: -16px;
    margin-inline-end: -16px;
    margin-bottom: 16px;
    padding-top: 24px;
    padding-bottom: 16px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    box-shadow: 0 10px 20px 0 rgba(36, 49, 67, 0.20);
    background: var(--av-solid-brand-lightest, #F4F7FC);
    border-top: 1px solid rgba(38, 104, 197, 0.30);
    border-bottom: 1px solid rgba(38, 104, 197, 0.30);

    @media (--viewport-tablet) {
        display: flex;
        align-items: stretch;
        padding: 0;
        margin: 56px 54px;
        border-radius: 8px;
        border: 2px solid var(--av-brand-light, rgba(38, 104, 197, 0.30));
        background: var(--av-solid-brand-lightest, #F4F7FC);
        overflow: hidden;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;

        @media (--viewport-tablet) {
            padding: 24px;
        }

        .a-dangerous-html {
            @mixin lead-accent;
            color: var(--av-nav-primary, #00204D);
        }

        .a-picture {
            border-radius: 6px;
            overflow: hidden;
            margin-inline: auto;
        }

        .a-button {
            width: 100%;

            @media (--viewport-tablet) {
                width: auto;
            }
        }
    }

    &__content-wrap {
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
    }

    .picture-cover {
        @media (--viewport-tablet) {
            position: relative;
            width: 242px;
            min-height: 228px;
            flex-shrink: 0;
        }
    }

    .a-picture {
        @media (--viewport-tablet) {
            position: absolute;
            inset: 0;
            margin: auto;

            &:deep(img) {
                width: 100%;
                height: 100%;
            }
        }
    }

    &--super {
        background: linear-gradient(133deg, #00204D 20.01%, #1769D1 99.44%);
        border: 1px solid var(--av-brand-secondary, #408BEA);

        .a-dangerous-html {
            color: var(--av-inversed-primary, #FFF);
        }
    }
}

.delimiter {
    height: 1px;
    background: var(--av-brand-secondary-accent);
    margin: 16px 0;
}
</style>
