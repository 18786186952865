<template>
    <div class="blog-side-menu" :class="className">
        <a-picture v-if="useBlur" class="menu-blur" :link="blurLink" />
        <div class="title">
            {{ title }}
        </div>
        <a-link
            v-for="(item, i) in links"
            :key="i"
            class="menu-item"
            v-bind="item"
            :to="item.link"
            :text="item.title"
            :class="{ active: activeLinkId === item.id }"
            :size="item.size || 'paragraph'"
            :type="item.type || 'secondary'"
        />
        <a-link
            v-if="extraLink"
            class="menu-item extra"
            v-bind="extraLink"
            :to="extraLink.link"
            :text="extraLink.title"
            :size="extraLink.glyph || 'lead'"
            :glyph-position="extraLink.glyphPosition || 'left'"
            :type="extraLink.type || 'secondary'"
        />
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'BlogSideMenu',
    components: {
        APicture,
        ALink,
    },
    props: {
        links: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        activeLinkId: {
            type: Number,
            default: null,
        },

        theme: {
            type: String,
            default: 'light',
        },

        extraLink: {
            type: Object,
            default: null,
        },

        useBlur: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
            };
        },
        blurLink() {
            return '@7c70b8d4a4a9ad0962449fad7be90a27';
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-side-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-inline-start: 14px;
    padding-bottom: 32px;

    @media (--viewport-desktop) {
        padding-bottom: 0;
    }

    .title {
        position: relative;
        @mixin title-accent;
        color: var(--av-nav-primary);
        margin-bottom: 16px;
        font-weight: 700;
    }

    .menu-item {
        margin-bottom: 16px;
        text-decoration: none;
        position: relative;
        display: block;

        &.active {
            font-weight: bold;
            pointer-events: none;

            &::before {
                position: absolute;
                content: '';
                background: var(--av-brand-accent);
                border-radius: 4px;
                inset-inline-end: 0;
                inset-inline-start: -8px;
                top: -8px;
                bottom: -8px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.extra {
            margin-top: auto;
            padding-top: 8px;
            font-weight: 600;
            border-top: 2px solid var(--av-brand-secondary-light);

            &:deep(.a-glyph.left) {
                inset-block: 0;
                margin-block: auto;
            }
        }
    }

    &.dark {
        border-inline-start: 0;
        padding-inline-start: 16px;

        .title {
            color: var(--av-inversed-primary);
        }

        .menu-item {
            margin-bottom: 12px;

            &.active {
                color: var(--av-inversed-primary);

                &::before {
                    inset-inline-start: -10px;
                }
            }

            &.extra {
                font-weight: 500;
            }

            &:hover {
                color: var(--av-inversed-primary);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .menu-blur {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
