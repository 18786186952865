<template>
    <div :id="id" class="s-subscription" :class="basicClassName">
        <div v-if="slice" class="a-container">
            <div class="s-subscription__grid">
                <div v-if="slice.picture && slice.picture.link" class="s-subscription__grid__image">
                    <a-picture
                        :link="slice.picture.link"
                        :alt="slice.picture.alt || 'Acronis'"
                        :size="slice.picture.size || 'normal'"
                    />
                </div>
                <div class="s-subscription__grid__content">
                    <p v-if="slice.title" class="s-subscription__grid__content__title" v-html="slice.title" />
                    <p
                        v-if="slice.description"
                        class="s-subscription__grid__content__description"
                        v-html="slice.description"
                    />
                    <p v-if="slice.button">
                        <a-button
                            v-modal="'s-subscription-modal'"
                            class="s-subscription__grid__content__button"
                            :event="event"
                            v-bind="slice.button"
                            :size="slice.button.size || 's'"
                            :type="slice.button.type || 'main'"
                            :text="slice.button.text || 'Subscribe'"
                        />
                    </p>
                </div>
            </div>
        </div>
        <a-modal
            v-if="slice && slice.form"
            id="s-subscription-modal"
            :is-overlay-close="false"
            :use-layout="false"
            :has-container-close="true"
        >
            <div class="s-subscription__registration">
                <s-form-container :form="slice.form" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import { merge } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

const STORE_SLICE_NAME = 's-subscription';

export default {
    name: 'SSubscription',

    components: {
        AButton,
        AModal,
        APicture,
        SFormContainer,
    },

    directives: {
        Modal,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            slice: null,
            event: {
                category: 'Interaction',
                action: 'button click',
                label: 'Subscribe now',
            },
        };
    },

    async serverPrefetch() {
        const locale = this.$route.params.locale;
        await this.$store.dispatch('slices/getSyncedData', { slice: STORE_SLICE_NAME, locale });
    },

    mounted() {
        const sliceSettings = this.$store.state.slices.items[STORE_SLICE_NAME];
        const propsSettings = this.settings || {};
        this.slice = merge(sliceSettings, propsSettings);
    },
};
</script>

<style lang="postcss">
.el-select-dropdown {
    &.el-select-subscription {
        z-index: 2147483655 !important; /* this must be on top of modal  which has z-index: 2147483645 because of GEO IP ribbon */
        .el-select-dropdown__item {
            @mixin body;
        }
    }
}
</style>

<style lang="postcss" scoped>
.s-subscription {
    color: var(--av-nav-primary);
    padding: 40px 0;
    background: rgb(242, 248, 255);

    &__grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        &__image {
            @mixin colls 12;
            margin-bottom: 40px;
            @media (--viewport-mobile-wide) {
                @mixin colls 4;
                margin: 0;
            }

            &:deep(img) {
                display: block;
                margin: 0;
                max-width: 100%;
                width: 213px;

                @media (--viewport-mobile-wide) {
                    width: 224px;
                    margin: 0 auto;
                }
                @media (--viewport-desktop) {
                    width: 227px;
                }
            }
        }

        &__content {
            @mixin colls 12;

            @media (--viewport-mobile-wide) {
                @mixin colls 8;
            }

            &__title {
                @mixin title;
                margin-bottom: 32px;

                @media (--viewport-desktop-wide) {
                    @mixin colls 10;
                }
                @media (--viewport-tablet) {
                    @mixin display;
                }
            }
        }
    }

    &-modal {
        overflow: hidden;
        background: var(--av-inversed-primary);
    }

    &__registration {
        .s-form-container {
            margin: 0;
            width: auto;
            text-align: start;

            &:deep(.el-form) {
                margin: 40px 0 0;
            }

            &:deep(.s-form-registration) {
                padding: 40px;
                border: none;
                box-shadow: none;

                &__title {
                    @mixin title;
                    font-weight: 600;
                    text-align: start;
                    color: var(--av-nav-primary);
                    font-family: var(--font-family-cyber);
                }

                &__error {
                    @mixin caption;
                }
            }

            &:deep(.s-form-success) {
                display: flex;
                min-height: 568px;
                padding: 106px 16px;
                flex-direction: column-reverse;

                .description {
                    @mixin title;
                    margin: 0;
                    font-weight: 600;
                    text-align: center;
                    padding: 0 16px 40px;
                    color: var(--av-nav-primary);
                }
            }

            &:deep(.el-form-item__error-holder) {
                height: 24px;
            }

            &:deep(input),
            &:deep(.el-input__placeholder) {
                @mixin body;
            }

            &:deep(.el-input__label) {
                @mixin body;

                &.is-active {
                    @mixin caption;
                    padding-top: 1px;
                }
            }
        }
    }
}

#s-subscription-modal {
    :deep(.inner) {
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-modal);
        border-radius: 8px;
        padding: 0;
        max-width: 500px;
    }

}
</style>
