<template>
    <s-basic-slice class="s-requirements" v-bind="$attrs">
        <a-accordion v-if="$attrs.layout !== 'row' && isMobile" class="s-requirements__accordion" is-wide>
            <a-accordion-item v-for="(item, key) in getSystemInfo" :key="key">
                <template #title>
                    <a-glyph class="icon" name="icon-pointer-right" />
                    <template v-if="item.title">
                        {{ item.title.text || item.title }}
                        <a-tooltip v-if="item.title.tooltip" class="s-requirements__tooltip" :text="item.title.tooltip">
                            <a-glyph name="info-circle" />
                        </a-tooltip>
                    </template>
                </template>
                <a-requirement
                    :learn-more-text="learnMoreText"
                    :title="item.title"
                    :sections="item.sections"
                />
            </a-accordion-item>
        </a-accordion>
        <div v-else class="s-requirements__content" :class="{ 's-requirements__content_with-image': image }">
            <div
                class="s-requirements__grid masonry-layout"
                :class="`s-requirements__grid--layout-columns-${columnsCount}`"
                :style="masonryStyle"
            >
                <div
                    v-for="(items, i) in gridMap"
                    :key="i"
                    class="masonry-column"
                >
                    <a-requirement
                        v-for="(item, key) in items"
                        :key="key"
                        :learn-more-text="learnMoreText"
                        v-bind="item"
                        class="s-requirements__item masonry-item"
                    />
                </div>
            </div>
            <div v-if="image" class="s-requirements__image">
                <a-picture
                    :class="imageClass"
                    :link="image"
                    :alt="imageAlt"
                />
            </div>
        </div>
        <a-link
            v-if="link"
            class="s-requirements__link-top"
            v-bind="link"
            :size="link.size || 'title'"
            :type="link.type || 'regular'"
            :glyph="link.glyph || 'arrow'"
            accent
        />
        <a-link
            v-if="link"
            class="s-requirements__link-bottom"
            v-bind="link"
            :size="link.size || 'title'"
            :type="link.type || 'regular'"
            :glyph="link.glyph || 'arrow'"
            accent
        />
    </s-basic-slice>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import productMixin from '@core/mixins/product.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import ARequirement from './requirement.vue';

export default {
    name: 'SRequirements',

    components: {
        SBasicSlice,
        AAccordion,
        AAccordionItem,
        AGlyph,
        ALink,
        ARequirement,
        ATooltip,
        APicture,
    },

    mixins: [breakpoint, productMixin],

    inheritAttrs: false,

    props: {
        systemInfo: {
            type: Array,
            default: undefined,
        },

        /**
         * image path
         */
        image: {
            type: String,
            default: undefined,
        },

        imageAlign: {
            type: String,
            default: 'center',
        },

        learnMoreText: {
            type: String,
            default: 'Learn more',
        },

        imageAlt: {
            type: String,
            default: '',
        },

        /**
         * optional link
         */
        link: {
            type: Object,
            default: undefined,
        },

        columnsCount: {
            type: Number,
            required: true,
        },
    },

    computed: {
        getSystemInfo() {
            return this.systemInfo || this.productSystemInfo;
        },
        masonryStyle() {
            const columnsCount = this.currentBreakpoint === 'mobile' ? 1 : this.columnsCount;
            return `--masonry-columns: ${columnsCount}`;
        },
        imageClass() {
            return `s-requirements__pic ${this.imageAlign}`;
        },
        gridMap() {
            const items = this.getSystemInfo;
            const columns = this.columnsCount;
            const maxRows = Math.ceil(items.length / columns);
            const grid = [];

            for (let i = 1; i <= columns; i++) grid.push([]);

            for (let m = 0; m < maxRows; m++) {
                for (let n = 0; n < columns; n++) {
                    const item = items[m * columns + n];
                    if (item) grid[n].push(item);
                }
            }

            return grid;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-requirements {
    &:deep(.a-slice-header),
    &:deep(.a-accordion .a-accordion-item .title) {
        padding-inline-start: var(--container-paddings-mobile);
        padding-inline-end: var(--container-paddings-mobile);
    }

    &:deep(.a-slice-header) {
        @media(--viewport-tablet) {
            padding-inline-start: 32px;
        }
    }

    &:deep(.a-container) {
        padding: 0;

        @media(--viewport-desktop-wide) {
            padding-inline: 32px;
        }

        @media(--viewport-desktop-large) {
            max-width: 1376px;
            padding: 0;
        }
    }

    &:deep(.a-accordion .a-accordion-item) {
        margin: 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        &.active {
            padding: 0;
            margin: 0;
        }
        .content-wrapper {
            padding: 8px 0;
        }

        .a-requirement__section {
            padding-inline-start: 46px;
        }
    }

    &:not(.s-slice_layout_row) {
        .s-requirements__content {
            display: none;

            @media (--viewport-mobile-wide) {
                display: block;
            }

            &_with-image {
                @media (--viewport-desktop-wide) {
                    display: flex;

                    .s-requirements__grid {
                        width: calc(50% - 8px);
                    }

                    .s-requirements__image {
                        display: flex;
                        width: calc(50% - 8px);
                        margin-inline-start: 16px;
                        min-height: 304px;
                        flex-direction: column;
                    }
                }
            }
        }
    }

    &.s-slice_layout_row {
        &:deep(.a-slice-header__title) {
            display: none;
        }

        .s-requirements__content {
            @media (--viewport-mobile-wide) {
                flex-shrink: 0;
                flex-grow: 1;
            }
        }
    }

    &__grid {
        --masonry-columns: 1;
        column-count: var(--masonry-columns);
        gap: 32px;
    }

    .masonry-column {
        @media (--viewport-mobile-wide) {
            margin-bottom: 56px;
        }
    }

    &__item {
        box-sizing: border-box;
        break-inside: avoid;
        @media (--viewport-mobile-wide) {
            margin-bottom: 56px;
        }
    }

    &__image {
        display: none;
    }

    &__pic {
        @media (--viewport-desktop-wide) {
            object-fit: contain;
            width: 100%;
            height: auto;
            margin: auto;
        }

        &.top {
            margin-bottom: auto;
            margin-top: 0;
        }

        &.bottom {
            margin-bottom: 0;
            margin-top: auto;
        }
    }

    &__link-top,
    &__link-bottom {
        margin-top: 56px;
    }

    &__link-bottom {
        padding-inline-start: 16px;

        @media(--viewport-tablet) {
            padding-inline-start: 32px;
        }

        @media (--viewport-desktop) {
            display: none;
        }
    }

    &__link-top {
        display: none;

        @media (--viewport-desktop) {
            display: block;
            position: absolute;
            top: 8px;
            margin-top: 0;
            max-width: 33.33%;
            inset-inline-end: 32px;
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: 64px;
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: 0;
        }
    }

    &__tooltip {
        padding-inline-start: 8px;
        position: relative;
        bottom: 5px;

        .a-glyph {
            fill: var(--av-fixed-neutral-dark);
        }
    }
}
</style>
