<template>
    <div class="s-workload">
        <div v-if="product.description" class="s-workload__description">
            {{ product.description }}
            <div v-if="isActivePromo" class="s-workload__description-promo">
                {{ promoDescription }}
            </div>
        </div>
        <div v-if="hasEditions(product)" class="s-workload__editions" :style="cssStyle">
            <div
                v-for="(edition, editionIndex) in product.editions"
                :key="`workload-edition-${editionIndex}`"
                class="s-workload__edition"
                :class="className(edition)"
            >
                <p v-if="edition.name" class="s-workload__edition-name" v-html="edition.name" />
                <p v-if="edition.description" class="s-workload__edition-description">
                    {{ edition.description }}
                </p>
                <notification
                    v-for="(item, notificationIndex) in notifications(edition)"
                    :key="`notification-${notificationIndex}`"
                    :description="item"
                    glyph="info-circle-o"
                    size="small"
                />
                <div v-if="edition.subscriptions" class="s-workload__edition-cta">
                    <div v-if="isActivePromo" class="s-workload__edition-label" v-html="setPromoLabel(edition)" />
                    <div class="s-workload__edition-price" v-html="price(edition)" />
                    <a-button
                        v-if="!edition.added"
                        size="s"
                        type="main"
                        :text="setTranslate('Add to cart')"
                        :event="{ doNotSendGA: true }"
                        @click="add(product, edition)"
                    />
                    <a-button
                        v-if="edition.added"
                        class="a-button__remove"
                        size="s"
                        :text="setTranslate('Remove')"
                        :event="{ doNotSendGA: true }"
                        @click="remove(product, edition)"
                    />
                </div>
                <a-glyph v-if="edition.added" name="check-square" />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import analytics from '@core/mixins/analytics.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';
import notification from '../notification.vue';

export default {
    name: 'SWorkloadEditions',

    components: {
        AGlyph,
        AButton,
        notification,
    },

    mixins: [mixin, analytics, currency],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        isActivePromo: false,
    }),

    computed: {
        cssStyle() {
            return { height: `${this.settings.height || 320}px` };
        },

        promoDescription() {
            return this.settings.promo?.description || '';
        },
    },

    methods: {
        className(edition) {
            return {
                's-workload__edition__active': edition.added,
                's-workload__edition__disabled': !edition.subscriptions,
            };
        },

        notifications(edition) {
            return edition.notifications ? edition.notifications.slice(-1) : [];
        },

        add(product, edition) {
            this.editionAdd(product, edition);
            this.ecommerceClick(edition, 'add', 'Add to cart');
            this.microsoft365Check();
        },

        remove(product, edition) {
            this.editionRemove(product, edition);
            this.ecommerceClick(edition, 'remove', 'Remove from cart');
        },

        ecommerceClick(edition, type, eventAction) {
            const cleverBridgeCartID = this.license(edition).cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });
            const event = {
                quantity: 1,
                eventAction,
                eventLabel: license.name || '',
                ecommerce: { type, license: license.cleverbridge_id },
            };
            this.ecommerceSendEvent({ event, license });
        },

        setPromoLabel(edition) {
            const text = this.settings?.promo?.offer || '';
            const cleverBridgeCartID = edition.subscriptions.standard[0].cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            return license.discount_percentage ? text.replace('XX', license.discount_percentage) : '';
        },

        license(edition) {
            if (edition.subscriptionsCustom) {
                return edition.subscriptionsCustom.standard[0].mailboxes[0];
            }
            return edition.subscriptions.standard[0];
        },

        price(edition) {
            const cleverBridgeCartID = this.license(edition).cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            if (!license.cleverbridge_id) {
                return 0;
            }

            const text = this.setTranslate('From');
            const price = license.price;

            this.isActivePromo = this.hasLicensePromo({
                cleverBridgeCartID: license.cleverbridge_id,
                promo: this.settings.promo,
            });

            if (this.isActivePromo) {
                const discount = license.discount_absolute;
                const priceFull = price + discount || 0;
                return `${text} <del>${this.setCurrency(priceFull)}</del> ${this.setCurrency(price)}`;
            }

            return `${text} ${this.setCurrency(price)}`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-workload {
    &__description {
        position: relative;

        &-promo {
            @mixin paragraph-accent;
            inset-inline-start: 0;
            top: 100%;
            position: absolute;
            color: var(--av-fixed-success);
        }
    }

    &__editions {
        width: 100%;
        overflow: hidden;
        margin: 40px 0 0;
        flex-wrap: nowrap;
        border-radius: 4px;
        flex-direction: row;
        display: inline-flex;
        align-items: stretch;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-secondary-light);
        min-width: 1150px;
    }

    &__edition {
        display: flex;
        width: 100%;
        padding: 24px;
        position: relative;
        flex-direction: column;
        background: var(--av-inversed-primary);

        &:not(:first-of-type) {
            border-inline-start: 1px solid var(--av-brand-secondary-light);
        }

        .a-glyph {
            &__check-square {
                top: 8px;
                inset-inline-start: 16px;
                position: absolute;
            }
        }

        &__active {
            background-color: var(--av-brand-lightest);
        }

        &__disabled {
            .s-workload {
                &__edition-name,
                &__edition-description {
                    color: var(--av-fixed-lighter);
                }
            }
        }

        &-name {
            @mixin paragraph-accent;
            color: var(--av-nav-primary);
        }

        &-description {
            @mixin body;
            margin: 8px 0 auto;
            color: var(--av-fixed-secondary);
        }

        &-cta {
            .a-button {
                @mixin body-accent;
                width: 100%;
                padding: 4px 8px;
                user-select: none;
                color: var(--av-brand-secondary);
                background-color: var(--av-inversed-primary);

                .a-button__content {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .a-button__remove {
                background-color: var(--av-brand-secondary-accent);
            }
        }

        &-price {
            @mixin paragraph-accent;
            overflow: hidden;
            margin: 0 0 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: var(--av-nav-primary);

            &:deep(del) {
                font-weight: 400;
                color: var(--av-fixed-lighter);
            }
        }
    }
}

.s-purchasing-acp-promo {
    .s-workload {
        &__edition {
            &-label {
                @mixin caption-accent;
                top: 0;
                inset-inline-start: 0;
                position: absolute;
                color: var(--av-fixed-success);
            }

            &-price {
                margin: 0 0 8px;
            }

            &-cta {
                position: relative;
                padding: 16px 0 0 0;
            }
        }
    }
}
</style>
