<template>
    <div v-if="isVisible" class="s-card-add-workload" :class="{ 'opened': isOpen }">
        <div class="item">
            <div class="title">
                {{ setTranslate('Add workload') }}
            </div>
            <div class="description">
                {{ setTranslate('textAddWorkload') }}
            </div>
            <div class="toggler" @click="toggle()" />
        </div>
        <div v-if="isOpen" class="selectors">
            <p class="selector">
                {{ setTranslate('Select workload') }}
            </p>
            <div class="wrapper" :class="className('workload')">
                <div class="selector" @click="open('workload')">
                    <div class="selector-title">
                        {{ productName }}
                    </div>
                    <div class="suffix">
                        <a-glyph class="glyph" name="chevron-down" />
                    </div>
                </div>
                <div class="options">
                    <div v-for="(item, workloadIndex) in workloads" :key="`workload-item-${workloadIndex}`" class="option-item">
                        <label class="option-item-label">
                            <input
                                v-model="workload.selected"
                                class="option-item-input"
                                name="workload"
                                type="radio"
                                :value="workloadIndex"
                                @change="syncEditions()"
                            />{{ item.name }}
                        </label>
                    </div>
                </div>
            </div>
            <p class="selector">
                {{ setTranslate('Select edition') }}
            </p>
            <div class="wrapper" :class="className('edition')">
                <div class="selector" @click="open('edition')">
                    <div class="selector-title-edition" v-html="editionName()" />
                    <div class="suffix">
                        <a-glyph class="glyph" name="chevron-down" />
                    </div>
                </div>
                <div class="edition">
                    <p class="edition-title">
                        {{ setTranslate('Editions:') }}
                    </p>
                    <el-checkbox
                        v-for="(item, subscriptionIndex) in listSubscriptions"
                        :key="`edition-${subscriptionIndex}`"
                        v-model="item.added"
                    >
                        <span class="el-checkbox-title" v-html="item.name" />
                    </el-checkbox>
                    <div class="edition-cta">
                        <a-button :text="setTranslate('Cancel')" :event="{ doNotSendGA: true }" @click="cancel()" />
                        <a-button
                            type="main"
                            :text="setTranslate('Apply')"
                            :event="{ doNotSendGA: true }"
                            @click="apply()"
                        />
                    </div>
                </div>
            </div>
            <div class="button">
                <a-button
                    glyph="arrow-long"
                    :text="setTranslate('Add to cart')"
                    :event="{ doNotSendGA: true }"
                    @click="addWorkload()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { get, merge, cloneDeep, isEmpty } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SCardAddWorkload',

    components: {
        AGlyph,
        AButton,
        elCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        isOpen: false,
        edition: {
            isOpen: false,
        },
        workload: {
            selected: 0,
            isOpen: false,
        },
        editions: [],
        workloads: [],
    }),

    computed: {
        listProducts() {
            return this.listWorkloads.map((s) => s).filter((p) => !p.added && !p.selected);
        },

        listEditions() {
            return this.selectedProduct.editions;
        },

        listSubscriptions() {
            return this.editions.filter((e) => this.type(e));
        },

        selectedProduct() {
            return this.listProducts[this.workload.selected] || this.listProducts[0];
        },

        productName() {
            return this.selectedProduct.name || '';
        },

        isVisible() {
            return this.listProducts.length;
        },
    },

    methods: {
        className(name) {
            return {
                opened: this[name].isOpen,
            };
        },

        toggle() {
            this.productOpened('workloads').opened = false;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                this.workloads = cloneDeep(this.listProducts);
                this.syncEditions();
            }
        },

        editionName() {
            const result = this.editions.find((p) => p.added);
            return result ? result.name : this.listEditions.filter((e) => this.type(e))[0].name;
        },

        type(edition) {
            return edition[this.editionTypeSubscriptions(edition)];
        },

        open(name) {
            this[name].isOpen = true;
            window.addEventListener('click', this.close, true);
        },

        syncEditions() {
            this.editions = cloneDeep(this.listEditions);
            this.listSubscriptions[0].added = true;
        },

        close(el) {
            const className = get(el, 'target.className', '');

            if (/checkbox|button|edition|check/.test(className)) {
                return;
            }

            this.edition.isOpen = false;
            this.workload.isOpen = false;
            window.removeEventListener('click', this.close, true);
        },

        cancel() {
            merge(this.editions, this.listEditions);
            this.apply();
        },

        apply() {
            if (isEmpty(this.editions.filter((e) => e.added))) {
                this.listSubscriptions[0].added = true;
                merge(this.editions, this.listSubscriptions[0]);
            }
            this.edition.isOpen = false;
        },

        addWorkload() {
            this.isOpen = false;
            this.productOpened('workloads').opened = false;

            const productID = this.listProducts[this.workload.selected].id;
            const workload = this.currentProduct(productID);

            merge(workload.editions, this.editions);
            merge(workload, { opened: true, added: true, selected: true, time: this.time() });

            this.microsoft365Check();

            this.cancel();
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-card-add-workload {
    margin: 16px 0 0;
    user-select: none;
    border-radius: 4px;
    position: relative;
    box-shadow: var(--av-shadow-small);
    border: 1px solid var(--av-brand-light);
    background-color: var(--av-inversed-primary);

    &.opened {
        background-color: var(--av-solid-brand-lightest);
    }

    .item {
        position: relative;
        padding: 24px 32px 32px;
    }

    .title {
        @mixin title-accent;
        color: var(--av-fixed-secondary);
    }

    .description {
        @mixin body;
        width: 488px;
        margin: 8px 0 0;
        color: var(--av-fixed-secondary);
    }

    .selectors {
        @mixin body;
        padding: 0 32px 32px;
    }

    .selector {
        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }

    .button {
        margin: 24px 0 0;
        text-align: end;

        &:deep(.a-button) {
            min-width: 196px;
        }
    }

    .toggler {
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
    }

    .wrapper {
        height: 40px;
        width: 382px;
        border-radius: 4px;
        position: relative;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-light);
    }

    .selector {
        display: flex;
        padding: 7px 0;
        cursor: pointer;
        flex-wrap: nowrap;
        align-items: center;
        color: var(--av-fixed-primary);
        justify-content: space-between;
    }

    .selector-title {
        @mixin paragraph-accent;
        white-space: nowrap;
        padding: 0 16px;
    }

    .selector-title-edition {
        @mixin paragraph;
        padding: 0 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .suffix {
        @mixin paragraph;
        padding-inline-end: 11px;
        padding-inline-start: 8px;

        .a-glyph {
            margin: 0 0 2px;
            transition: none;
            vertical-align: middle;
            fill: var(--av-brand-primary);
        }
    }

    .options {
        z-index: 2;
        display: none;
        padding: 8px 0;
        margin: 0 -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--av-brand-primary);
        border-top: 1px solid var(--av-brand-primary);
    }

    .edition {
        @mixin paragraph;
        z-index: 2;
        display: none;
        padding: 24px 0;
        margin: 0 -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--av-brand-primary);
        border-top: 1px solid var(--av-brand-primary);

        &:deep(.el-checkbox) {
            display: block;
            padding: 0 16px;

            &:not(:first-child) {
                margin: 8px 0 0;
            }

            &__label {
                padding-inline-start: 8px;
            }
        }
    }

    .edition-title {
        @mixin body;
        padding: 0 16px;
        color: var(--av-fixed-light);
    }

    .edition-cta {
        margin: 24px 0 0;
        padding: 24px 24px 0;
        border-top: 1px solid var(--av-brand-light);

        &:deep(.a-button) {
            padding: 0;
            height: 34px;
            font-size: 14px;
            font-weight: 400;
            line-height: 34px;
            vertical-align: middle;

            &:not(:first-child) {
                margin-inline-start: 24px;
            }

            .a-button__content {
                padding: 0 24px;
            }
        }
    }

    .option-title {
        @mixin paragraph-accent;
        white-space: nowrap;
        padding-inline-end: 8px;
        padding-inline-start: 16px;
    }

    .option-description {
        @mixin descriptor;
        line-height: 18px;
        padding: 8px 16px 4px;
        padding-inline-end: 32px;
    }

    .option-item-label {
        @mixin paragraph;
        display: block;
        padding: 8px 16px;
        padding-inline-end: 34px;
    }

    .opened {
        border-radius: 4px 4px 0 0;
        border-color: var(--av-brand-primary);

        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .selector {
            cursor: default;
        }

        .suffix {
            .a-glyph {
                transform: rotate(180deg);
            }
        }

        .options {
            inset-inline-start: 0;
            top: 100%;
            display: block;
            position: absolute;
            background: var(--av-inversed-primary);
        }

        .option-item {
            position: relative;

            &:hover {
                background: var(--av-brand-secondary-accent);
            }
        }

        .edition {
            inset-inline-start: 0;
            top: 100%;
            display: block;
            position: absolute;
            background: var(--av-inversed-primary);
        }
    }
}
</style>
