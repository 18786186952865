/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=14738-14695&t=JMJ2fbzoLIVoGwai-4';

export const Default = {
    args: {
        title: 'Resources',
        link: { text: 'More Documents', to: '#' },
        note: 'Looking for help? Email us at <a href="mailto:access-sales@acronis.com">access-sales@acronis.com</a> or call <a href="tel:+1 781-782-9000">+1 781-782-9000<a>',
        cards: [
            {
                title: "What's New",
                text: "What's New in Acronis Backup Advanced Edition",
                to: '#1',
                preferred: true,
            },
            {
                title: 'Card with glyph',
                text: 'PDF, 0.9 MB',
                to: '#2',
                preferred: true,
                glyph: 'download-arrow',
            },
            {
                title: 'Executive Summary',
                text: 'Hundreds of G2 Crowd users rank Acronis Backup higher than Veritas',
                to: '#3',
                preferred: true,
            },
            {
                title: 'Whitepaper',
                text: 'Six costly data protection gaps in Microsoft Office 365 and how to close them',
                to: '#4',
                preferred: true,
            },
            {
                title: "What's New",
                text: "What's New in Acronis Backup Advanced Edition",
                to: '#1',
                preferred: true,
            },
            {
                title: 'Executive Summary',
                text: 'Hundreds of G2 Crowd users rank Acronis Backup higher than Veritas',
                to: '#3',
                preferred: true,
            },
        ],
        background: 'light-blue',
    },
};
