/* eslint-disable max-len */
const ARG_DEFAULTS = {
    isCentered: false,
    withTabletAltLayout: false,
    isReversed: false,
    label: 'Exclusive',
    descriptor: 'Server Backup and Recovery',
    title: 'Easy, efficient, secure G Suite backup',
    lead: 'Instantiate your apps in the cloud when disaster strikes as a cost-effective DR strategy vs. maintaining idle infrastructure on-prem',
    bodyText: 'Acronis Disk Director 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk by creating and managing disk partitions.Acronis Disk Director 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk by creating and managing disk partitions. Store different file systems or operating systems on one disk.',
    media: {
        type: 'image',
        link: '/images/schema-shield/schema-desktop',
        imageAlt: 'Embedded thumbnail for Backing up Your files',
    },
};

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=44432-70955&t=NowktMty3PwitnGN-4';

export const MediaImage = {
    args: {
        ...ARG_DEFAULTS,
    },
};

export const MediaVideo = {
    args: {
        ...ARG_DEFAULTS,
        image: '',
        media: {
            type: 'video',
            ytId: 'kSLEGX-j9Jg',
            text: 'Introduction to the most advanced backup solution Introduction to the most advanced backup solution Introduction to the most advanced backup solution Introduction to the most advanced backup solution',
            time: '2:00',
            image: 'images/video_car/video_car',
            imageAlt: 'Embedded thumbnail for Backing up Your files',
        },
    },
};

export const AdditionalArgs = {
    args: {
        ...ARG_DEFAULTS,
        link: { text: 'Learn more', to: '#' },
        bulletListItems: [
            'Partners that choose to leverage <a href="#">marketing materials</a> and engage in more marketing and market-building activities should close more deals',
            'Register deals and earn higher margins',
            'Access pricelists, battle cards and product collateral',
            'Train with the Acronis Academy online or get certified in person',
            'Benefit from 24/7 support in seven different languages',
        ],
        bulletListGlyph: {
            name: 'success',
            fill: 'fixed-success',
            size: 's',
        },
        tags: [
            { link: { text: 'Detection', to: '' } },
            { link: { text: 'Prevention', to: '' } },
            { link: { text: 'Response', to: '' } },
            { link: { text: 'Recovery', to: '' } },
            { link: { text: 'Management', to: '' } },
            { link: { text: 'Cyber security', to: '' } },
            { link: { text: 'Anti-malware', to: '#' }, type: 'tag' },
        ],
    },
};

export const DownloadTagLinks = {
    args: {
        ...ARG_DEFAULTS,
        tagLinks: [
            {
                title: 'Acronis Cyber Backup',
                tag: 'For Business',
                to: '#',
            },
            {
                title: 'Acronis Cyber Backup',
                tag: 'For Business',
                to: '#',
            },
        ],
    },
};

export const DownloadCards = {
    args: {
        ...ARG_DEFAULTS,
        downloadFileArr: [
            {
                title: 'White paper',
                desc: 'PDF, 1.2 MB',
                to: '#',
            },
            {
                title: 'Data sheet',
                desc: 'PDF, 0.5 MB',
                to: '#',
            },
        ],
    },
};

export const DownloadList = {
    args: {
        ...ARG_DEFAULTS,
        linkListItems: [
            { text: 'Windows Server', to: '#' },
            { text: 'Windows PC', to: '#' },
            { text: 'Microsoft Hyper-V', to: '#' },
            { text: 'Other platforms', to: '#' },
        ],
    },
};

export const DownloadButton = {
    args: {
        ...ARG_DEFAULTS,
        download: [
            {
                type: 'MAC',
                list: [
                    {
                        type: 'action',
                        glyph: 'download-arrow',
                        text: 'Download for macOS',
                        to: 'https://download.acronis.com/AcronisCyberProtectHomeOffice.dmg',
                    },
                    {
                        text: 'Looking for Windows version?',
                        changeOS: 'PC',
                        isLink: true,
                    },
                ],
                description: 'No credit card or serial number required.',
                info: [
                    {
                        title: 'Released',
                        text: 'August 22, 2022',
                    },
                    {
                        title: 'Size',
                        text: '44,54 MB (dmg)',
                    },
                    {
                        title: 'Build',
                        text: '39702 <a target="_blank" href="https://www.acronis.com/support/updates/changes.html?p=41675">Release notes</a>',
                    },
                ],
            },
            {
                type: 'PC',
                list: [
                    {
                        type: 'action',
                        glyph: 'download-arrow',
                        text: 'Download for Windows',
                        to: 'https://download.acronis.com/AcronisCyberProtectHomeOffice.exe',
                    },
                    {
                        text: 'Looking for a macOS version?',
                        changeOS: 'MAC',
                        isLink: true,
                    },
                ],
                description: 'No credit card or serial number required.',
                info: [
                    {
                        title: 'Released',
                        text: 'August 14, 2018',
                    },
                    {
                        title: 'Size',
                        text: '44,54 MB (exe)',
                    },
                    {
                        title: 'Build',
                        text: '39900 <a target="_blank" href="https://www.acronis.com/support/updates/changes.html?p=42798">Release notes</a>',
                    },
                ],
            },
        ],
    },
};

export const EventCountdown = {
    args: {
        extraClassName: 'event-countdown event-countdown-home-page',
        label: 'Exclusive',
        title: 'System recovery without IT? Seriously?',
        ctaButton: {
            to: '#',
            text: 'Register now',
            type: 'action',
        },
        link: {
            text: 'Discover the Cyber Protect Platform',
            title: 'Title test',
            to: '#',
        },
        video: {
            ytId: 'kSLEGX-j9Jg',
            text: 'Introduction to the most advanced backup solution Introduction to the most advanced backup solution Introduction to the most advanced backup solution Introduction to the most advanced backup solution',
            time: '2:00',
            image: 'images/video_car/video_car',
            imageAlt: 'Embedded thumbnail for Backing up Your files',
        },
        eventCountdown: {
            startDate: '2024-01-18 20:00',
            background: {
                mobile: '@bbb27e188d1fb4d4b1570372b5c85d37',
                tablet: '@785ca106a9a35d475f0cc6dc89c55322',
                desktop: '@785ca106a9a35d475f0cc6dc89c55322',
            },
            translations: {
                topText: 'Big announcement is coming in...',
                bottomText: 'Attend the event to learn more!',
                eventStartsNowText: 'THE EVENT STARTS NOW!',
                days: 'days',
                hours: 'hours',
                minutes: 'minutes',
                seconds: 'seconds',
            },
        },
    },
};
