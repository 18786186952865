<template>
    <resource-page>
        <div class="press-releases-media-contacts">
            <div class="header-wrapper">
                <resource-breadcrumbs
                    :breadcrumbs="breadcrumbs"
                    :common-translations="pressReleasesConfig"
                />
                <h1 class="title">
                    {{ pressReleasesConfig.mediaContactsTitle }}
                </h1>
                <div class="description">
                    {{ pressReleasesConfig.mediaContactsDescription }}
                </div>
            </div>
            <div class="media-contacts-list a-container">
                <press-releases-media-contact
                    v-for="(contact, i) in mediaContacts"
                    :key="i"
                    v-bind="contact"
                    :common-translations="pressReleasesConfig"
                />
            </div>
        </div>
    </resource-page>
</template>

<script lang="ts">
import breakpoint from '@core/mixins/breakpoint.js';
import PressReleasesMediaContact
    from '@core/slices/press-releases/press-releases-media-contact/press-releases-media-contact.vue';
import ResourceBreadcrumbs from '@core/slices/resource-center/resource-breadcrumbs/resource-breadcrumbs.vue';
import ResourcePage from '@core/slices/resource-center/shared-components/resource-page.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { PR_ROOT_URL } from '@model/const/press-releases';

export default {
    name: 'PRMediaContacts',

    components: {
        ResourceBreadcrumbs,
        PressReleasesMediaContact,
        ResourcePage,
    },

    mixins: [breakpoint],

    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        pressReleasesConfig() {
            return this.$store.state.slices.items['press-releases'] || {};
        },

        mediaContacts() {
            return this.pressReleasesConfig.mediaContacts || [];
        },

        breadcrumbs() {
            return [
                {
                    text: this.pressReleasesConfig.pressReleases,
                    to: `${PR_ROOT_URL}/`,
                    glyph: 'home',
                    glyphPosition: 'left',
                },
                {
                    sublinks: true,
                },
                {
                    text: this.pressReleasesConfig.mediaContactsTitle,
                    to: '#',
                },
            ];
        },
    },
};
</script>

<style lang="postcss" scoped>
.press-releases-media-contacts {
    background: var(--av-inversed-primary);
    margin: 0 auto;

    .header-wrapper {
        @mixin page-horizonal-paddings;
        padding-top: 20px;
        padding-bottom: 24px;
        border-top: 1px solid var(--av-brand-light);
        border-bottom: 1px solid var(--av-brand-light);
        background: var(--av-brand-lightest);
        background: linear-gradient(180deg, rgba(38, 104, 197, 0.05) 0%, rgba(38, 104, 197, 0) 100%);

        @media (--viewport-tablet) {
            padding-top: 16px;
        }
    }

    .title {
        @mixin hero;
        max-width: 1312px;
        margin: 20px auto 0;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: left;
        }
    }

    .description {
        @mixin lead;
        max-width: 1312px;
        margin: 0 auto;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: left;
        }
    }

    .media-contacts-list {
        @mixin page-horizonal-paddings;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding-top: 48px;
        padding-bottom: 80px;

        @media (--viewport-tablet) {
            padding-top: 24px;
        }

        @media (--viewport-desktop) {
            padding-top: 48px;
        }
    }
}

.resource-center-page {
    &:deep(.main-content:before) {
        content: none;
        background: transparent;
    }
}
</style>
