<template>
    <div v-if="productList.length" class="products">
        <div v-for="(item, itemIndex) in productList" :key="`item-${itemIndex}`" class="item">
            <div v-if="item.product" class="product">
                <div class="product-name" v-html="titleProduct(item.product)" />
                <a-button v-bind="button.removeProduct" @click="removeProduct(item)" />
            </div>
            <div
                v-for="(license, licenseIndex) in licenseList(item.product)"
                :key="`license-${licenseIndex}`"
                class="license-wrapper"
            >
                <div class="license">
                    <div class="license-info">
                        <p>
                            <span v-if="license.quantity">{{ license.quantity * license.multiplier }}</span>
                            <span v-if="license.workload">{{ titleWorkload(license.workload) }}</span>
                            <span v-if="license.period">- {{ titlePeriod(license.period, 'short') }}</span>
                        </p>
                        <p v-if="cloudStorageIncluded(license)" class="license-description" v-html="cloudStorageIncluded(license)" />
                    </div>
                    <div :class="['license-price', {'license-price-promo': hasDiscount(license)}]">
                        <div class="license-price-wrapper">
                            <div v-if="hasDiscount(license)" class="license-price-full" v-html="licensePriceFull(license)" />
                            <div class="license-price-current" v-html="licensePrice(license)" />
                        </div>
                        <a-button v-bind="button.removeLicense" @click="removeLicense(license)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';

export default {
    name: 'SOrderEdition',

    components: {
        AButton,
    },

    mixins: [mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeQuantity'],

    data: () => ({
        button: {
            removeProduct: {
                text: '',
                glyph: 'delete',
                event: { doNotSendGA: true },
            },
            removeLicense: {
                glyph: 'close-16',
                event: { doNotSendGA: true },
            },
        },
    }),

    computed: {
        promo() {
            return this.settings.promo || {};
        },
        productList() {
            return this.settings.products
                .map((edition) => this.addedLicenses.filter((item) => item.workload).find((item) => item.product === edition.product))
                .filter(Boolean);
        },
    },

    created() {
        this.button.removeProduct.text = this.settings.dictionary?.['Remove license'] || '';
    },

    methods: {
        licenseList(name) {
            return this.addedLicenses.filter((item) => item.product === name);
        },

        titleProduct(name) {
            return this.settings.products.find((item) => item.product === name).title || '';
        },

        titleWorkload(name) {
            return this.settings.workloads.find((item) => item.name === name).title || '';
        },

        removeLicense(license) {
            this.$emit('changeQuantity', { ...license, quantity: 0, isChecked: false });
        },

        removeProduct(item) {
            this.licenseList(item.product).forEach((license) => this.removeLicense(license));
        },

        hasDiscount(license) {
            return this.promo.isPromoActive && license.store.discount_absolute;
        },

        licensePrice(license) {
            return this.setCurrency(license.store.price * license.quantity);
        },

        licensePriceFull(license) {
            const price = license.store.price + license.store.discount_absolute;
            return this.setCurrency(price * license.quantity);
        },
    },
};
</script>

<style lang="postcss" scoped>
.products {
    .item {
        margin-bottom: 16px;
        padding-bottom: 16px;
        padding-inline-end: 8px;
        border-bottom: 1px solid var(--av-brand-secondary-light);
        &:deep(.a-button) {
            padding: 0;
            border: none;
            box-shadow: none;
            background: none;
            .a-button__content {
                @mixin descriptor;
                font-weight: 400;
                color: var(--av-brand-primary);
            }
            .a-glyph {
                margin: 0;
                fill: var(--av-brand-primary);
            }
        }
        .product {
            gap: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .product-name {
                @mixin paragraph-accent;
                color: var(--av-nav-primary);
            }
            .a-button {
                gap: 8px;
            }
        }
    }
    .license-wrapper {
        @mixin descriptor;
        width: 100%;
        margin-top: 8px;
        color: var(--av-nav-primary);
        .license {
            gap: 8px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .license-info {
                min-width: 60%;
            }
            .license-price {
                gap: 8px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                &:deep(.a-button) {
                    .a-glyph {
                        margin-top: 2px;
                    }
                }
                .license-price-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: flex-end;
                    .license-price-full {
                        color: var(--av-fixed-light);
                        text-decoration: line-through;
                    }
                    .license-price-current {
                        margin-inline-start: 8px;
                    }
                }
            }
        }
        .license-description {
            @mixin caption;
            color: var(--av-fixed-light);
            @media (--viewport-tablet) {
                @mixin descriptor;
            }
        }
    }
}
</style>
