<template>
    <s-basic-slice class="s-resources" v-bind="$attrs" :background="background">
        <div class="s-resources__content" :class="`s-resources__content-type-${type}`">
            <ul class="s-resources__list" :class="className">
                <li v-for="(card, i) in cards" :key="i" class="s-resources__item">
                    <a-resource-card v-bind="card" />
                </li>
            </ul>
            <div v-if="note" class="s-resources__note" v-html="note" />
            <a-link
                v-if="link"
                class="s-resources__link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AResourceCard from '@core/components/resource-card/resource-card.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SResources',

    components: {
        SBasicSlice,
        AResourceCard,
        ALink,
    },

    inheritAttrs: false,
    props: {
        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 1 && cards.length < 7,
        },

        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        background: {
            type: String,
            default: 'light-blue',
        },

        type: {
            type: String,
            default: 'block',
        },

        note: {
            type: String,
            required: false,
            default: '',
        },
    },

    computed: {
        className() {
            return {
                's-resources__list_layout_short': this.cards.length === 2 || this.cards.length === 4,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-resources {
    &__list {
        @media (--viewport-mobile-wide) {
            display: flex;
            flex-wrap: wrap;
            margin: -8px;
            width: 100%;
        }

        &_layout_short {
            @media (--viewport-desktop) {
                margin: -8px 0;
                justify-content: space-between;
            }

            @media (--viewport-desktop-wide) {
                width: calc(((100% - 32px) / 3) * 2 + 16px);
            }
        }
    }

    &__item {
        &:not(:first-child) {
            margin-top: 16px;

            @media (--viewport-mobile-wide) {
                margin-top: 8px;
            }
        }

        @media (--viewport-mobile-wide) {
            width: calc(50% - 16px);
            margin: 8px;
        }

        @media (--viewport-desktop) {
            width: calc((100% - 48px) / 3);
        }

        .s-resources__list_layout_short & {
            @media (--viewport-desktop) {
                width: calc(50% - 8px);
                margin: 8px 0;
            }
        }
    }

    &__link {
        margin: 56px 0 0;

        @media (--viewport-desktop) {
            margin: 0;
            position: absolute;
            top: 8px;
            inset-inline-end: var(--container-paddings-mobile-wide);
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: var(--container-paddings-desktop-wide);
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: var(--container-paddings-desktop-large);
        }
    }

    &__note {
        @mixin body;
        max-width: 212px;
        margin-top: 40px;
        a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }
        @media (--viewport-mobile-wide) {
            max-width: none;
        }
    }

    &__content-type-scroll {
        .s-resources {
            &__list {
                margin: 0 -16px -16px;
                display: flex;
                flex-wrap: nowrap;
                width: calc(100% + 16px * 2);
                padding: 0 0 16px;
                padding-inline-start: 16px;
                overflow-x: auto;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    height: 0;
                    background: rgba(255, 255, 255, 0);
                }
            }
            &__item {
                display: block;
                margin-inline-end: 16px;
            }
        }
        @media (--viewport-mobile-wide) {
            .s-resources {
                &__list {
                    margin: 0 -32px -16px;
                    padding: 0 0 16px;
                    padding-inline-start: 32px;
                    width: calc(100% + 32px * 2);
                }
                &__item {
                    display: block;
                    min-width: 284px;
                    margin-inline-end: 16px;
                }
            }
        }
        @media (--viewport-desktop) {
            .s-resources {
                &__list {
                    margin: 0 -8px;
                    padding: 0;
                    flex-wrap: wrap;
                    width: calc(100% + 16px);
                }
                &__item {
                    min-width: 0;
                    width: calc(33.33% - 16px);
                    margin: 8px;
                }
                &__list_layout_short {
                    .s-resources__item {
                        width: calc(50% - 16px);
                    }
                }
            }
        }
    }
}
</style>
