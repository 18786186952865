export const Mock = [
    {
        title: 'Title #1',
        sections: [
            {
                subtitle: {
                    text: 'Title with Body.',
                    tooltip: "it's me again",
                },
                subsection: [
                    {
                        text: 'Microsoft Hyper- V Server 2019, 2016, 2012 / 2012 R2, 2008 / 2008 R2',
                        tooltip: 'hi, my dear friends',
                    },
                ],
            },
        ],
    },
    {
        title: 'Long Title Long Title #2',
        sections: [
            {
                subsection: [
                    'CPU 800 MHz',
                    'RAM 256 MB',
                    'Disk Space 150 MB Disk Space 150 MB',
                    'BIOS- or UEFI-based boot firmware',
                ],
                list: [
                    '<a href="google.com">Google</a>',
                    '<span>Facebook</span>',
                ],
            },
            {
                subtitle: 'Title with list',
                subsection: ['CPU 800 MHz'],
            },
        ],
    },
    {
        title: {
            text: 'Title #3',
            to: '/',
            tooltip: 'Hi again, glad to see you!',
        },
        sections: [
            {
                subsection: [
                    {
                        text: 'Office 365',
                        to: '#',
                    },
                    {
                        text: 'Azure5',
                        to: '#',
                    },
                    {
                        text: 'Amazon EC2',
                        to: '#',
                    },
                ],
            },
        ],
    },
    {
        title: 'Title #4',
        sections: [
            {
                subtitle: 'Title with list',
                subsection: [
                    'CPU 800 MHz',
                    'RAM 256 MB',
                    'Disk Space 150 MB Disk Space 150 MB',
                    'BIOS- or UEFI-based boot firmware',
                ],
            },
        ],
    },
    {
        title: 'Title #5',
        sections: [
            {
                subsection: [
                    {
                        text: 'Windows PC',
                        to: '#',
                    },
                    {
                        text: 'MAC',
                        to: '#',
                    },
                ],
            },
            {
                subtitle: 'Title with Body.',
                subsection: [
                    {
                        text: 'Windows PC',
                        to: '#',
                    },
                    {
                        text: 'MAC',
                        to: '#',
                    },
                ],
            },
        ],
    },
    {
        title: 'Title #6',
        sections: [
            {
                subtitle: 'Title with Body.',
                subsection: [
                    '2016 / 2012 R2 / 2012 / 2008 R2 / 2008 / Small Business Server 2011 Standard / 2003 R2 / 20',
                ],
            },
        ],
    },
];

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=46230-83476&t=JMJ2fbzoLIVoGwai-4';

export const Columnsx1 = {
    args: {
        columnsCount: 1,
        title: 'Supported System',
        image: 'images/image_placeholder',
        imageAlt: 'Alt',
        imageAlign: 'top',
        link: { text: 'Optional Link', to: '#' },
        systemInfo: Mock.slice(0, 3),
    },
    argTypes: {
        columnsCount: {
            options: [1],
        },
    },
};

export const Columnsx2 = {
    args: {
        columnsCount: 2,
        title: 'Supported System',
        image: 'images/image_placeholder',
        imageAlt: 'Alt',
        link: { text: 'Optional Link', to: '#' },
        systemInfo: Mock.slice(0, 4),
    },
    argTypes: {
        columnsCount: {
            options: [2],
        },
    },
};

export const Columnsx3 = {
    args: {
        columnsCount: 3,
        title: 'Supported System',
        image: 'images/image_placeholder',
        imageAlt: 'Alt',
        link: { text: 'Optional Link', to: '#' },
        systemInfo: Mock.slice(0, 4),
    },
    argTypes: {
        columnsCount: {
            options: [3],
        },
    },
};
