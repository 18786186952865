/* eslint-disable max-len */
const USER_AVATAR = '/images/intro/quote_author';
const LOGO = '/images/partner_logos/mono/heg';
const LOGO_2 = '/images/partner_logos/mono/aws';
const LOGO_3 = '/images/partner_logos/mono/cdw';
const LOGO_4 = '/images/partner_logos/mono/comparex';
const REVIEW = {
    title: 'Finally, data security I can trust and afford',
    text: 'Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console.',
    author: 'Andrés F. Ambrosi Chica',
};
const ROLE = 'IT security director';
const CTA = {
    text: 'Custom CTA text',
    link: 'https://acronis.com',
};
const COMPANY_NAME = 'HEG GmbH';

const ARG_DEFAULTS = {
    background: 'white',
    type: 'personal',
    title: 'Product reviews',
    subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
    infinite: false,
    averageRatingText: 'Average rating, out of 5',
};

export const Figma = 'https://www.figma.com/file/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?type=design&node-id=27959-11100';

export const PersonalCustomizeCta = {
    name: 'Personal: customize CTA',
    args: {
        ...ARG_DEFAULTS,
        type: 'personal',
        showMoreText: 'Custom Read More text',
        showLessText: 'Custom Read Less text',
        reviews: [
            {
                ...REVIEW,
                role: 'Head o IT, Scuderia Torro Rosso',
                image: USER_AVATAR,
                rate: 3.5,
                cta: CTA,
            },
            {
                ...REVIEW,
                role: 'Head o IT, Scuderia Ferrari',
                image: USER_AVATAR,
                rate: 4,
            },
            {
                ...REVIEW,
                role: 'Head o IT, Alpine',
                image: USER_AVATAR,
                rate: 1,
                cta: CTA,
            },
            {
                ...REVIEW,
                role: 'Head o IT, AMG Mercedes Petronas',
                image: USER_AVATAR,
                rate: 2.7,
            },
        ],
    },
};

export const PersonalPhotoLogoRating = {
    name: 'Personal: photo + logo + rating',
    args: {
        ...ARG_DEFAULTS,
        type: 'personal',
        allReviewsLink: { text: 'Read all reviews', to: '#' },
        reviews: [
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 3.5,
                logo: LOGO,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 4.8,
                logo: LOGO_2,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 1.2,
                logo: LOGO_3,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 3,
                logo: LOGO_4,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
        ],
    },
};

export const PersonalPhotoLogo = {
    name: 'Personal: photo + logo',
    args: {
        ...ARG_DEFAULTS,
        allReviewsLink: { text: 'Read all reviews', to: '#' },
        type: 'personal',
        reviews: [
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
        ],
    },
};

export const PersonalPhoto = {
    name: 'Personal: photo',
    args: {
        ...ARG_DEFAULTS,
        type: 'personal',
        reviews: [
            {
                ...REVIEW,
                image: USER_AVATAR,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
        ],
    },
};

export const CompanyLogo = {
    name: 'Company: logo',
    args: {
        ...ARG_DEFAULTS,
        type: 'company',
        reviews: [
            {
                ...REVIEW,
                role: 'Head o IT, Scuderia Torro Rosso',
                logo: LOGO,
            },
            {
                ...REVIEW,
                role: 'Head o IT, Scuderia Ferrari',
                logo: LOGO,
            },
            {
                ...REVIEW,
                role: 'Head o IT, Alpine',
                logo: LOGO,
            },
            {
                ...REVIEW,
                role: 'Head o IT, AMG Mercedes Petronas',
                logo: LOGO,
            },
        ],
    },
};

export const CompanyRating = {
    name: 'Company: logo + rating',
    args: {
        ...ARG_DEFAULTS,
        type: 'company',
        reviews: [
            {
                ...REVIEW,
                role: 'Head o IT, Scuderia Torro Rosso',
                logo: LOGO,
                rate: 3.5,
            },
            {
                ...REVIEW,
                role: 'Head o IT, Scuderia Ferrari',
                logo: LOGO,
                rate: 4,
            },
            {
                ...REVIEW,
                role: 'Head o IT, Alpine',
                logo: LOGO,
                rate: 1,
            },
            {
                ...REVIEW,
                role: 'Head o IT, AMG Mercedes Petronas',
                logo: LOGO,
                rate: 2.7,
            },
        ],
    },
};
