<template>
    <div v-show="isVisible()" :class="['s-purchasing-acp-total', `s-purchasing-acp-total-${settings.mode}`]">
        <div class="wrapper">
            <div class="title" v-html="title" />
            <div class="info">
                <div class="price">
                    <p v-if="price.discount" class="price-full" v-html="setCurrency(price.discount + price.current)" />
                    <p class="price-current" v-html="setCurrency(price.current, { zeroByDefault: true })" />
                </div>
                <div class="price-vat" v-html="translation('VAT included')" />
            </div>
        </div>

        <s-warnings
            v-if="settings.mode === 'addons'"
            :list="cloudWarnings"
            @changePage="$emit('changePage', 'products')"
        />

        <a-button v-bind="button" :disabled="!price.current" @click="handleClick" />
        <a-loader :is-visible="isVisibleSURL" />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALoader from '@core/components/loader/loader.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';
import SWarnings from './warnings.vue';

export default {
    name: 'STotal',

    components: {
        AButton,
        ALoader,
        SWarnings,
    },

    mixins: [mixin, analytics, currency, cleverbridge],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changePage'],

    data: () => ({
        price: {
            discount: 0,
            current: 0,
        },
    }),

    computed: {
        isSimple() {
            return this.settings?.mode === 'simple';
        },

        button() {
            return this.settings?.button || {
                size: 's',
                type: 'action',
                glyph: 'shopping-cart-o',
                event: { doNotSendGA: true },
                text: this.translation('Proceed to checkout'),
            };
        },

        title() {
            return this.translation(this.settings?.title || 'Subtotal:');
        },
    },

    methods: {
        priceTotal(type) {
            return this.addedLicenses
                .map((license) => license.quantity * (license.store?.[type] || 0))
                .reduce((a, b) => a + b, 0);
        },

        isVisible() {
            this.price.current = this.priceTotal('price');
            if (this.settings.promo.isPromoActive) {
                this.price.discount = this.priceTotal('discount_absolute');
            }
            return this.isSimple || this.price.current;
        },

        handleClick() {
            const list = {};
            this.addedLicenses.forEach((product) => {
                list[product.store.cleverbridge_id] = product.quantity;
            });
            const productsID = Object.keys(list).toString();
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'AcronisEcommerce',
                eventLabel: productsID,
                eventAction: 'Proceed to checkout',
                eventContext: 'add',
                eventCategory: 'Conversions',
                eventContent: this.brandName,
                eventLocation: `${this.pageCategory}`,
                eventProductName: '',
                eventProductPrice: this.price.current,
                eventProductId: productsID,
                eventPosition: 1,
                ecommerce: {
                    currencyCode: this.productCurrency,
                    add: { products: this.ecommerceProducts(list)[0] },
                },
            });

            const cart = Object.keys(list);
            const quantity = this.addedLicenses.map((item) => [`quantity_${item.cleverBridgeCartID}`, item.quantity]);

            // temporary solution for issue with cleverbridge [WEB-44870]
            // id: 243465, 243467, 243469 - should be sent first to not cause problems with discount
            ['243465', '243467', '243469'].forEach((item) => {
                if (cart.includes(item)) {
                    cart.splice(cart.indexOf(item), 1);
                    cart.unshift(item);
                }
            });

            const options = this.storeOptionsSURL({
                shopId: 837,
                segment: 'corporate',
                params: {
                    scope: 'checkout',
                    cart: cart.toString(),
                    ...Object.fromEntries(quantity),
                    'x-source': this.isSimple ? 'simple_mode' : 'expert_mode',
                },
            });

            const promo = this.settings?.promo || {};
            if (promo.coupon) {
                if (promo.isPromoActive) {
                    options.params.coupon = promo.coupon;
                }
                if (promo.coupon) {
                    options.params.coupon = promo.coupon;
                }
            }

            this.redirectToCleverbridge(options);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-total {
    .info {
        display: flex;
        text-align: end;
        flex-direction: column;
        color: var(--av-fixed-primary);
    }
    .price-full {
        @mixin paragraph;
        color: var(--av-fixed-light);
        text-decoration: line-through;
    }
    .price-current {
        @mixin title-accent;
    }
    .price-vat {
        @mixin body;
    }
    .a-button {
        @mixin paragraph-accent;
        width: 100%;
        display: flex;
        margin: 16px auto 0;
        justify-content: center;
        flex-direction: row-reverse;
        &:deep(.a-glyph) {
            margin-inline-start: 0;
            margin-inline-end: 8px;
        }
    }
}

.s-purchasing-acp-total-expert {
    z-index: 2;
    margin-top: auto;
    text-align: center;
    padding: 24px 16px 32px;
    box-shadow: 0 -10px 10px 0 rgba(36, 49, 67, 0.1);
    border-top: 2px solid var(--av-brand-secondary-light);
    border-bottom: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        bottom: 0;
        position: sticky;
        padding-inline-end: 64px;
        padding-inline-start: 40px;
        background: var(--av-inversed-primary);
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .title {
        @mixin title-accent;
        color: var(--av-nav-primary);
    }
}

.s-purchasing-acp-total-addons {
    margin: 24px 0 0;
    padding: 24px 0 0;
    border-top: 1px solid var(--av-brand-secondary-light);

    .title {
        @mixin nav-title-accent;
        color: var(--av-nav-primary);
    }

    .warnings {
        margin-top: 40px;

        @media (--viewport-tablet) {
            margin-top: 32px;
        }

        @media (--viewport-desktop) {
            margin-top: 64px;
        }
    }

    .info {
        flex-wrap: nowrap;
        margin-top: 16px;
        align-items: flex-start;
        flex-direction: row-reverse;

        @media (--viewport-tablet) {
            flex-direction: column-reverse;
        }

        @media (--viewport-desktop) {
            flex-direction: row-reverse;
        }
    }

    .price {
        flex-direction: column;
        align-items: flex-end;

        @media (--viewport-tablet) {
            width: 100%;
            align-items: end;
            margin-top: 40px;
        }

        @media (--viewport-desktop) {
            margin: 0;
        }
    }

    .price-vat {
        text-align: start;
        white-space: nowrap;
    }

    &:deep(.a-button) {
        @mixin paragraph-accent;
        margin-top: 32px;
    }
}
</style>
