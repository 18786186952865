<template>
    <section :id="id" class="s-customizable-header" :class="{ ...basicClassName, ...className }">
        <a-picture
            v-if="backgroundImage"
            :background="backgroundImage"
            fit="cover"
            is-background
        />
        <video
            v-if="hasVideoBackground"
            class="s-customizable-header__video_bg__video"
            playsinline
            autoplay
            muted
            preload
            loop
            :poster="videoBackground.poster"
        >
            <source
                v-for="(item, index) in videoBackground.sources"
                :key="`video-source-${index}`"
                :src="item.link"
                :type="`video/${item.type}`"
            />
        </video>
        <div
            v-if="hasVideoBackground && videoOverlay.visible"
            class="s-customizable-header__video_bg__overlay"
            :style="`background: ${videoOverlay[isMobile ? 'mobile' : 'desktop']}`"
        />
        <div class="a-container">
            <div class="s-customizable-header__wrapper">
                <div v-if="productTitle" class="s-customizable-header__productTitle">
                    {{ productTitle }}
                </div>
                <breadcrumbs
                    v-if="breadcrumbs.length"
                    class="s-customizable-header__breadcrumbs"
                    :sublinks="true"
                    :links="breadcrumbs"
                />
                <div
                    class="s-customizable-header__content"
                    :class="{
                        'has-product': productTitle && productTitle.length,
                        'has-media': media,
                        'has-breadcrumbs': breadcrumbs.length
                    }"
                >
                    <div class="s-customizable-header__content__title__wrapper">
                        <h1
                            v-if="isHeaderTag"
                            class="s-customizable-header__content__title"
                            :class="titleClass"
                            v-html="title.text"
                        />
                        <div
                            v-else
                            class="s-customizable-header__content__title"
                            :style="titleStyle"
                            v-html="title.text"
                        />
                        <div
                            v-if="titleSecondary && alignment !== 'left'"
                            class="s-customizable-header__content__title__secondary"
                            :class="secondaryTitleClass"
                            v-html="titleSecondary.text"
                        />
                    </div>
                    <div
                        v-if="lead"
                        class="s-customizable-header__content__lead"
                        :class="leadClass"
                        v-html="lead.text"
                    />
                    <div
                        v-if="(isMobile || isTablet) && media && alignment === 'left'"
                        class="s-customizable-header__media__wrapper mobile"
                    >
                        <div class="s-customizable-header__media__container">
                            <a-media class="s-customizable-header__media" v-bind="media" />
                        </div>
                    </div>
                    <div v-if="buttons || link" class="s-customizable-header__actions" :class="{ 'has-media': media }">
                        <div v-if="buttons" class="s-customizable-header__actions__buttons">
                            <a-button
                                v-for="(button, index) in buttons"
                                :key="`customizable_header_button_${index}`"
                                class="s-customizable-header__actions__buttons__button"
                                v-bind="button"
                            />
                            <a-link
                                v-if="link"
                                class="s-customizable-header__actions__link"
                                v-bind="link"
                                :glyph="link.glyph || 'arrow'"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="isDesktop && media && alignment === 'left'"
                    class="s-customizable-header__media__wrapper"
                >
                    <div class="s-customizable-header__media__container">
                        <a-media class="s-customizable-header__media" v-bind="media" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Breadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import { THEMES, ALIGNMENTS, BACKGROUNDS, BORDERS } from './constants.js';

export default {
    name: 'SCustomizableHeader',
    components: {
        Breadcrumbs,
        AButton,
        ALink,
        AMedia,
        APicture,
    },
    mixins: [contentMixin, styleMixin, breakpoint],
    props: {
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        productTitle: {
            type: String,
            default: undefined,
        },
        isHeaderTag: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            validator: (value) => !value || THEMES.includes(value),
            default: 'light',
        },
        videoBackground: {
            type: Object,
            default: undefined,
        },
        videoOverlay: {
            type: Object,
            default() {
                return {
                    visible: true,
                    mobile: 'linear-gradient(180deg, #00204d 34.31%, rgba(0, 32, 77, 0) 74.2%)',
                    desktop: 'linear-gradient(90deg, #00204d 29.45%, transparent 60.35%)',
                };
            },
        },
        background: {
            type: String,
            validator: (value) => !value || BACKGROUNDS.includes(value),
            default: 'white',
        },
        backgroundImage: {
            type: Object,
            default: undefined,
        },
        /**
         * Media Component: Only for left alignment
         */
        media: {
            type: Object,
            default: undefined,
        },
        borderBottom: {
            type: String,
            default: 'none',
            validator: (value) => !value || BORDERS.includes(value),
        },
        alignment: {
            type: String,
            required: true,
            validator: (value) => !value || ALIGNMENTS.includes(value),
        },
        title: {
            type: Object,
            default: undefined,
        },
        titleSecondary: {
            type: Object,
            default: undefined,
        },
        lead: {
            type: Object,
            default: undefined,
        },
        buttons: {
            type: Array,
            default: undefined,
        },
        link: {
            type: Object,
            default: undefined,
        },
    },
    data: () => ({
        titleClass: '',
        secondaryTitleClass: '',
        leadClass: '',
    }),
    computed: {
        className() {
            const { alignment, background, theme, hasVideoBackground } = this;
            return {
                's-customizable-header__dark': theme === 'dark' || background === 'dark-blue',
                's-customizable-header__video_bg': hasVideoBackground,
                [`s-customizable-header__${alignment}`]: alignment,
            };
        },
        hasVideoBackground() {
            return (
                this.alignment === 'left' &&
                this.videoBackground &&
                this.videoBackground.sources &&
                this.videoBackground.sources.length
            );
        },
    },
    created() {
        this.titleClass = [`font-size-${this.title?.size}`, `line-height-${this.title?.height}`];
        this.secondaryTitleClass = [`font-size-${this.titleSecondary?.size}`, `line-height-${this.titleSecondary?.height}`];
        this.leadClass = [`font-size-${this.lead?.size}`, `line-height-${this.lead?.height}`];
    },
};
</script>

<style lang="postcss" scoped>
.s-customizable-header {
    min-height: 900px;
    padding: 56px 0 64px;

    @media (--viewport-tablet) {
        min-height: 618px;
    }

    @media (--viewport-desktop) {
        min-height: 424px;
        padding: 64px 0 64px;
    }

    &__video_bg {
        position: relative;
        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
        }
        &__video {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
        }
    }
    &__wrapper {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }
    &__wrapper:has(.is-modal-open) {
        z-index: 9999;
    }
    &__productTitle {
        @mixin colls 12;
        @mixin display;
        display: none;
        margin-top: 8px;
        color: var(--av-nav-primary);

        @media (--viewport-desktop) {
            display: block;
        }
    }
    &__content {
        &__title,
        &__title__secondary {
            font-weight: 700;
            color: var(--av-nav-primary);
        }
        &__lead {
            color: var(--av-nav-primary);
        }
    }
    &__actions {
        @mixin colls 10;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        @media (--viewport-mobile-wide) {
            @mixin colls 8;
        }
        @media (--viewport-tablet) {
            @mixin colls 12;
        }
        &__buttons {
            width: 100%;
            @media (--viewport-tablet) {
                width: auto;
            }
            &__button {
                @mixin paragraph-accent;
                padding: 8px 16px;
                margin-bottom: 16px;
                min-width: 100%;

                @media (--viewport-tablet) {
                    margin-bottom: 0;
                    margin-inline-end: 16px;
                    min-width: 152px;
                }
            }
        }
        &__link {
            width: 100%;
            text-align: center;
            color: var(--av-brand-primary);
            font-weight: 600;
            @media (--viewport-tablet) {
                width: auto;
                text-align: start;
            }
        }
    }

    /* Left variant */
    &__left {
        &:deep(.a-container) {
            @media (--viewport-desktop) {
                min-height: 416px;
                display: flex;
                flex-flow: column;
            }
        }
        .s-customizable-header {
            &__content {
                @mixin colls 12;
                margin-top: 48px;

                @media (--viewport-tablet) {
                    @mixin colls 8;
                    width: auto;
                    padding-inline-start: 68px;
                    padding-inline-end: 68px;
                }

                @media (--viewport-desktop) {
                    @mixin colls 8;
                    margin-top: 16px;
                    padding: 0;
                }

                @media (--viewport-desktop-wide) {
                    @mixin colls 6;
                }

                &__title {
                    @mixin display;
                    text-align: center;

                    @media (--viewport-desktop) {
                        @mixin hero;
                        text-align: start;
                    }
                }
                &__lead {
                    @mixin lead;
                    margin-top: 40px;
                    text-align: center;

                    @media (--viewport-tablet) {
                        @mixin paragraph;
                        margin-top: 32px;
                    }

                    @media (--viewport-desktop) {
                        text-align: start;
                    }
                }
                &.has-breadcrumbs {
                    margin-top: 0;
                }
                &.has-media {
                    @media (--viewport-tablet) {
                        @mixin colls 12;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 6;
                    }
                }
            }
            &__media__wrapper {
                @mixin colls 12;
                margin-top: 0;
                display: none;

                @media (--viewport-desktop) {
                    position: absolute;
                    display: block;
                    right: 0;
                    top: 128px;
                    width: 387px;
                }

                @media (--viewport-desktop-wide) {
                    top: 64px;
                }

                &.mobile {
                    display: block;
                    margin: 48px 0;

                    @media (--viewport-desktop) {
                        display: none;
                    }
                }
            }
            &__media__container {
                max-width: 328px;
                margin: 0 auto;

                @media (--viewport-desktop) {
                    max-width: 387px;
                }
            }
            &__actions {
                margin-top: 48px;
                justify-content: center;

                @media (--viewport-desktop) {
                    margin-top: 56px;
                    justify-content: flex-start;
                }

                @media (--viewport-desktop-wide) {
                    margin-top: 48px;
                }

                &.has-media {
                    margin-top: 0;
                    @media (--viewport-desktop) {
                        margin-top: 56px;
                    }
                }
            }
            &__wrapper {
                @media (--viewport-tablet) {
                    flex-flow: column;
                }

                @media (--viewport-desktop) {
                    align-items: flex-start;
                }
            }
            &__productTitle {
                @media (--viewport-tablet) {
                    font-weight: 400;
                }
            }
        }
    }

    /* Center variant */
    &__center {
        .s-customizable-header {
            &__content {
                @mixin colls 12;
                margin-top: 48px;

                @media (--viewport-desktop) {
                    margin-top: 16px;
                }

                &__title__wrapper {
                    @mixin colls 12;
                    margin: 0 auto;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    text-align: center;
                    @media (--viewport-tablet) {
                        @mixin colls 8;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 10;
                    }
                }
                &__title {
                    margin-bottom: 16px;
                }
                &__title,
                &__title__secondary {
                    @mixin colls 12;
                    @mixin display;
                    @mixin large-accent;

                    @media (--viewport-desktop) {
                        font-size: 64px;
                        line-height: 72px;
                    }
                }
                &__lead {
                    @mixin title;
                    @mixin colls 12;
                    margin: 40px auto 0;
                    text-align: center;

                    @media (--viewport-tablet) {
                        @mixin colls 8;
                    }

                    @media (--viewport-desktop) {
                        @mixin colls 10;
                    }
                }
            }
            &__actions {
                margin-top: 48px;
                justify-content: center;
                text-align: center;

                @media (--viewport-tablet) {
                    margin-top: 64px;
                }

                &__buttons {
                    &__button:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

    /* Left-right variant */
    &__left-right {
        .s-customizable-header {
            &__content {
                @mixin colls 12;
                margin-top: 48px;

                @media (--viewport-desktop) {
                    margin-top: 16px;
                }

                &__title__wrapper {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    justify-content: space-between;
                }
                &__title,
                &__title__secondary {
                    @mixin colls 12;
                    @mixin large-accent;
                    @media (--viewport-tablet) {
                        @mixin colls 6;
                    }
                    @media (--viewport-desktop) {
                        font-size: 64px;
                        line-height: 64px;
                    }
                }
                &__title__secondary {
                    text-align: end;
                    margin-top: 24px;
                    @media (--viewport-tablet) {
                        margin-top: 0;
                    }
                }
                &__lead {
                    margin-top: 40px;
                    text-align: center;

                    @media (--viewport-tablet) {
                        width: auto;
                        margin-top: 32px;
                    }

                    @media (--viewport-desktop) {
                        @mixin colls 7;
                        text-align: start;
                    }

                    @media (--viewport-desktop-wide) {
                        @mixin colls 6;
                    }
                }
            }
            &__actions {
                width: 100%;
                margin-top: 48px;

                @media (--viewport-tablet) {
                    justify-content: center;
                    margin-top: 56px;
                }

                @media (--viewport-desktop) {
                    justify-content: flex-start;
                }
            }
        }
    }
    &__breadcrumbs {
        &:deep(.a-container) {
            min-height: min-content;
            margin: 16px 0;
            padding-inline-start: 0;

            .a-link__content,
            .a-breadcrumbs__current {
                color: var(--av-link-inversed) !important;
            }
            .a-glyph {
                fill: var(--av-link-inversed) !important;
            }

            .a-breadcrumbs__current {
                background-color: var(--av-brand-light);
                border-radius: 4px;
            }
        }
    }
}

/* Dark mode */
.s-slice_background_dark-blue,
.s-customizable-header__dark {
    .s-customizable-header__productTitle,
    .s-customizable-header__content__title,
    .s-customizable-header__content__title__secondary,
    .s-customizable-header__content__lead {
        color: var(--av-inversed-primary) !important;
    }
    .s-customizable-header__actions__link {
        &:deep(.a-link__content) {
            @mixin paragraph-accent;
        }
    }
}
</style>
