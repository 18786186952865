<template>
    <header
        class="s-acronis-scs-main-header"
        :class="{ ...basicClassName, active: isActive, 'extra-space': extraSpace }"
    >
        <div class="a-container">
            <div class="content">
                <a class="logo-link" :href="logoLink">
                    <a-scs-logo class="acronis-scs-logo" />
                </a>
                <nav v-if="menuIsAvailable && isDesktop" class="nav">
                    <ul class="menu">
                        <li
                            v-for="(item, index) in headerMenu"
                            :key="`menu-item-${index}`"
                            class="menu-item"
                            :class="{ active: item.id === activeTabId }"
                        >
                            <button
                                v-if="!item.to"
                                :ref="`menuLink_${item.id}`"
                                class="menu-link"
                                type="button"
                                @click.prevent.stop="selectTab(item.id), switchMenuState('fix')"
                            >
                                {{ item.name }}
                            </button>
                            <a-link v-else v-bind="item" class="menu-link" />
                        </li>
                    </ul>
                </nav>
                <div v-if="panelIsAvailable && login" class="buttons">
                    <dynamic-link class="login-link" :to="login.to">
                        <a-glyph class="glyph" :name="login.glyph || 'group'" />
                        <span v-if="isDesktop">{{ login.text }}</span>
                    </dynamic-link>
                    <template v-if="isMobile || isTablet">
                        <div class="separator" />
                        <button
                            class="menu-toggler"
                            :class="{ active: isActive }"
                            type="button"
                            tabindex="1"
                            @click.prevent.stop="toggleMenu()"
                        >
                            <span v-if="!isActive" class="opened-text">{{ upText }} </span>
                            <span v-if="isActive" class="closed-text">{{ downText }}</span>
                            <a-glyph class="icon" :name="getMenuTogglerIcon" />
                        </button>
                    </template>
                </div>
            </div>
        </div>

        <div
            v-show="isActive"
            ref="menu"
            class="main-menu"
            :style="mainMenuStyle"
        >
            <div v-if="isMobile || isTablet" class="accordion-wrapper">
                <a-accordion class="accordion">
                    <template v-for="(item, index) in getTabs">
                        <a-accordion-item
                            v-if="!item.to"
                            :key="`menu-tab-${index}`"
                            :title="item.name"
                        >
                            <s-menu-tab :menu="item.data" :active-tab-id="activeTabId" />
                        </a-accordion-item>
                        <li v-else :key="`menu-tab-${index}`" class="a-accordion-item">
                            <a-link class="title-link" v-bind="item" />
                        </li>
                    </template>
                </a-accordion>
            </div>
            <template v-if="isDesktop">
                <button
                    class="closer"
                    type="button"
                    aria-label="Close menu"
                    @click="hideMenu"
                >
                    <a-glyph class="closer-icon" name="big-close" />
                </button>

                <div class="tabs-wrapper">
                    <div class="tab">
                        <s-menu-tab
                            v-if="activeTabId"
                            ref="menuTab"
                            :menu="activeTab.data"
                            :active-tab-id="activeTabId"
                        />
                    </div>
                </div>
            </template>
        </div>
    </header>
</template>

<script>
import { get, cloneDeepWith } from 'lodash';
import { mapState } from 'vuex';
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import AScsLogo from '@core/components/scs-logo/scs-logo.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SMenuTab from './menu-tab.vue';

export default {
    name: 'SAcronisScsHeader',
    components: {
        SMenuTab,
        ALink,
        AGlyph,
        DynamicLink,
        AScsLogo,
        AAccordion,
        AAccordionItem,
    },
    mixins: [breakpoint, contentMixin, styleMixin],
    props: {
        logoLink: {
            type: String,
            default: '/index/',
        },
        menuIsAvailable: {
            type: Boolean,
            default: true,
        },
        panelIsAvailable: {
            type: Boolean,
            default: true,
        },
        listToUpdate: {
            type: Array,
            default: null,
        },
        extraSpace: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            STORE_SLICE_NAME: 's-acronis-scs-header',
            activeTabId: null,
            isActive: false,
            menuState: null,
            leftMenuMargin: 0,
        };
    },
    computed: {
        ...mapState({
            upText: (state) => get(state.slices.items, 's-acronis-scs-header.upText', ''),
            downText: (state) => get(state.slices.items, 's-acronis-scs-header.downText', ''),
            headerMenu: (state) => get(state.slices.items, 's-acronis-scs-header.menu', {}),
            login: (state) => get(state.slices.items, 's-acronis-scs-header.login', {}),
        }),
        getMenuText() {
            return this.isActive ? this.downText : this.upText;
        },
        getMenuTogglerIcon() {
            return this.isActive ? 'big-close' : 'burger-menu';
        },
        getTabs() {
            if (this.listToUpdate) {
                return cloneDeepWith(this.headerMenu, (value) => {
                    const link = this.listToUpdate.find((item) => item.to === value.to);

                    if (get(link, 'queryString', false)) {
                        const queryString = new URLSearchParams(link.queryString);
                        return { ...value, to: `${value.to}?${queryString.toString()}` };
                    }

                    return undefined;
                });
            }

            return this.headerMenu;
        },
        activeTab() {
            return this.activeTabId
                ? this.getTabs.find((tab) => tab.id === this.activeTabId)
                : null;
        },
        mainMenuStyle() {
            return { left: `${this.leftMenuMargin}px` };
        },
    },
    watch: {
        isDesktop() {
            this.hideMenu();
        },
    },
    mounted() {
        window.addEventListener('resize', this.calculateMenuPosition);
        document.addEventListener('click', this.onMainMenuClickAway);
    },
    unmounted() {
        window.removeEventListener('resize', this.calculateMenuPosition);
        document.removeEventListener('click', this.onMainMenuClickAway);
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
        },
        selectTab(to) {
            if (this.activeTabId === to) {
                if (this.isActive) {
                    this.activeTabId = null;
                }
                this.toggleMenu();
                return;
            }
            this.isActive = true;
            this.activeTabId = to;
        },
        switchMenuState(actionType) {
            if (this.isDesktop) {
                if (actionType !== this.menuState) {
                    if (!(this.menuState === 'fix' && actionType === 'hover')) {
                        this.menuState = actionType;
                        this.isActive = true;
                    }
                }
            }
            this.$nextTick(() => this.calculateMenuPosition());
        },
        onMainMenuClickAway(event) {
            if (event.target !== this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.hideMenu();
            }
        },
        hideMenu() {
            this.menuState = null;
            this.isActive = false;
            this.activeTabId = null;
        },
        calculateMenuPosition() {
            if (!this.$refs.menuTab) {
                this.leftMenuMargin = 0;
                return;
            }
            const activeLink = get(this.$refs, `menuLink_${this.activeTabId}.0`, null);
            const leftMarginRelativeToLink = activeLink
                ? activeLink.getBoundingClientRect().left + activeLink.clientWidth / 2
                : 0;

            this.leftMenuMargin = leftMarginRelativeToLink;
        },
    },
    serverPrefetch() {
        this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: 'en-us' });
    },
};
</script>

<style lang="postcss" scoped>
header.s-acronis-scs-main-header {
    position: absolute;
    top: 24px;
    width: 100%;

    /* WEB-43924 why do we use 9000 here ? */
    z-index: 9000;
    @media (--viewport-mobile-wide) {
        top: 32px;
    }
    .content {
        display: flex;
        align-items: center;
        @media (--viewport-desktop) {
            justify-content: space-between;
        }
        .logo-link {
            display: inline-block;
            margin-inline-end: 16px;
            max-width: 160px;
            max-height: 20px;
            @media (--viewport-mobile-wide) {
                margin: 0;
                max-height: 32px;
                max-width: 257px;
            }
            .logo {
                width: 100%;
                max-height: 20px;
                @media (--viewport-mobile-wide) {
                    max-height: 32px;
                }
            }
        }
        .nav {
            .menu {
                display: flex;
                flex-flow: row nowrap;
                gap: 24px;
                align-items: center;
                .menu-item {
                    button.menu-link {
                        padding: 0 6px;
                    }
                    .menu-link,
                    &:deep(.a-link__content) {
                        color: var(--av-nav-primary);
                        @mixin paragraph;
                        text-decoration: none;
                        border: none;
                        background: none;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
        }
        .buttons {
            display: flex;
            align-items: center;
            margin-inline-start: auto;
            gap: 16px;
            @media (--viewport-mobile-wide) {
                gap: 24px;
            }
            @media (--viewport-desktop) {
                margin: 0;
            }
            .login-link {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0;
                color: var(--av-nav-primary);
                @mixin paragraph;
                text-decoration: none;
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                &:deep(.a-glyph) {
                    fill: var(--av-nav-primary);
                }
            }
            .separator {
                height: 24px;
                width: 1px;
                background: var(--av-nav-primary);
                border-radius: 1px;
            }
            .menu-toggler {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0;
                color: var(--av-nav-primary);
                @mixin body;
                text-decoration: none;
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                &:deep(.a-glyph) {
                    height: 21px;
                    width: 24px;
                    fill: var(--av-nav-primary);
                    transition: fill 0.2s;
                }
                &.active {
                    color: var(--av-brand-primary);
                    &:deep(.a-glyph) {
                        height: 12px;
                        width: 24px;
                        fill: var(--av-brand-primary);
                    }
                }
            }
        }
    }
    .main-menu {
        position: absolute;
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        width: 100%;

        /* WEB-43924 why do we use 9000 here ? */
        z-index: 9000;
        margin-top: 24px;
        @media (--viewport-mobile-wide) {
            margin-top: 32px;
        }
        &:before {
            content: '';
            position: absolute;
            display: none;
            left: 50%;
            transform: translateX(-50%);
            bottom: 100%;

            /* WEB-43924 why do we use 9000 here ? */
            z-index: 9000;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent var(--av-inversed-primary) transparent;
        }
        @media (--viewport-desktop) {
            width: auto;
            transform: translateX(-50%);
            padding: 40px 64px;
            margin-top: 12px;
            border-radius: 4px;
            &:before {
                display: block;
            }
        }
        .closer {
            position: absolute;
            inset-inline-end: 64px;
            background: none;
            border: none;
            padding: 0;
            color: var(--av-brand-secondary);
            cursor: pointer;
            &:deep(.a-glyph) {
                vertical-align: baseline;
            }
        }
        &:deep(.tabs-wrapper) {
            padding-inline-end: 48px;
            .tab-item {
                font-weight: bold;
                .a-link__content span {
                    color: var(--av-brand-secondary);
                    font-size: 16px;
                }
            }
        }
        &:deep(.accordion-wrapper) {
            .title {
                @mixin nav-title-accent;
                font-weight: 700;
                padding: 32px;
                display: flex;
                align-items: center;
                color: var(--av-nav-primary);
                gap: 16px;
                .a-glyph {
                    fill: var(--av-brand-primary);
                }
            }
            .icon {
                margin: 0;
            }
            .content-wrapper {
                padding: 32px;
                background: var(--av-brand-lightest);
            }
            .a-accordion-item {
                border-top: var(--av-border);

                .title-link {
                    padding: 32px;
                    display: block;
                    .a-link__content {
                        @mixin nav-title-accent;
                        color: var(--av-nav-primary);
                    }
                }
            }
        }
    }
    &.extra-space {
        @media (--viewport-mobile-wide) {
            top: 48px;
        }
        .main-menu {
            @media (--viewport-mobile-wide) {
                margin-top: 48px;
            }
            @media (--viewport-desktop) {
                margin-top: 12px;
            }
        }
    }
}
</style>
