<template>
    <div class="cta-main">
        <div v-if="hasCta" v-sticky="stickyOptions" class="cta-wrapper">
            <div
                ref="cta"
                class="cta"
                :class="{ 'cta-product': isCTAProduct, 'cta-generic': isCTAGeneric }"
            >
                <template v-if="isCTAProduct">
                    <div class="cta-text">
                        <div class="cta-title">
                            <a-logo class="cta-acronis" type="full" />
                            <span><span class="cta-acronis-text">Acronis</span> {{ ctaProduct.name }}</span>
                        </div>
                        <div v-if="cta.label_text" class="cta-label">
                            <div>{{ cta.label_text }}</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="cta-text">
                        <div class="cta-title">
                            <span>{{ cta.label_text }}</span>
                        </div>
                    </div>
                </template>
                <div class="cta-button-wrap">
                    <a-link
                        v-if="cta.link_url"
                        class="cta-link"
                        :to="cta.link_url"
                        :text="cta.link_text"
                        size="body"
                    />
                    <a-button
                        v-if="hasCtaAction"
                        class="cta-button"
                        :to="cta.button_url"
                        :text="cta.button_text"
                        type="action"
                        :event="isCTAProduct ? getGaEventData(cta.button_text, ctaProduct.name) : null"
                    />
                </div>
            </div>
            <blog-progress-bar class="progressbar" :progress="readProgress" height="3" />
        </div>
    </div>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import Sticky from '@core/directives/sticky.js';
import BlogProgressBar from '@core/slices/blog/shared-components/blog-progressbar/blog-progressbar.vue';

export default {
    name: 'BlogPostCtaMain',

    components: {
        BlogProgressBar,
        ALink,
        AButton,
        ALogo,
    },

    directives: { Sticky },

    props: {
        cta: {
            type: Object as any,
            default: null,
        },
        ctaProduct: {
            type: Object as any,
            default: null,
        },
        readProgress: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        stickyOptions: {
            parent: '.cta-main',
            stickyHeader: true,
        },
    }),

    computed: {
        hasCta() {
            return this.isCTAGeneric || this.isCTAProduct;
        },
        hasCtaAction() {
            return Boolean(this.cta && this.cta.button_text && this.cta.button_url);
        },
        isCTAGeneric() {
            return Boolean(!this.ctaProduct && this.hasCtaAction && this.cta.label_text);
        },
        isCTAProduct() {
            return Boolean(this.ctaProduct && this.ctaProduct.name && this.hasCtaAction);
        },
    },

    methods: {
        getGaEventData(action, content) {
            return {
                category: 'Conversions',
                action,
                content,
                location: 1,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.progressbar {
    display: none;
}

.cta-main {
    margin-bottom: 24px;

    @media(--viewport-tablet) {
        margin-bottom: 32px;
    }
}

.cta {
    background: var(--av-solid-brand-lightest);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 0 -16px;

    @media (--viewport-mobile-wide) {
        border: 1px solid var(--av-brand-secondary-light);
        margin: 0;
        border-radius: 4px;
        padding: 16px 40px;
    }
}

.cta.cta-generic .cta-title {
    @mixin paragraph;
    color: var(--av-nav-primary);
    font-weight: 700;
    letter-spacing: 0;
}

.cta-text {
    text-align: start;
}

.cta-title {
    font-size: 17px;
    line-height: 19px;
    letter-spacing: 0.3px;
    font-weight: 300;
    color: var(--av-fixed-primary);
    margin: 0;

    @media (--viewport-mobile-wide) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 24px;
        line-height: 27px;
    }
}

.cta-acronis {
    display: block;
    height: 12px;
    width: 60px;

    @media (--viewport-mobile-wide) {
        width: 89px;
        height: 18px;
        margin-inline-end: 8px;
    }
}

.cta-label {
    font-size: 11px;
    line-height: 17px;
    color: var(--av-brand-secondary);
    font-weight: bold;

    @media (--viewport-mobile-wide) {
        @mixin paragraph;
    }
}

.cta-wrapper:not(.is-sticky) {
    .cta-text {
        flex-basis: 40%;

        @media (--viewport-mobile-wide) {
            flex-basis: 60%;
        }
    }

    .cta-button-wrap {
        display: flex;
        align-items: center;
        gap: 16px;

        .cta-link {
            flex-shrink: 0;

            @media (--viewport-tablet) {
                flex-shrink: 0;
                margin: 0;
            }
        }

        .cta-button {
            @mixin body;
            padding: 8px 16px;
        }
    }

    .cta.cta-generic {
        .cta-text {
            flex-basis: 50%;

            @media (--viewport-mobile-wide) {
                flex-basis: 45%;
            }

            @media (--viewport-tablet) {
                flex-basis: 60%;
            }
        }

        .cta-button-wrap {
            flex-direction: column;

            @media (--viewport-mobile-wide) {
                flex-direction: row;
            }
        }
    }
}

.cta-acronis-text {
    display: none;
}

.cta-wrapper.is-sticky {
    position: fixed;
    z-index: 999;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    padding: 0;
    margin: auto;
    background: var(--av-solid-brand-lightest);
    box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);
    border-bottom: 3px solid var(--av-brand-light);

    .progressbar {
        display: block;
        position: absolute;
        bottom: -3px;
    }

    .cta {
        gap: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        border: 0;
        border-radius: 0;
        padding: 24px 16px;
        max-width: 1440px;
        margin-inline: auto;

        @media (--viewport-mobile-wide) {
            gap: 32px;
            padding: 16px;
            flex-direction: row;
            justify-content: space-between;
        }

        @media (--viewport-tablet) {
            padding: 19px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 12px 64px;
        }
    }

    .cta-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        text-align: center;

        @media (--viewport-mobile-wide) {
            align-items: flex-start;
        }

        @media (--viewport-tablet) {
            flex-basis: 60%;
        }

        @media (--viewport-desktop) {
            flex-basis: 65%;
        }

        @media (--viewport-desktop-wide) {
            flex-direction: row;
            align-items: flex-end;
            gap: 24px;
            flex-basis: auto;
        }
    }

    .cta-button-wrap {
        display: flex;
        width: 100%;
        gap: 16px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (--viewport-tablet) {
            flex-direction: row;
        }

        .cta-link {
            @mixin body-accent;
            flex-shrink: 0;
            margin: 0;
        }

        .cta-button {
            width: 100%;
            padding: 8px 24px;
        }

        @media (--viewport-mobile-wide) {
            width: auto;
            margin: 0;
        }
    }

    .cta-acronis {
        display: none;

        @media (--viewport-desktop) {
            display: inline;
        }
    }

    .cta-acronis-text {
        display: inline;

        @media (--viewport-desktop) {
            display: none;
        }
    }

    .cta-acronis-text,
    .cta-title {
        @mixin title-accent;
        color: var(--av-nav-primary);
    }

    .cta-title {
        @media (--viewport-mobile-wide) {
            display: inline;
            max-width: 90%;
            text-align: left;
        }

        @media (--viewport-tablet) {
            max-width: initial;
        }

        @media (--viewport-desktop) {
            display: flex;
            align-items: baseline;
            font-weight: 300;
            letter-spacing: 0;
        }
    }

    .cta-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        border-radius: 16px;
        background: linear-gradient(90deg, #6DC8FF 0%, #0071FF 100%);

        @media (--viewport-desktop-wide) {
            margin: 0;
        }

        div {
            @mixin note-heading;
            position: relative;
            width: 100%;
            height: 100%;
            margin: 2px;
            border-radius: 16px;
            padding: 4px 12px;
            color: var(--av-nav-primary);
            background: var(--av-solid-brand-lightest);
        }
    }
}
</style>
