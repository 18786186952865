<template>
    <div class="s-workload-comparison">
        <div class="s-workload-comparison__wrapper">
            <div class="s-workload-comparison__container">
                <div class="s-workload-comparison__header">
                    <p class="s-workload-comparison__title" v-html="title" />
                    <div class="s-workload-comparison__close" @click="comparisonToggle()">
                        <a-glyph name="times" />
                        <a-button :text="close" :event="{ doNotSendGA: true }" />
                    </div>
                    <table class="s-workload-comparison__table">
                        <tr class="s-workload-comparison__table-row">
                            <th
                                v-for="(item, thIndex) in table.slice(0, 1)[0]"
                                :key="`comparison-th-${thIndex}`"
                                class="s-workload-comparison__table-cell"
                            >
                                <a-link
                                    v-if="isObject(item)"
                                    class="s-workload-comparison__link"
                                    v-bind="item"
                                    :glyph-position="item.glyphPosition || 'left'"
                                    :glyph="item.glyph || 'download-arrow'"
                                />
                                <span v-else class="s-workload-comparison__table-cell-title" v-html="item" />
                            </th>
                        </tr>
                    </table>
                </div>
                <table class="s-workload-comparison__table">
                    <tr v-for="(row, trIndex) in table.slice(1)" :key="`comparison-tr-${trIndex}`" class="s-workload-comparison__table-row">
                        <td
                            v-for="(item, tdIndex) in row"
                            :key="`comparison-td-${tdIndex}`"
                            class="s-workload-comparison__table-cell"
                            :class="className(row, item)"
                            v-html="!isNubmer(item) ? item : ''"
                        />
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import mixin from '../mixin.js';

export default {
    name: 'SWorkloadComparison',

    components: {
        AButton,
        AGlyph,
        ALink,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },
    },

    computed: {
        comparison() {
            return this.settings?.comparison || {};
        },
        title() {
            return this.comparison.title || '';
        },

        close() {
            return this.comparison.close || 'Close';
        },

        table() {
            return this.comparison.table || [];
        },
    },

    methods: {
        isObject(item) {
            return item instanceof Object;
        },

        isNubmer(item) {
            return Number.isInteger(item);
        },

        isSubTitle(row) {
            return row.length <= 1;
        },

        className(row, item) {
            const str = item ? 'plus' : 'minus';
            return {
                's-workload-comparison__table-cell-subtitle': this.isSubTitle(row),
                [`s-workload-comparison__table-cell-${str}`]: this.isNubmer(item),
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-workload-comparison {
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    z-index: 401;
    position: fixed;
    overflow: hidden;
    background: #f2f2f2;

    &__wrapper {
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;
        overflow: auto;
        position: absolute;
        margin: 48px 0 40px;
    }

    &__container {
        height: 100%;
        overflow: auto;
        width: 1184px;
        margin: 0 48px;
        padding: 0 64px 48px;
        border-radius: 4px;
        background: var(--av-solid-brand-lightest);
    }

    &__header {
        top: 0;
        inset-inline-start: 0;
        z-index: 3;
        background: var(--av-inversed-primary);
        position: sticky;
        margin: 0 -64px;
        padding: 0 64px;
        box-shadow: var(--av-shadow-small);

        .s-workload-comparison {
            &__table,
            &__table-row {
                border: none;
            }

            &__table-cell {
                padding: 0 0 16px;
                vertical-align: top;

                &:not(:first-child) {
                    padding: 0 16px 16px;
                }
            }
        }
    }

    &__title {
        @mixin title-accent;
        padding: 24px 0;
        color: var(--av-nav-primary);
    }

    &__table {
        width: 100%;
        table-layout: fixed;
        border-bottom: 1px solid var(--av-brand-secondary-light);
    }

    &__table-row {
        &:not(:first-child) {
            border-top: 1px solid var(--av-brand-secondary-light);
        }
    }

    &__table-cell {
        @mixin caption;
        padding: 16px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        color: var(--av-fixed-lighter);

        &:first-child {
            @mixin body;
            padding-inline-start: 0;
            text-align: start;
            color: var(--av-fixed-secondary);
        }
    }

    &__link {
        @mixin paragraph;

        .a-glyph {
            fill: var(--av-brand-primary);
        }
    }

    &__table-cell-title {
        @mixin body-accent;
        color: var(--av-nav-primary);
    }

    &__table-cell-subtitle {
        &:first-child {
            @mixin lead-accent;
            padding-top: 40px;
            color: var(--av-fixed-primary);
        }
    }

    &__table-cell-plus,
    &__table-cell-minus {
        &:before {
            content: '';
            display: block;
            margin: 0 auto;
        }
    }

    &__table-cell-plus {
        &:before {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: var(--av-fixed-success);
        }
    }

    &__table-cell-minus {
        &:before {
            width: 16px;
            height: 2px;
            background: var(--av-fixed-lighter);
        }
    }

    &__close {
        top: 24px;
        z-index: 4;
        inset-inline-end: 40px;
        cursor: pointer;
        position: absolute;

        .a-glyph {
            fill: var(--av-brand-secondary);
            margin: 0 0 2px;
            margin-inline-end: 8px;
            vertical-align: middle;
        }

        .a-button {
            @mixin paragraph;
            border: 0;
            margin: 0;
            padding: 0;
            box-shadow: none;
            color: var(--av-brand-secondary);

            &:hover {
                background: none;
            }
        }
    }
}

@media (--viewport-desktop-wide) {
    .s-workload-comparison {
        &__container {
            margin: 0 auto;
        }
    }
}
</style>
