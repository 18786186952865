<template>
    <el-form-item
        class="phone-number-input"
        :prop="prop"
        :hint="formattedExampleNumber"
        :rules="rules"
    >
        <el-select
            v-model="computedCountry"
            v-bind="labelSelect"
            class="country-input"
            dynamic-width
            filterable
            :change-on-options-removal="false"
            :hide-on-resize="isDesktop"
            :filter-method="onCodeFilter"
            :label="countryLabel"
            :popper-class="popperClass || 'phone-input-dropdown'"
            popper-placement="auto"
            popper-max-height="210px"
            @change="onCodeChange"
        >
            <el-option
                v-for="item in countriesList"
                :key="item.iso_code"
                :label="getCountryMetaData(item.iso_code)?.parsed_code"
                :value="item.iso_code"
            >
                {{ getCountryMetaData(item.iso_code)?.parsed }}
            </el-option>
        </el-select>
        <el-input
            v-model="phone"
            :label="labels.phoneLabel"
            :name="prop"
            type="tel"
            class="phone-input"
            :maxlength="20"
            :minlength="3"
            :required="required"
            @change="onChange"
            @blur="setPhone"
            @input="onInput"
            @paste="onInput"
        />
    </el-form-item>
</template>

<script>
import {
    AsYouType,
    getCountryCallingCode,
    getExampleNumber,
    isSupportedCountry,
    parsePhoneNumber,
} from 'libphonenumber-js/max';
import examples from 'libphonenumber-js/mobile/examples';
import { mapState } from 'vuex';
import breakpoints from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    name: 'APhoneInput',
    components: {
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [form, breakpoints],
    props: {
        prop: {
            type: String,
            default: 'phone-number-input',
        },
        defaultPhone: {
            type: String,
            default: '',
        },
        defaultCountry: {
            type: String,
            default: null,
        },
        settings: {
            type: Object,
            default() {
                return {
                    labels: {
                        example: 'Example:',
                        countryLabel: 'Country code',
                        phoneLabel: 'Phone',
                    },
                };
            },
        },
        popperClass: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['change', 'update'],
    data() {
        return {
            supportedCountries: null,
            filteredCountries: null,
            country: null,
            code: null,
            phone: '',
        };
    },
    computed: {
        ...mapState({
            countries: (state) => state.countries?.items || [],
        }),
        rules() {
            return {
                required: true,
                errorMessage: this.setErrorMessage('notFilledPhone'),
                trigger: 'blur',
                validator: this.validatePhone.bind(this),
            };
        },
        locale() {
            return this.$route?.params?.locale || LOCALE_DEFAULT;
        },
        labels() {
            return this.settings?.labels || {};
        },
        countriesList() {
            return this.filteredCountries || this.supportedCountries;
        },
        countryLabel() {
            const countryName = this.getCountryMetaData(this.computedCountry)?.name;
            return countryName || this.labels?.countryLabel || null;
        },
        formattedExampleNumber() {
            const exampleNumber = this.exampleNumber ? this.exampleNumber.formatNational() : null;
            return `${this.labels?.example} ${exampleNumber || ''}`;
        },
        parsedPhone() {
            try {
                return parsePhoneNumber(this.phone, {
                    defaultCountry: this.computedCountry,
                    defaultCallingCode: this.computedCode,
                    extract: false,
                });
            } catch {
                return this.phone;
            }
        },
        exampleNumber() {
            return this.computedCountry ? getExampleNumber(this.computedCountry, examples) : false;
        },
        computedCode: {
            get() {
                return this.computedCountry ? getCountryCallingCode(this.computedCountry) : this.code;
            },
            set(newValue) {
                this.code = newValue;
            },
        },
        computedCountry: {
            get() {
                const country = this.country || this.defaultCountry;
                this.changeCallingCode(country);
                return country;
            },
            set(newValue) {
                this.country = newValue;
                this.changeCallingCode(newValue);
            },
        },
    },
    watch: {
        defaultCountry() {
            if (!isSupportedCountry(this.defaultCountry)) return;
            if (this.phone?.length) return;
            this.computedCountry = this.defaultCountry;
        },
        defaultPhone() {
            if (this.phone?.length) return;
            this.initPhone();
        },
    },
    mounted() {
        this.initCountries();
        this.initPhone();
    },
    updated() {
        this.$emit('update');
    },
    methods: {
        initCountries() {
            this.supportedCountries = this.countries.filter((el) => isSupportedCountry(el.iso_code));
        },
        changeCallingCode(country) {
            if (!country) return;
            this.computedCode = getCountryCallingCode(country);
        },
        getCountryMetaData(country) {
            if (!country) return null;

            const name = this.countries.find((el) => el.iso_code === country)?.name;
            const code = getCountryCallingCode(country);

            return {
                code,
                name,
                parsed_code: `+${code} `,
                parsed: `${name} +${code} `,
            };
        },
        validatePhone(rule, value, callback) {
            if (!this.computedCode) callback(new Error(this.setErrorMessage('notFilledCallingCode')));
            if (!this.phone) callback(new Error(this.setErrorMessage('notFilledPhone')));

            try {
                const phoneNumber = parsePhoneNumber(this.phone, {
                    defaultCountry: this.computedCountry,
                    defaultCallingCode: this.computedCode,
                    extract: false,
                });
                const countrySupported = this.countries.find((el) => el.iso_code === phoneNumber.country);
                if (!countrySupported) {
                    callback(new Error(this.setErrorMessage('invalidPhone')));
                }
                if (!phoneNumber.isValid() || !phoneNumber.isPossible()) {
                    callback(new Error(this.setErrorMessage('invalidPhone')));
                } else {
                    callback();
                }
            } catch {
                callback(new Error(this.setErrorMessage('invalidPhone')));
            }
        },
        onInput() {
            try {
                const phoneNumber = parsePhoneNumber(this.phone);
                const countrySupported = this.countries.find((el) => el.iso_code === phoneNumber.country);

                if (phoneNumber.country === undefined) {
                    this.computedCountry = 'US';
                    return;
                }

                if (!countrySupported) {
                    this.phone = '';
                    this.computedCountry = 'US';
                    return;
                }

                if (this.computedCountry === phoneNumber.country) return;
                this.computedCountry = phoneNumber?.country;
            } catch {
                /* Should be called to avoid console errors from parsePhoneNumber */
            }
        },
        setPhone() {
            this.phone = this.computedCountry ? new AsYouType(this.computedCountry).input(this.phone) : this.phone;
        },
        initPhone() {
            if (!this.defaultPhone) return;
            this.phone = this.defaultPhone;
            const phoneNumber = this.parsedPhone;
            this.computedCountry = phoneNumber?.country || '';
            this.computedCode = phoneNumber?.countryCallingCode || '';
            this.phone = phoneNumber?.nationalNumber || '';
            this.setPhone();
        },
        onChange() {
            return this.$emit('change', this.parsedPhone);
        },
        onCodeChange(value) {
            if (value?.length) this.phone = '';
            this.changeCallingCode(this.computedCountry);
        },
        onCodeFilter(searchQuery) {
            const query = searchQuery.toLowerCase();
            if (!query) this.filteredCountries = this.supportedCountries;

            this.filteredCountries = this.supportedCountries.filter((country) => {
                const meta = this.getCountryMetaData(country.iso_code)?.parsed;
                return meta.toLowerCase().includes(query);
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.phone-number-input {
    .country-input {
        position: absolute;
        width: 108px;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        @media (--viewport-mobile-wide) {
            width: 150px;
        }
        &:deep(.el-input) {
            margin: 1px;
        }
        &:deep(.el-input__container) {
            height: 100%;
            border: none;
        }
    }
    .phone-input {
        width: 100%;
        &:deep(.el-input__container) {
            padding-inline-start: 110px;
            @media (--viewport-mobile-wide) {
                padding-inline-start: 156px;
            }
        }
        &:deep(.el-input__wrapper) {
            padding-inline-start: 16px;
            border-inline-start: 1px solid var(--av-brand-light);
            .el-input__label {
                padding-inline-start: 16px;
            }
        }
    }
    &:deep(.el-form-item__hint) {
        @mixin caption-accent;
        margin: 0 0 16px;
        color: var(--av-fixed-light);
    }
    &:deep(.el-select-dropdown__wrap) {
        margin-inline: 0 !important;
    }
    &.is-error {
        .phone-input {
            &:deep(.el-input__wrapper) {
                border-inline-start: 1px solid var(--av-fixed-danger);
            }
        }
    }
    &.is-focus {
        .phone-input {
            &:deep(.el-input__container) {
                border: 1px solid var(--av-brand-primary);
            }
            &:deep(.el-input__wrapper) {
                border-inline-start: 1px solid var(--av-brand-primary);
            }
        }
    }
}

[dir="rtl"] {
    .phone-number-input {
        .country-input {
            &:deep(.el-input__editor) {
                direction: ltr;
                text-align: end;
            }
        }
    }
}

.phone-input-dropdown {
    z-index: 2004;
    min-width: 250px !important;
}
</style>
