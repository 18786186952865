<template>
    <div class="a-product-card" :class="className">
        <div>
            <div class="a-product-card__header">
                <div class="a-product-card__title">
                    {{ title }}
                </div>
                <a-picture
                    v-if="logo"
                    class="a-product-card__logo"
                    :link="logo"
                    :alt="title"
                />
            </div>
            <div class="a-product-card__content">
                <a-label
                    v-if="labelTarget"
                    size="l"
                    type="text-label"
                >
                    {{ labelTarget }}
                </a-label>
                <a-label
                    v-if="labelFlag"
                    size="l"
                    variant="success"
                    type="text-label"
                    :glyph="labelFlagIcon"
                >
                    {{ labelFlag }}
                </a-label>
                <p v-if="$slots.default" class="a-product-card__text">
                    <slot />
                </p>
            </div>
        </div>
        <div v-if="isFooter" class="a-product-card__footer">
            <a-button
                v-if="learnLink"
                class="a-product-card__button"
                v-bind="learnLink"
                :size="learnLink.size || 's'"
                :text="learnLink.text || 'Learn More'"
                :event="{ content: title }"
                native-type="a"
            />
            <a-button
                v-if="tryLink"
                class="a-product-card__button"
                v-bind="tryLink"
                :size="tryLink.size || 's'"
                :type="tryLink.type || 'action'"
                :text="tryLink.text || 'Try Now'"
                :event="{ content: title }"
                native-type="a"
                @click="handleClick(tryLink.optionsSURL)"
            />
        </div>
        <a-loader :is-visible="isVisibleSURL" />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';
import ALoader from '@core/components/loader/loader.vue';
import APicture from '@core/components/picture/picture.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';
import { TYPES } from './constants.js';

export default {
    name: 'AProductCard',

    components: {
        AButton,
        ALabel,
        ALoader,
        APicture,
    },

    mixins: [purchasing, analytics, cleverbridge],

    props: {
        /**
         * Card type: default state / promo state
         */
        type: {
            type: String,
            default: undefined,
            validator: (value) => value === undefined || TYPES.includes(value),
        },

        /**
         * Logo URL
         */
        logo: {
            type: String,
            default: undefined,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            default: undefined,
        },

        labelTarget: {
            type: String,
            default: undefined,
        },

        labelFlag: {
            type: String,
            default: undefined,
        },

        /**
         * Label flag Icon name
         */
        labelFlagIcon: {
            type: String,
            default: undefined,
        },

        /**
         * Is Footer exists
         */
        isFooter: {
            type: Boolean,
            default: true,
        },

        /**
         * Button Try
         */
        tryLink: {
            type: Object,
            default: undefined,
        },

        /**
         * Button Learn
         */
        learnLink: {
            type: Object,
            default: undefined,
        },
    },

    computed: {
        className() {
            return {
                [`a-product-card_type_${this.type}`]: this.type,
            };
        },
    },

    methods: {
        handleClick(optionsSURL) {
            if (optionsSURL) {
                const options = this.storeOptionsSURL(optionsSURL);
                this.redirectToCleverbridge(options);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-product-card {
    border-radius: 4px;
    box-shadow: var(--av-shadow-small);
    border: var(--av-border);
    background-color: var(--av-inversed-primary);
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__title {
        color: var(--av-nav-primary);
        @mixin title-accent;
    }

    &__logo {
        height: 56px;
        width: auto;
        flex-shrink: 0;
        margin-inline-start: 8px;
    }

    &__content {
        flex: 1 1 auto;
        margin-bottom: 16px;
    }

    &__text {
        @mixin body;

        color: var(--av-fixed-secondary);
    }

    .a-label {
        display: flex;
        margin-bottom: 8px;
    }

    &__label {
        @mixin paragraph-accent;

        margin-bottom: 8px;

        &_target {
            color: var(--av-nav-secondary);
        }

        &_flag {
            color: var(--av-button-action);
        }
    }

    &__glyph {
        fill: var(--av-fixed-success);
        margin-inline-end: 8px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: auto 0 -8px;
    }

    &__button {
        margin: 0 8px;
        white-space: nowrap;
        text-align: center;
        flex: 1 0 50%;
    }

    @media (--viewport-desktop-wide) {
        &__button {
            flex: 1 0 0;
        }
    }
}
</style>
