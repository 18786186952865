<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-announce">
        <div class="a-container">
            <div class="wrapper">
                <a-picture v-if="content.picture" v-bind="content.picture" />
                <div class="content">
                    <s-main-page-tag v-bind="tag({name: 'title'})" />
                    <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true})" />
                    <s-main-page-cta :content="content.cta" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import SMainPageCta from '../components/cta.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageAnnounce',
    components: {
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content?.description;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-announce {
    padding: 40px 0 0;
    .wrapper {
        overflow: hidden;
        border-radius: 8px;
        background: #0b0b50;
        border: 1px solid #0c7cff;
        @media (--viewport-mobile-wide) {
            @mixin colls 10;
            margin: 0 auto;
        }
        @media (--viewport-desktop-wide) {
            width: 100%;
            display: flex;
            padding: 40px;
        }
    }
    .content {
        padding: 16px;
        text-align: center;
        color: var(--av-inversed-primary);
        @media (--viewport-mobile-wide) {
            padding: 32px 64px;
        }
        @media (--viewport-tablet) {
            padding: 32px 80px;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 6;
            z-index: 1;
            padding: 0;
            text-align: left;
        }
    }
    .title {
        @mixin title-accent;
        @media (--viewport-tablet) {
            @mixin display-accent;
        }
        @media (--viewport-desktop-wide) {
            @mixin hero-accent;
        }
    }
    .description {
        @mixin paragraph;
        margin-top: 24px;
        @media (--viewport-tablet) {
            @mixin lead;
        }
        @media (--viewport-desktop-wide) {
            @mixin title;
        }
    }
    .main-page-cta {
        margin-top: 40px;
        @media (--viewport-desktop-wide) {
            margin-top: 24px;
        }
    }
    &:deep(.a-button) {
        width: 100%;
        @media (--viewport-desktop-wide) {
            width: auto;
            min-width: 200px;
        }
    }
    &:deep(.a-picture) {
        height: 190px;
        @media (--viewport-desktop-wide) {
            top: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            inset-inline-start: 0;
        }
        .a-picture__img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
