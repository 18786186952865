<template>
    <div class="s-header-product-new">
        <s-header-with-form
            v-if="form"
            :awards="awards"
            :background="background"
            :button="buttons[0]"
            :cta-settings="ctaSettings"
            :description="description"
            :event="event"
            :form="form"
            :label="label"
            :menu-label="menuLabel"
            :navigation-subtitle="productCaption || product?.productCaption"
            :navigation-title="productName"
            :overview-label="overviewLabel"
            :overview-link="overviewLink"
            :submenu="submenuLinks"
            :subtitle="subtitleProduct"
            :title="titleProduct"
        />
        <s-header-with-navigation
            v-else
            :title="titleProduct"
            :subtitle="subtitleProduct"
            :navigation-title="productName"
            :navigation-subtitle="product?.productCaption"
            :cta-settings="ctaSettings"
            :is-subpage="isSubpage"
            :is-short="isShort"
            :action-links="actionLinks"
            :overview-link="overviewLink"
            :buttons="buttons"
            :additional-label="additionalLabel"
            :background="background"
            :submenu="submenuLinks"
            :menu-label="menuLabel"
            :overview-label="overviewLabel"
            :event="event"
        >
            <slot name="video" />
        </s-header-with-navigation>
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';
import SHeaderWithForm from './components/header-with-form.vue';
import SHeaderWithNavigation from './components/header-wth-navigation.vue';

const ADVANCED_PACKS_GROUP = 'advanced-packs';

export default {
    name: 'SHeaderProductNew',

    components: {
        SHeaderWithForm,
        SHeaderWithNavigation,
    },

    mixins: [purchasing, analytics, cleverbridge],

    props: {
        background: {
            type: Object,
            required: true,
        },

        productId: {
            type: Number,
            default: null,
        },

        event: {
            type: Object,
            default: () => ({
                category: 'Conversions',
                location: 'Top CTA',
            }),
        },

        title: {
            type: String,
            default: '',
        },

        subtitle: {
            type: String,
            default: null,
        },

        isSubpage: {
            type: Boolean,
            default: false,
        },

        isShort: {
            type: Boolean,
            default: false,
        },

        settings: {
            type: Object,
            default: undefined,
        },

        productPageCTA: {
            type: Object,
            default: undefined,
        },

        additionalLabel: {
            type: String,
            default: null,
        },

        groupId: {
            type: String,
            default: '',
        },

        label: {
            type: Object,
            default: null,
        },

        description: {
            type: String,
            default: '',
        },

        awards: {
            type: Array,
            default: null,
        },

        productCaption: {
            type: String,
            default: '',
        },

        form: {
            type: Object,
            default: null,
        },

        companyNameFallback: {
            type: String,
            default: 'Service Providers',
        },
    },

    data: () => ({
        isMounted: false,
    }),

    computed: {
        ctaSettings() {
            return {
                ...this.productPageCTA,
                labelTheme: this.productPageCTA?.labelTheme || 'dark',
                hasDescription: true,
                hasLabel: !this.form,
            };
        },

        productData() {
            const productId = this.productId || this.internalProductId;
            return this.productItems?.[productId] || {};
        },

        product() {
            return this.productData?.parameters?.common || {};
        },

        actionLinks() {
            return this.settings?.actionLinks || this.product?.actionLinks || [];
        },

        buttons() {
            return this.settings?.buttons || this.product?.buttons || [];
        },

        children() {
            return this.productData?.parameters?.pages?.children || [];
        },

        submenu() {
            const submenu = this.settings?.submenu || this.children;

            if (!this.isOnAdvancedPackPage) {
                return submenu;
            }

            const anchor = this.$route.fullPath.split('/').filter(Boolean).pop();

            return submenu.map((s) => (s.addAdvancedPackAnchor ? { ...s, to: `${s.to}#${anchor}` } : s));
        },

        submenuLinks() {
            return this.submenu.filter((link) => link.name !== 'overview');
        },

        isOnAdvancedPackPage() {
            const links = this.children.filter((c) => c.groupId === ADVANCED_PACKS_GROUP);

            const sublinks = links.flatMap((c) => c.sublinks);
            const submenuLinks = links.flatMap((c) => c.submenu).filter(Boolean)
                ?.flatMap((c) => c.columns).filter(Boolean)
                ?.flatMap((c) => c.links);

            return [...sublinks, ...submenuLinks].some((s) => this.$route.fullPath.endsWith(s?.to));
        },

        productName() {
            const productName = this.productData?.parameters?.common?.productName;
            return productName ? `Acronis ${productName}` : '';
        },

        menuLabel() {
            const menuLabel = this.productData?.parameters?.common?.menuLabel;
            return menuLabel || 'Menu';
        },

        overviewLink() {
            if (!this.hasSubmenu) {
                return null;
            }
            const overviewItem = this.submenu.find((item) => item.name === 'overview');
            return overviewItem ? {
                to: overviewItem.to,
                text: this.productName,
            } : null;
        },

        overviewLabel() {
            const overviewItem = this.submenu.find((item) => item.name === 'overview');
            return overviewItem?.text;
        },

        hasSubmenu() {
            return Boolean(this.submenu && this.submenu.length);
        },

        titleProduct() {
            const titleSource = this.title || this.product.title || '';
            let path = this.$route.path;

            if (!path.includes('products/cloud/cyber-protect')) {
                return titleSource;
            }

            if (!this.isMounted) {
                return titleSource.includes('%COMPANY_NAME%') ? '' : titleSource;
            }

            if (path.includes('products/cloud/cyber-protect/pricing/hello') && titleSource.includes('%COMPANY_NAME%')) {
                const companyName = this.$store.state.trial?.queryStringData?.company || this.companyNameFallback;
                path = path.replace('hello/', '');
                this.$router.replace({ path });
                return titleSource.replace('%COMPANY_NAME%', companyName);
            }

            return titleSource;
        },

        subtitleProduct() {
            return this.subtitle || this.product.subtitle;
        },
    },

    mounted() {
        this.isMounted = true;
    },
};
</script>
