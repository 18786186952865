<template>
    <section v-if="isVisible" class="main-page-header">
        <div
            v-for="(list, rowIndex) in content.list"
            :key="`list-${rowIndex}`"
            class="content a-container"
        >
            <div
                v-for="(product, productIndex) in list"
                :key="`product-${productIndex}`"
                :class="className(product,!rowIndex && !productIndex)"
            >
                <s-main-page-tag v-if="product.title" v-bind="tag({name: 'title'}, product)" />
                <div v-if="hasLabel(rowIndex)" class="label">
                    <a-label v-if="product.label?.text" v-bind="{ size: 's', ...product.label}">
                        <span v-html="product.label.text" />
                    </a-label>
                    <p v-else-if="rowIndex" v-html="'\xa0'" />
                </div>
                <p v-if="isLink(product)">
                    <a-link v-bind="{ size: 'title', accent: true, ...product.link}" />
                </p>
                <p v-if="product.description" class="description" v-html="product.description" />
                <s-main-page-cta :content="product.cta" />
                <div v-if="product.picture" class="background-content">
                    <a-picture v-bind="product.picture" />
                </div>
            </div>
        </div>
        <div class="background">
            <a-picture v-if="content.picture" v-bind="content.picture" />
        </div>
    </section>
</template>

<script>
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoints from '@core/mixins/breakpoint';
import SMainPageCta from '../../components/cta.vue';
import mixin from '../../components/mixin.js';
import SMainPageTag from '../../components/tag.vue';

export default {
    name: 'SMainPageHeaderVariant2',
    components: {
        ALabel,
        ALink,
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.list?.length;
        },
    },
    methods: {
        className(product, isInfo) {
            return [
                isInfo ? 'info' : 'product',
                { 'product-border': product.hasBorder },
                { 'product-cta-button-single': product.cta?.length === 1 },
            ];
        },
        isLink(item) {
            return item.link?.text && item.link.to;
        },
        hasLabel(index) {
            return this.content.list[index].some((item) => item.label);
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-header {
    gap: 16px;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 104px 0 16px;
    @media (--viewport-tablet) {
        gap: 40px;
        padding-bottom: 40px;
    }
    @media (--viewport-desktop) {
        padding-bottom: 24px;
    }
    @media (--viewport-desktop-wide) {
        padding-bottom: 48px;
    }
    .content {
        gap: 16px;
        display: flex;
        flex-direction: column;
        @media (--viewport-tablet) {
            flex-direction: row;
        }
    }
    .info {
        gap: 24px;
        flex: 1 1 0;
        display: flex;
        padding: 16px 0;
        position: relative;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        background: transparent;
        color: var(--av-inversed-primary);
        @media (--viewport-tablet) {
            min-width: 344px;
            position: unset;
            padding: 32px 0;
            text-align: left;
        }
        @media (--viewport-desktop) {
            min-width: 66%;
            padding: 48px 0;
            padding-inline-end: 72px;
            padding-inline-start: 16px;
        }
        @media (--viewport-desktop-wide) {
            padding-inline-start: 24px;
        }
        .title {
            @mixin display-accent;
            @media (--viewport-tablet) {
                @mixin hero-accent;
            }
        }
        .description {
            @mixin title;
        }
    }
    .product {
        flex: 1 1 0;
        display: flex;
        position: relative;
        padding: 24px 16px;
        border-radius: 8px;
        flex-direction: column;
        background: var(--av-inversed-primary);
        @media (--viewport-desktop) {
            width: 33.33%;
        }
        @media (--viewport-desktop-wide) {
            padding: 24px;
        }
        .label {
            @mixin note-heading;
            display: flex;
        }
        .description {
            @mixin paragraph;
            margin-top: 8px;
        }
    }
    .product-border {
        border: 2px solid var(--av-brand-secondary-light);
    }
    .product-cta-button-single {
        .main-page-cta {
            &:deep(.a-button) {
                width: 100%;
            }
        }
    }
    .main-page-cta {
        padding-top: 24px;
        @media (--viewport-tablet) {
            margin-top: auto;
            padding-top: 16px;
            flex-direction: column;
        }
        @media (--viewport-desktop) {
            flex-direction: row;
        }
        &:deep(.a-button) {
            @media (--viewport-tablet) {
                width: 100%;
            }
            @media (--viewport-desktop) {
                width: 50%;
            }
        }
    }
    .background {
        top: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        position: absolute;
        inset-inline-start: 0;
        background-color: var(--av-nav-primary);
        &:deep(.a-picture) {
            height: 100%;
            .a-picture__img {
                height: 100%;
                width: 100%;
            }
        }
    }
    .background-content {
        top: 0;
        height: 100%;
        z-index: -1;
        position: absolute;
        width: calc(100% + 32px);
        inset-inline-start: -16px;
        @media (--viewport-mobile-wide) {
            width: calc(100% + 64px);
            inset-inline-start: -32px;
        }
        @media (--viewport-tablet) {
            top: 24px;
            width: 100%;
            inset-inline-start: 0;
            height: calc(100% - 48px);
        }
        @media (--viewport-desktop-wide) {
            inset-inline-start: 64px;
            width: calc(100% - 128px);
        }
        @media (--viewport-desktop-large) {
            width: 100%;
            inset-inline-start: 0;
        }
        &:deep(.a-picture) {
            height: 100%;
            .a-picture__img {
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>
