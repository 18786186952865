<template>
    <div v-show="isVisible" class="a-loader">
        <template v-if="hasError">
            <p v-if="slice.glyph">
                <a-glyph v-bind="slice.glyph" />
            </p>
            <p
                v-if="slice.title"
                class="title"
                v-html="slice.title"
            />
            <p
                v-for="(item, itemIndex) in slice.description"
                :key="`error-${itemIndex}`"
                class="description"
                v-html="item"
            />
            <a-button
                v-bind="slice.button"
                @click="$emit('click')"
            />
        </template>
        <template v-else>
            <div
                v-loading="true"
                el-loading-size="48"
                el-loading-color="brand-secondary"
            />
            <div class="placeholder">
                <p
                    v-for="(item, itemIndex) in slice.placeholder"
                    :key="`placeholder-${itemIndex}`"
                    v-html="item"
                />
            </div>
        </template>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { mapState } from 'vuex';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

const STORE_SLICE_NAME = 's-loader';

export default {
    name: 'ALoader',

    components: {
        AButton,
        AGlyph,
    },

    directives: {
        Loading,
    },

    props: {
        content: {
            type: Object,
            default() {},
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['click'],

    computed: {
        ...mapState({
            preset: (state) => state.slices.items?.[STORE_SLICE_NAME] || null,
        }),

        slice() {
            return { ...this.preset, ...this.content };
        },
    },

    watch: {
        isVisible(active) {
            if (active) {
                document.body.classList.add('overflow-hidden');
                return;
            }
            document.body.classList.remove('overflow-hidden');
        },
    },

    async serverPrefetch() {
        const locale = this.$route.params.locale || LOCALE_DEFAULT;
        await this.$store.dispatch('slices/getSyncedData', { slice: STORE_SLICE_NAME, locale });
    },
};
</script>

<style lang="postcss">
.a-loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .95;
    display: flex;
    z-index: 2147483650;
    position: fixed;
    padding: 0 16px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: var(--av-inversed-primary);

    .placeholder {
        @mixin body;
        margin-top: 16px;
    }

    .title {
        @mixin lead;
    }

    .title ~ .description {
        width: 100%;
        margin-top: 8px;
    }

    .el-loading {
        position: relative;
    }

    .a-glyph {
        margin-bottom: 16px;
    }

    .a-button {
        @mixin body;
        margin-top: 16px;
        padding: 4px 16px;
    }
}

.overflow-hidden {
    overflow: hidden;

    .s-purchasing-acp-v2 {
        position: relative;
        z-index: 2147483647;
    }
}
</style>
