export const MOCK_SMALL_CARD_COMPANY = {
    title: 'IBM Japan Ltd.',
    text: `Company info. The great benefit of using Acronis is securing the data, our IP (intellectual property).
                Data is king in our business.`,
    labels: [
        { text: 'Success', variant: 'success' },
        { text: 'Info', variant: 'info' },
        { text: 'Neutral', variant: 'neutral' },
    ],
    company: {
        address: '19-21 Nihonbashi Hakozakicho, Chuo-ku, Tokyo 103-8510',
        phone: { text: '+359875328030', to: 'tel:+359875328030' },
        website: { text: 'Visit website', glyph: 'arrow', to: '#' },
    },
};
