<template>
    <s-basic-slice class="s-locator">
        <a-slice-header :title="title" :lead="lead" :body-text="bodyText">
            <p v-if="link">
                <a-link
                    v-bind="link"
                    :size="link.size || 'paragraph'"
                    :glyph="link.glyph || 'arrow-long'"
                    :text="setTranslation(link.title)"
                />
            </p>
        </a-slice-header>
        <div
            v-if="loading"
            v-loading
            class="s-locator__loader"
            el-loading-size="48"
            el-loading-color="brand-primary"
            :el-loading-text="setTranslation('Loading')"
        />
        <div v-else class="s-locator__wrapper" :class="{ 'is-open': isFilterVisible }">
            <div class="s-locator__control">
                <div class="s-locator__filters-info">
                    <span
                        class="s-locator__filters-btn"
                        :class="{ 'is-active': isFilterVisible }"
                        @click="setFiltersStatus"
                        v-html="setFiltersQuantity()"
                    />
                    <span class="s-locator__quantity">{{ quantityPartners }}</span>
                </div>
                <div v-if="locatorType === 'partners'" class="s-locator__switcher">
                    <a-button
                        v-for="item in modeViewSwitcher"
                        :key="`switcher-${item}`"
                        class="s-locator__switcher-item"
                        :text="setTranslation(item)"
                        :class="{ 'is-active': modeView === item }"
                        @click="changeMode(item)"
                    />
                </div>
            </div>
            <div class="s-locator__container" :class="{ 'is-open': !isFilterVisible }">
                <div v-if="isFilterVisible" class="s-locator__filters">
                    <div class="s-locator__filters-list">
                        <div class="s-locator__filters-info">
                            <span
                                v-if="isDesktop"
                                class="s-locator__filters-btn"
                                :class="{ 'is-active': isFilterVisible }"
                                @click="setFiltersStatus"
                                v-html="setFiltersQuantity()"
                            />
                            <span v-else class="s-locator__filters-btn-glyph" @click="setFiltersStatus">
                                <a-glyph class="s-locator__filters-glyph" name="back" />
                            </span>
                            <span class="s-locator__quantity">{{ quantityPartners }}</span>
                        </div>
                        <div v-if="countryList.length > 1" class="s-locator__filter-country">
                            <el-select
                                v-bind="labelSelect"
                                filterable
                                :hide-on-resize="isDesktop"
                                :value="country"
                                :label="setTranslation('Country')"
                                popper-class="s-locator__el-select-country"
                            >
                                <el-option
                                    v-for="item in countryList"
                                    :key="`country-${item.code}`"
                                    :label="item.name"
                                    :value="item.code"
                                    @click.native="changeCountry(item.code)"
                                />
                            </el-select>
                        </div>
                        <div class="s-locator__filter-search">
                            <el-search
                                v-model="search"
                                :placeholder="setTranslation('Search by name or address')"
                                @input="mapInitCheck"
                            />
                        </div>
                        <div class="s-locator__filter-type">
                            <p>{{ setTranslation('Partner type') }}</p>
                            <p
                                v-for="(item, i) in listCompanyLevels"
                                :key="`company-level-${i}`"
                                class="s-locator__filter-type-item"
                            >
                                <el-checkbox
                                    v-model="levels[item.type]"
                                    :name="item.type"
                                    :label="setTranslation(item.name)"
                                    @change="changeFilter"
                                />
                            </p>
                        </div>
                        <p class="s-locator__filter-reset">
                            <a-button :text="setTranslation('Reset to default')" @click="resetFilter" />
                        </p>
                    </div>
                    <div class="s-locator__filters-close" @click="setFiltersStatus" />
                </div>
                <div v-if="isVisibleMessage" class="s-locator__partners s-locator__message">
                    <div
                        v-if="loadingData"
                        v-loading
                        class="s-locator__loader"
                        el-loading-size="48"
                        el-loading-color="brand-primary"
                        :el-loading-text="setTranslation('Data is loading...')"
                    />
                    <div v-else-if="hasErrorConnection" class="s-locator__message-connection">
                        <p>
                            <a-glyph class="s-locator__message-connection-glyph" name="attention2" />
                        </p>
                        <p class="s-locator__message-connection-title">
                            {{ setTranslation(dictionary.errorConnectionTitle) }}
                        </p>
                        <p class="s-locator__message-connection-description">
                            {{ setTranslation(dictionary.errorConnectionDescription) }}
                        </p>
                    </div>
                    <div v-else-if="partnerNotFound" class="s-locator__message-not-found">
                        <p>
                            <a-glyph class="s-locator__message-not-found-glyph" name="pin" />
                        </p>
                        <p
                            v-if="dictionary.errorNotFoundTitle"
                            class="s-locator__message-not-found-title"
                            v-html="setTranslation(dictionary.errorNotFoundTitle)"
                        />
                        <p v-if="dictionary.errorNotFoundDescription" class="s-locator__message-not-found-description">
                            <span
                                v-for="(item, i) in dictionary.errorNotFoundDescription"
                                :key="`message-not-found-${i}`"
                            >
                                <template v-if="item.isLink">
                                    <a-link v-bind="item" @click="resetFilter" />
                                </template>
                                <template v-else>
                                    {{ item.text }}
                                </template>
                            </span>
                        </p>
                    </div>
                </div>
                <div v-else class="s-locator__partners">
                    <div v-if="isModeMap" class="s-locator__partners-map">
                        <div id="map" class="s-locator__partners-map">
                            <div
                                class="s-locator__partners-map-caption"
                                v-html="setTranslation('Data is loading...')"
                            />
                        </div>
                    </div>
                    <div v-if="isModeList" class="s-locator__partners-list">
                        <template v-if="companyListAvailable.length">
                            <div
                                v-for="(company, i) in companyListAvailable"
                                :key="`company-${i}`"
                                class="s-locator__partners-list-item"
                            >
                                <p v-if="company.name" class="s-locator__partners-list-item-name">
                                    {{ company.name }}
                                </p>
                                <p
                                    v-if="company.level"
                                    class="s-locator__partners-list-item-level"
                                    v-html="company.level"
                                />
                                <p class="s-locator__partners-list-item-address" v-html="company.address" />
                                <div class="s-locator__links">
                                    <p v-if="company.phone" class="s-locator__links-item">
                                        <a-link
                                            glyph="phone2"
                                            glyph-position="left"
                                            :text="company.phone"
                                            :to="`tel:${company.phone}`"
                                        />
                                    </p>
                                    <p v-if="company.website" class="s-locator__links-item">
                                        <a-link
                                            glyph="external-link"
                                            glyph-position="left"
                                            :to="company.website"
                                            :text="setTranslation('Website')"
                                        />
                                    </p>
                                </div>
                            </div>
                        </template>
                        <div v-else class="empty-list">
                            <a-glyph name="locator" />
                            <div class="not-found-text">
                                {{ setTranslation('errorNotFoundTitle') }}
                            </div>
                            <div class="reset-filter-text">
                                {{ dictionary.errorNotFoundDescription[0].text }}
                                <a-link :text="dictionary.errorNotFoundDescription[1].text" @click="resetFilter" />
                            </div>
                        </div>
                    </div>
                    <Pagination
                        v-if="isModeList && totalPages > 1"
                        url-template=""
                        :current-page="currentPage"
                        :total-pages="totalPages"
                        :click-handler="handlePageClick"
                    />
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script lang="ts">
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { isEmpty, sortBy } from 'lodash';
import { mapState, mapActions } from 'vuex';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import Pagination from '@core/components/pagination/pagination.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import breakpoints from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import { GOOGLE_MAP_API_KEY } from '@model/const/api-keys.ts';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&loading=async`;
const MARKER_CLUSTER = 'https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js';

const PARTNERS_PER_PAGE = 12;

export default {
    name: 'SLocator',

    components: {
        AButton,
        AGlyph,
        ALink,
        ASliceHeader,
        Pagination,
        SBasicSlice,
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElSearch: () => import('@uikit/ui-kit/packages/search'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
    },

    directives: {
        Loading,
    },

    mixins: [breakpoints, form],

    props: {
        /**
         * Title
         */
        title: {
            type: String,
            default: null,
        },

        /**
         * Lead
         */
        lead: {
            type: String,
            default: null,
        },

        /**
         * Body text
         */
        bodyText: {
            type: String,
            default: null,
        },

        /**
         * Locator type
         */
        locatorType: {
            type: String,
            default: 'partners',
        },

        /**
         * Link
         */
        link: {
            type: Object as any,
            required: false,
            default: null,
        },

        /**
         * Dictionary
         */
        dictionary: {
            type: Object as any,
            required: false,
            default: null,
        },

        /**
         * Company
         */
        localCompanyList: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * Country
         */
        localCountryList: {
            type: Array,
            required: false,
            default: null,
        },

        partnersPerPage: {
            type: Object,
            default: () => ({
                mobile: PARTNERS_PER_PAGE,
                desktop: PARTNERS_PER_PAGE * 2,
            }),
        },
    },

    data: () => ({
        currentPage: 1,
        search: '',
        country: '',
        countryList: [],
        companyList: [],
        companyQuantity: 0,
        modeView: 'List',
        modeViewSwitcher: ['List', 'Map'],
        levels: {
            resellerLevel: false,
            serviceProviderLevel: false,
            cloudAggregatorLevel: false,
            cloudDistributorLevel: false,
            classicDistributorLevel: false,
        },
        loading: true,
        loadingData: false,
        partnerNotFound: false,
        hasErrorConnection: false,
        isFilterVisible: true,
    }),

    serverPrefetch() {
        this.$store.dispatch('countries/getCountries');
    },

    computed: {
        ...mapState({
            countries: (state) => state.countries?.items || [],
            location: (state) => state.geolocation?.location || null,
        }),

        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        language() {
            return this.locale.split('-').shift();
        },

        isActiveSearch() {
            return Boolean(this.search);
        },

        hasActiveFilters() {
            return !isEmpty(this.selectedFilters);
        },

        isModeList() {
            return this.modeView === 'List';
        },

        isModeMap() {
            return this.modeView === 'Map';
        },

        isVisibleMessage() {
            return this.loadingData || this.hasErrorConnection || this.partnerNotFound;
        },

        quantityPartners() {
            const quantity = this.companyQuantity;
            const result = quantity > 1 ? 'XX partners' : 'XX partner';
            return this.setTranslation(result).replace('XX', quantity);
        },

        quantityFilters() {
            return this.hasActiveFilters + this.isActiveSearch + 1;
        },

        listCompanyLevels() {
            const result = {
                distributors: [
                    { name: 'Cloud distributor', type: 'cloudDistributorLevel' },
                    { name: 'Classic distributor', type: 'classicDistributorLevel' },
                ],
                partners: [
                    { name: 'Service provider', type: 'serviceProviderLevel' },
                    { name: 'Reseller', type: 'resellerLevel' },
                    { name: 'Cloud aggregator', type: 'cloudAggregatorLevel' },
                ],
            };
            return result[this.locatorType] || result.partners;
        },

        selectedFilters() {
            return Object.keys(this.levels).filter((item) => this.levels[item]);
        },

        companyListAvailable() {
            let list = this.companyList;

            if (this.hasActiveFilters) {
                list = list.filter((company) => this.selectedFilters.every((item) => company[item]));
            }

            if (this.isActiveSearch) {
                const strSearch = this.search.toLowerCase();
                list = list.filter(
                    (item) => item.nameLC.includes(strSearch) || item.addressLC.includes(strSearch),
                );
            }

            if (this.isModeMap) {
                list = list.filter(
                    (item) => item.geoPoint && item.geoPoint.lat && item.geoPoint.lat !== 0,
                );
            }

            this.setCompanyQuantity(list);

            if (this.isModeList) {
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return list.slice(start, end);
            }
            return list;
        },

        itemsPerPage() {
            const key = this.isMobile ? 'mobile' : 'desktop';
            return this.partnersPerPage[key];
        },

        totalPages() {
            return Math.ceil(this.companyQuantity / this.itemsPerPage);
        },
    },

    async mounted() {
        document.body.classList.add('page-locator');

        await this.setCountryList();
        await this.setFilters();
        await this.setGeoLocation();
        await this.setCompanyList();

        const mapsLoaded = new Promise((resolve) => {
            let script = document.createElement('script');
            script.setAttribute('src', MARKER_CLUSTER);
            document.body.appendChild(script);

            script = document.createElement('script');
            script.setAttribute('src', `${GOOGLE_MAP_URL}&language=${this.language}`);
            script.onload = () => resolve(script);
            document.body.appendChild(script);
        });

        mapsLoaded.then(() => {
            this.isFilterVisible = this.isDesktop;
            this.loading = false;
            this.mapInitCheck();
        });
    },

    methods: {
        ...mapActions({
            getCompanyList: 'partnersLocator/getCompanyList',
            getCountryList: 'partnersLocator/getCountryList',
            getGeoLocation: 'geolocation/getGeoLocation',
        }),

        initGoogleMap() {
            const infoWindow = new window.google.maps.InfoWindow({ content: '', disableAutoPan: true });
            const partner = this.companyListAvailable[0];

            if (!partner) {
                return;
            }

            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: this.country === '' ? 2 : 3,
                minZoom: 0,
                center: new window.google.maps.LatLng(partner.geoPoint.lat, partner.geoPoint.lon),
            });

            /* eslint-disable max-len */
            const markers = this.companyListAvailable.map((item) => {
                const marker = new window.google.maps.Marker({
                    name: item.name,
                    address: item.address,
                    level: item.level,
                    phone: item.phone,
                    website: item.website,
                    position: { lat: item.geoPoint.lat, lng: item.geoPoint.lon },
                    icon: {
                        fillOpacity: 1,
                        fillColor: '#00204D',
                        path: 'M10.6464 25.3536L11 25.7071L11.3536 25.3536C12.6607 24.0464 13.8729 22.9297 14.9754 21.9141L15.009 21.8832C16.944 20.1007 18.5699 18.603 19.7021 16.9575C20.8588 15.2763 21.5 13.445 21.5 11C21.5 4.70819 16.7831 0.5 11 0.5C5.21694 0.5 0.5 4.70819 0.5 11C0.5 13.445 1.14118 15.2763 2.29793 16.9575C3.43007 18.603 5.05601 20.1007 6.99104 21.8832L7.02464 21.9141C8.12708 22.9297 9.33927 24.0464 10.6464 25.3536ZM14.5 10C14.5 11.933 12.933 13.5 11 13.5C9.067 13.5 7.5 11.933 7.5 10C7.5 8.067 9.067 6.5 11 6.5C12.933 6.5 14.5 8.067 14.5 10Z',
                    },
                });
                marker.addListener('click', () => {
                    const website = this.setTranslation('Website');
                    const content = [
                        '<div class="gm-style-content">',
                        `<p class="name">${marker.name}</p>`,
                        `<p class="level">${marker.level}</p>`,
                        `<p class="address">${marker.address}</p>`,
                        `<p class="phone"><svg class="icon" xmlns="http://www.w3.org/2000/svg"><path d="M3.79242 2.03751C3.32901 2.22468 2.62331 2.73344 2.20679 3.85985C1.78845 4.99117 2.15766 6.36957 3.02543 7.60778C4.85455 10.2178 7.01552 12.0725 8.66268 13.1847C9.69955 13.8849 10.8651 14.1615 11.8556 13.8469C13.0861 13.4561 13.6772 12.6956 13.9586 12.1267C13.57 11.3563 12.9647 10.7177 12.2193 10.2868L11.6404 10.863C10.4501 12.0479 8.52026 12.0479 7.32997 10.863L5.12371 8.66681C3.93343 7.48194 3.93343 5.56089 5.12371 4.37602L5.70048 3.80187C5.25249 3.04844 4.59496 2.43695 3.80422 2.04338L3.79242 2.03751ZM3.20599 0.0966482C3.76881 -0.0981076 4.30601 0.0316855 4.68341 0.219528L4.71293 0.234221C5.99402 0.871848 7.03279 1.90589 7.67333 3.18115C7.97676 3.78526 7.85782 4.51488 7.37805 4.99247L6.56051 5.80628C6.16375 6.20124 6.16375 6.84159 6.56051 7.23655L8.76677 9.43276C9.16353 9.82772 9.80681 9.82772 10.2036 9.43276L11.0211 8.61895C11.5009 8.14136 12.2338 8.02296 12.8407 8.32501L12.8839 8.34652C14.137 8.97019 15.153 9.98159 15.7795 11.2289C16.0185 11.7048 16.1051 12.3351 15.8248 12.9305C15.3671 13.9028 14.3903 15.165 12.4732 15.7739C10.7003 16.337 8.88558 15.7794 7.52201 14.8587C5.70553 13.6321 3.35319 11.6107 1.35894 8.76508C0.302742 7.25799 -0.443316 5.17111 0.299927 3.16114C0.957329 1.38331 2.1888 0.44863 3.20599 0.0966482Z" /></svg><a class="link" href="tel:${marker.phone}">${marker.phone}</a></p>`,
                        `<p class="website"><svg class="icon" xmlns="http://www.w3.org/2000/svg"><path d="M3 3V13H13V11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1H5C5.55228 1 6 1.44772 6 2C6 2.55228 5.55228 3 5 3H3ZM7.29117 7.30294L11.5941 3H9C8.44772 3 8 2.55228 8 2C8 1.44772 8.44772 1 9 1H14C14.5523 1 15 1.44772 15 2V7C15 7.55228 14.5523 8 14 8C13.4477 8 13 7.55228 13 7V4.40589L8.69706 8.70883C8.30884 9.09706 7.6794 9.09706 7.29117 8.70883C6.90294 8.3206 6.90294 7.69116 7.29117 7.30294Z" /></svg><a class="link" href="${marker.website}" target="_blank">${website}</a></p>`,
                        '<svg class="close" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="100%" y2="100%"></line><line x1="100%" y1="0" x2="0" y2="100%"></line></svg>',
                        '</div>',
                    ];
                    infoWindow.close();
                    infoWindow.setContent(content.join(' '));
                    infoWindow.setOptions({ disableAutoPan: false });
                    infoWindow.open(map, marker);
                });
                return marker;
            });

            const renderer = {
                render({ count, position }) {
                    return new window.google.maps.Marker({
                        position,
                        icon: {
                            url: `data:image/svg+xml;charset=UTF-8;base64,${window.btoa(
                                '<svg fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.5" y="1.5" width="29" height="29" rx="14.5" fill="white"/><rect x="1.5" y="1.5" width="29" height="29" rx="14.5" stroke="#00204d" stroke-width="3"/></svg>',
                            )}`,
                            scaledSize: new window.google.maps.Size(32, 32),
                        },
                        label: {
                            text: String(count),
                            color: '#00204D',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: 'Acronis Cyber',
                        },
                        // adjust zIndex to be above other markers
                        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
                    });
                },
            };

            // eslint-disable-next-line no-new
            new window.markerClusterer.MarkerClusterer({ map, markers, renderer });

            window.google.maps.event.addListener(map, 'click', () => {
                infoWindow.close();
            });
        },

        isCountrySupported(countryCode) {
            return this.countryList.find((c) => c.code === countryCode);
        },

        async setGeoLocation() {
            // check for unsupported countries from url hash
            if (this.isCountrySupported(this.country)) {
                return;
            }

            await this.getGeoLocation();
            this.country = this.location?.data?.country?.code || 'ALL';

            // check for unsupported countries by actual geolocation
            if (!this.isCountrySupported(this.country)) {
                this.country = 'ALL';
            }
        },

        async setCountryList() {
            if (this.localCountryList) {
                this.countryList = this.localCountryList;
                return;
            }

            try {
                const response = await this.getCountryList({ type: this.locatorType });
                const partnersCountryList = response ? response.data : [];

                const gisCountryList = Object.fromEntries(
                    this.countries.map((item) => [item.iso_code, item.name]),
                );

                const result = partnersCountryList.map((item) => ({
                    code: item.code,
                    name: gisCountryList[item.code] || item.name,
                }));

                this.countryList = sortBy(result, 'name');
            } catch (e) {
                this.country = 'ALL';
            } finally {
                this.countryList.unshift({ code: 'ALL', name: this.setTranslation('Worldwide') });
            }
        },

        async setCompanyList() {
            this.hasErrorConnection = false;

            const country = this.country === 'ALL' ? '' : this.country;

            if (this.localCompanyList) {
                this.companyList = this.localCompanyList;
                this.setCompanyData();
                return;
            }

            try {
                this.setLoadingStatus(true);
                const response = await this.getCompanyList({ country, type: this.locatorType });
                this.companyList = response ? response.data : [];
                this.setCompanyData();
            } catch (e) {
                this.hasErrorConnection = true;
                this.setCompanyQuantity(0);
            } finally {
                this.setLoadingStatus(false);
            }
        },

        setLoadingStatus(status) {
            this.loadingData = status;
            this.mapInitCheck();
        },

        setLocationHash() {
            const filters = [];
            filters.push(`country=${this.country},`);
            filters.push(this.search ? `search=${encodeURIComponent(this.search)},` : '');
            if (this.hasActiveFilters) {
                filters.push(`levels=${this.selectedFilters.join('|')}`);
            }
            window.location.hash = filters.join('').split(',').join(',');
        },

        setTranslation(str) {
            return this.dictionary[str] || str;
        },

        setFilters() {
            const filters = window.location.hash.substring(1);
            if (filters) {
                filters.split(',').forEach((filter) => {
                    const name = filter.split('=')[0];
                    const value = filter.split('=')[1];
                    if (name === 'country') {
                        const hasCountry = this.countryList.find((item) => item.code === value);
                        this.country = hasCountry ? value : this.country;
                    }
                    if (name === 'search') {
                        this.search = decodeURIComponent(value);
                    }
                    if (name === 'levels') {
                        value.split('|').forEach((level) => {
                            this.levels[level] = true;
                        });
                    }
                });
            }
        },

        setFiltersStatus() {
            window.scrollTo(0, 0);
            this.isFilterVisible = !this.isFilterVisible;
        },

        setFiltersQuantity() {
            const quantity = this.quantityFilters;
            const result = quantity > 1 ? 'XX Filters' : 'XX Filter';
            return this.setTranslation(result).replace('XX', `<span class="count">${quantity}</span>`);
        },

        setCompanyData() {
            this.companyList = (this.companyList || []).map((company) => {
                const city = company.city ? `${company.city}, ` : '';
                const state = company.state ? `${company.state}, ` : '';
                const street = company.street ? `${company.street}<br />` : '';
                const postalCode = company.postalCode ? `${company.postalCode}<br />` : '';

                const countryCode = company.country ? company.country : '';
                const country = this.countryList.find((item) => item.code === countryCode);
                const countryName = country ? country.name : countryCode;

                const result = [];
                const address = `${street}${city}${state}${postalCode}${countryName}`;

                this.listCompanyLevels.forEach((item) => {
                    if (company[item.type]) {
                        const isDistributors = this.locatorType === 'distributors';
                        const level = isDistributors ? '' : company[item.type];
                        const name = isDistributors ? item.name : item.name.toLowerCase();
                        result.push(`${this.setTranslation(level)} ${this.setTranslation(name)}`);
                    }
                });

                return {
                    ...company,
                    address,
                    level: result.join('<br />'),
                    nameLC: company.name.toLowerCase(),
                    addressLC: address.replace(/<br \/>|\s+/g, ' ').toLowerCase() + company.phone,
                };
            });
        },

        setCompanyQuantity(list) {
            this.companyQuantity = list.length || 0;
            this.partnerNotFound = this.companyQuantity === 0 && this.companyList.length;
            this.setLocationHash();
        },

        resetFilter() {
            if (this.quantityFilters === 1) {
                return;
            }
            this.listCompanyLevels.forEach((item) => {
                this.levels[item.type] = false;
            });
            this.search = '';
            this.changeFilter();
        },

        async mapInitCheck() {
            this.partnerNotFound = false;
            if (this.isModeMap && !this.isVisibleMessage) {
                await this.$nextTick();
                this.initGoogleMap();
            }
        },

        changeFilter() {
            this.currentPage = 1;
            window.scrollTo(0, 0);
            this.mapInitCheck();
        },

        changeMode(mode) {
            if (this.modeView === mode) {
                return;
            }
            this.modeView = mode;
            this.currentPage = 1;
            this.mapInitCheck();
        },

        changeCountry(countryCode) {
            if (this.country === countryCode) {
                return;
            }
            this.country = countryCode;
            this.hasErrorConnection = false;
            this.setCompanyList();
        },

        async handlePageClick(pageNumber: number, callback?: () => void) {
            this.currentPage = pageNumber;
            await this.$nextTick();
            window.scroll({ top: 0, behavior: 'smooth' });
            callback?.();
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-locator {
    padding-top: 120px;

    &__partners {
        margin: 24px 0 0;

        &-map {
            width: 100%;
            display: flex;
            height: 400px;
            align-items: center;
            justify-content: center;
        }

        &-list {
            &-item {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 16px;
                border-radius: 4px;
                flex-direction: column;
                padding: 16px 16px 24px;
                color: var(--av-nav-primary);
                background: var(--av-inversed-primary);
                box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
                border: 1px solid var(--av-brand-secondary-light);

                &-name {
                    @mixin lead-accent;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                &-level {
                    @mixin body-accent;
                    margin: 8px 0 0;
                }

                &-address {
                    @mixin body;
                    margin: 8px 0 0;
                    max-width: 100%;
                    color: var(--av-fixed-secondary);

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    &__switcher {
        display: flex;
        margin: 24px 0 0;
        flex-wrap: nowrap;
        border-radius: 4px;
        border: 1px solid var(--av-brand-primary);

        &-item {
            @mixin body-accent;
            flex: 1;
            border: 0;
            display: flex;
            height: 30px;
            outline: none;
            padding: 0 16px;
            cursor: pointer;
            border-radius: 0;
            position: relative;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            color: var(--av-brand-primary);

            &:not(:first-child) {
                border-inline-start: 1px solid var(--av-brand-primary);
            }

            &.is-active {
                color: var(--av-fixed-primary);
            }

            &:hover {
                background-color: var(--av-brand-accent);
            }

            &:active,
            &.is-active {
                background-color: var(--el-secondary-active);
            }
        }
    }

    &__links {
        margin: auto 0 0;
        padding: 16px 0 0;

        &-more {
            width: 100%;
            margin: 56px 0 0;
            text-align: center;

            &:deep(.a-button) {
                min-width: 280px;
            }
        }

        &-item {
            &:not(:first-child) {
                margin: 8px 0 0;
            }
        }

        .a-link {
            @mixin body-accent;
        }
    }

    &__message {
        &-connection {
            width: 100%;
            text-align: center;
            padding: 72px 0 64px;

            &-glyph {
                width: 72px;
                height: 72px;
                fill: var(--av-fixed-warning);
            }

            &-title {
                @mixin title;
                margin: 24px 0 0;
                color: var(--av-nav-primary);
            }

            &-description {
                @mixin paragraph;
                margin: 8px 0 0;
                color: var(--av-fixed-secondary);
            }
        }

        &-not-found {
            width: 100%;
            text-align: center;
            padding: 64px 0 56px;

            &-title {
                @mixin title;
                margin: 16px 0 0;
                color: var(--av-nav-primary);
            }

            &-description {
                @mixin paragraph;
                margin: 8px 0 0;
                color: var(--av-fixed-secondary);
            }

            .a-glyph {
                width: 72px;
                height: 72px;
                fill-opacity: 0.3;
                position: relative;
            }

            &:deep(.a-link) {
                &__content {
                    @mixin paragraph-accent;
                }
            }
        }

        &:deep(.s-locator) {
            &__loader {
                width: 100%;
            }
        }
    }

    &__control {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-height: 32px;
        user-select: none;
        justify-content: space-between;
    }

    &__filters {
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;

        /* WEB-43924 why do we use 1001 here ? */
        z-index: 1001;
        position: fixed;

        &-list {
            padding: 16px;
        }

        &-info {
            min-width: 100%;
        }

        &-glyph {
            width: 24px;
            height: 24px;
            margin: 0 0 2px;
            vertical-align: middle;
        }

        &-btn {
            @mixin body-accent;
            cursor: pointer;
            padding: 4px 8px;
            border-radius: 4px;
            display: inline-block;
            color: var(--av-brand-primary);

            &-glyph {
                cursor: pointer;
                margin-inline-end: -8px;
            }

            &:deep(.count) {
                color: var(--av-inversed-primary);
                padding: 0 4px;
                margin-inline-end: 8px;
                border-radius: 2px;
                background-color: var(--av-brand-primary);
                font-style: normal;
            }

            &:hover {
                background-color: var(--av-brand-accent);
            }

            &:active,
            &.is-active {
                background-color: var(--el-secondary-active);
            }
        }

        &:deep(.el) {
            &-select {
                .el-input {
                    .el-select {
                        &__caret {
                            color: var(--av-brand-primary);
                        }
                    }
                }
            }

            &-search {
                .el-input {
                    &--small {
                        .el-input {
                            &__wrapper {
                                padding: 10px 0 0 8px;
                            }

                            &__container {
                                height: 48px;
                                padding: 0 8px;
                            }

                            &__editor {
                                @mixin body;
                            }
                        }
                    }

                    &__suffix {
                        .i {
                            width: 16px;
                            height: 16px;
                            color: var(--av-brand-primary);
                        }
                    }
                }
            }
        }
    }

    &__filter {
        &-country {
            margin: 24px 0 0;
        }

        &-search {
            margin: 24px 0 0;
        }

        &-type {
            @mixin body;
            margin: 24px 0 0;

            &-item {
                margin: 8px 0 0;
            }
        }

        &-reset {
            .a-button {
                @mixin body-accent;
                padding: 0;
                border: none;
                box-shadow: none;
                background: none;
                margin: 24px 0 0;
                color: var(--av-brand-primary);
            }
        }
    }

    &__quantity {
        @mixin paragraph-accent;
        margin-inline-start: 16px;
        color: var(--av-nav-primary);
    }

    &__el-select-contry {
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

        .el-select-dropdown {
            &__item {
                &.selected {
                    background: var(--el-secondary-active);
                }
            }
        }
    }

    &:deep(.el-loading) {
        min-height: 496px;
        position: relative;

        .el-text {
            display: block;
            margin: 16px 0 0;
            line-height: 24px;
        }
    }

    .a-slice-header {
        width: 100%;

        .a-link {
            margin: 16px 0 0;
            font-weight: 600;
        }
    }

    &__wrapper {
        &.is-open {
            .s-locator {
                &__filters {
                    background: var(--av-inversed-primary);
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        padding-top: 128px;

        &__filters {
            &-list {
                top: 0;
                inset-inline-start: 0;
                z-index: 2;
                width: 360px;
                height: 100%;
                padding: 32px;
                overflow: scroll;
                position: absolute;
                background: var(--av-inversed-primary);
            }

            &-info {
                min-width: auto;
            }

            &-close {
                top: 0;
                inset-inline-start: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                position: absolute;
                background: var(--av-fixed-secondary);
            }
        }

        &__switcher {
            margin: 0;
        }

        &__partners {
            &-list {
                display: flex;
                flex-wrap: wrap;
                margin: -16px -8px 0;
                width: calc(100% + 16px);

                &-item {
                    margin: 16px 8px 0;
                    width: calc(50% - 16px);
                }
            }
        }

        &__wrapper {
            &.is-open {
                .s-locator {
                    &__filters {
                        background: transparent;
                    }
                }
            }
        }
    }

    @media (--viewport-tablet) {
        &__partners {
            &-list {
                &-item {
                    padding: 24px 24px 32px;

                    &-name {
                        @mixin title-accent;
                    }

                    &-level {
                        @mixin paragraph-accent;
                    }

                    &-address {
                        @mixin paragraph;
                    }
                }
            }

            &-map {
                height: 496px;
            }
        }

        .a-slice-header {
            position: relative;

            .a-link {
                inset-inline-end: 0;
                bottom: 4px;
                position: absolute;
            }
        }
    }

    @media (--viewport-desktop) {
        padding-top: 128px;

        &__container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;

            &.is-open {
                .s-locator {
                    &__partners {
                        width: 100%;

                        &-list {
                            &-item {
                                width: calc(33.33% - 16px);
                            }
                        }
                    }
                }
            }
        }

        &__filters {
            top: 40px;
            width: 33.33%;
            position: sticky;
            margin: -32px 0 0;
            padding: 0;
            padding-inline-end: 24px;
            z-index: 1;

            &-list {
                padding: 0;
                width: auto;
                overflow: hidden;
                position: relative;
            }

            &-close {
                display: none;
            }
        }

        &__partners {
            width: 66.16%;

            &-list {
                &-item {
                    width: calc(50% - 16px);
                }
            }
        }

        .a-slice-header {
            .a-link {
                bottom: 8px;
            }
        }

        &__wrapper {
            &.is-open {
                .s-locator {
                    &__control {
                        justify-content: flex-end;

                        .s-locator {
                            &__filters {
                                &-info {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-large) {
        &__filters {
            width: 25%;
        }

        &__partners {
            width: 75%;

            &-list {
                &-item {
                    width: calc(33.33% - 16px);
                }
            }
        }

        &__container {
            &.is-open {
                .s-locator {
                    &__partners {
                        &-list {
                            &-item {
                                width: calc(25% - 16px);
                            }
                        }
                    }
                }
            }
        }
    }

    .empty-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: 284px;
        align-items: center;
        justify-content: center;

        .a-glyph {
            width: 72px;
            height: 88px;
        }
    }

    .not-found-text {
        @mixin title;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .reset-filter-text {
        @mixin paragraph-accent;
        color: var(--av-fixed-secondary);

        &:deep(.a-link__content) {
            @mixin paragraph-accent;
            color: var(--av-brand-primary);
        }
    }

    .pagination {
        margin: 32px auto 0;
    }
}

[dir="rtl"] .s-locator .a-slice-header {
    .a-link {
        right: auto;
        left: 0;

        &:deep(.a-glyph) {
            transform: scaleX(-1);
        }
    }
}
</style>

<style lang="postcss">
.page-locator {
    .gm-style {
        .gm-style-iw-c {
            padding: 0;
            border-radius: 4px;
            max-width: 296px !important;
            min-width: 296px !important;
            box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

            button {
                top: 20px !important;
                inset-inline-end: 16px !important;
                width: 16px !important;
                height: 16px !important;

                span {
                    display: none !important;
                }
            }
        }

        .gm-style-iw-d {
            padding: 16px;
            overflow: hidden !important;
        }

        .gm-style-content {
            .name {
                @mixin lead-accent;
                padding: 0;
                padding-inline-end: 32px;
                color: var(--av-nav-primary);
            }

            .level {
                @mixin body-accent;
                margin: 8px 0 0;
            }

            .address {
                @mixin body;
                margin: 8px 0 0;
                color: var(--av-fixed-secondary);

                + p {
                    margin: 16px 0 0;
                }
            }

            .phone,
            .website {
                margin: 8px 0 0;

                .link {
                    @mixin body-accent;
                    text-decoration: none;
                    color: var(--av-brand-primary);
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    fill: var(--av-brand-primary);
                    margin-inline-end: 8px;
                    vertical-align: middle;
                }
            }

            .close {
                top: 20px;
                inset-inline-end: 16px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                position: absolute;

                line {
                    stroke: var(--av-brand-primary);
                    stroke-width: 2px;
                }
            }
        }
    }
}
</style>
