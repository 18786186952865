<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-blog">
        <div class="a-container">
            <div class="wrapper">
                <s-main-page-tag v-bind="tag({name: 'title'})" />
                <div v-if="card" class="card">
                    <a-picture v-if="card.picture?.link" v-bind="card.picture" />
                    <div class="content">
                        <a-label v-if="card.label?.text" v-bind="card.label">
                            <span v-html="card.label.text" />
                        </a-label>
                        <a-link v-if="card.link" v-bind="card.link" />
                        <p v-if="card.description" class="description" v-html="card.description" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageBlog',
    components: {
        ALabel,
        ALink,
        APicture,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.title || this.card;
        },
        card() {
            return this.content.card || false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-blog {
    padding: 40px 0 0;
    @media (--viewport-tablet) {
        padding-bottom: 40px;
    }
    .wrapper {
        gap: 16px;
        display: flex;
        flex-direction: column;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }
    .title {
        @mixin display-accent;
        text-align: center;
        color: var(--av-nav-primary);
        @media (--viewport-desktop) {
            display: flex;
            @mixin colls 3;
            text-align: left;
            align-items: center;
        }
        @media (--viewport-desktop-large) {
            @mixin hero-accent;
        }
    }
    .card {
        display: flex;
        overflow: hidden;
        text-align: center;
        border-radius: 8px;
        flex-direction: column;
        background: var(--av-inversed-primary);
        box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
        border: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-tablet) {
            text-align: left;
            flex-direction: row;
            align-items: stretch;
        }
        @media (--viewport-desktop) {
            flex: 1 1 0;
            width: auto;
        }
        .content {
            padding: 16px;
            @media (--viewport-tablet) {
                flex: 1 1 0;
            }
        }
        .description {
            @mixin lead;
            margin-top: 24px;
            color: var(--av-fixed-light);
        }
        &:deep(.a-link) {
            width: 100%;
            margin-top: 16px;
            .a-link__content {
                @mixin lead-accent;
                color: var(--av-brand-primary);
                @media (--viewport-tablet) {
                    @mixin title-accent;
                }
            }
        }
        &:deep(.a-label) {
            display: flex;
            margin: 0 auto;
            width: fit-content;
            @media (--viewport-tablet) {
                margin: 0;
            }
        }
        &:deep(.a-picture) {
            width: auto;
            @media (--viewport-tablet) {
                @mixin colls 4;
            }
            .a-picture__img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
