<template>
    <resource-page>
        <div class="press-releases">
            <div class="header-wrapper">
                <h1 class="title">
                    {{ pressReleasesConfig.pressReleases }}
                </h1>
            </div>
            <div class="content-wrapper a-container">
                <div class="filter-media-wrapper">
                    <press-releases-home-filters
                        :common-translations="pressReleasesConfig"
                        :year="year"
                        @change="applyFilter"
                    />
                    <div v-if="!isMobile" class="media-resources">
                        <div class="media-resources-title">
                            {{ pressReleasesConfig.mediaResourceTitle }}
                        </div>
                        <div class="media-resources-desc">
                            {{ pressReleasesConfig.mediaResourceDescription }}
                        </div>
                        <div class="media-resources-links">
                            <dynamic-link
                                v-for="(link, i) in mediaLinks"
                                :key="i"
                                :to="getUrl(link.to)"
                                class="media-resource-link"
                            >
                                {{ link.text }}
                            </dynamic-link>
                        </div>
                    </div>
                </div>
                <div class="press-releases-list">
                    <div class="press-releases-cards">
                        <template v-if="releaseList.length">
                            <press-releases-release-card
                                v-for="(release, i) in releaseList"
                                :key="i"
                                v-bind="release"
                            />
                        </template>
                        <div v-else class="empty-result-message">
                            <span>{{ pressReleasesConfig.emptySearchResultText_1 }}</span>
                            <span class="empty-result-reset" @click="triggerResetFilters">
                                &nbsp;{{ pressReleasesConfig.emptySearchResultText_2 }}&nbsp;
                            </span>
                            <span>{{ pressReleasesConfig.emptySearchResultText_3 }}</span>
                        </div>
                    </div>
                    <pagination
                        v-if="totalPages > 1"
                        :url-template="`/$LOCALE$/pr/${year}/page/$PAGE_NUMBER$/`"
                        :current-page="currentPage"
                        :total-pages="totalPages"
                        :click-handler="setPage"
                    />
                </div>
            </div>
        </div>
    </resource-page>
</template>

<script lang="ts">
import pressReleasesSearch from '@core/mixins/press-releases-search';

export default {
    name: 'PrByYear',

    mixins: [pressReleasesSearch],

    props: {
        year: {
            type: Number,
            required: true,
        },
    },

    created() {
        this.searchData = { ...this.searchHashData };

        if (this.year) {
            this.searchData.year = this.year;
        }

        this.fixPageNumber();
    },
};
</script>

<style lang="postcss" scoped>
.press-releases {
    margin: 0 auto;

    .header-wrapper {
        @mixin page-horizonal-paddings;
        padding-top: 20px;
        padding-bottom: 48px;
        border-top: 1px solid var(--av-brand-light);
        border-bottom: 1px solid var(--av-brand-light);
        background: var(--av-brand-lightest);
        background: linear-gradient(var(--av-brand-lightest), rgba(255, 255, 255, 0));

        @media (--viewport-tablet) {
            padding-top: 16px;
        }
    }

    .title {
        @mixin hero;
        max-width: 1312px;
        margin: 20px auto 0;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: left;
        }
    }

    .content-wrapper {
        @mixin page-horizonal-paddings;

        @media (--viewport-tablet) {
            display: flex;
            gap: 16px;
            margin: 32px auto 56px;
        }
    }

    .press-releases-list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex-grow: 1;
    }

    .press-releases-cards {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex-grow: 1;
        margin-bottom: 56px;

        @media (--viewport-tablet) {
            min-height: 340px;
        }

        @media (--viewport-desktop) {
            min-height: 362px;
        }

        @media (--viewport-desktop-wide) {
            min-height: 464px;
        }
    }

    .filter-media-wrapper {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 100%;

        @media (--viewport-tablet) {
            margin-top: 0;
            max-width: 224px;
        }

        @media (--viewport-desktop) {
            max-width: 228px;
        }

        @media (--viewport-desktop-wide) {
            max-width: 276px;
        }
    }

    .media-resources {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: -8px;
    }

    .media-resources-title {
        @mixin lead-accent;
    }

    .media-resources-desc {
        @mixin paragraph;
        margin-top: 8px;
    }

    .media-resources-links {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 16px;
    }

    .media-resource-link {
        @mixin paragraph;
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }

        &:active {
            color: var(--av-brand-primary);
        }
    }
}

.resource-center-page {
    &:deep(.main-content::before) {
        content: none;
    }

    &:deep(.s-global-footer__social .s-global-footer__social-wrapper) {
        border-top: 0;
    }
}

.empty-result-message {
    text-align: center;
    margin: 100px auto 0;
    @mixin title;
    color: var(--av-fixed-primary);
    max-width: 80%;

    @media (--viewport-mobile-wide) {
        max-width: 82%;
    }

    @media (--viewport-tablet) {
        max-width: 90%;
    }

    @media (--viewport-desktop) {
        max-width: 774px;
    }
}

.empty-result-reset {
    cursor: pointer;
    color: var(--av-brand-primary);
}
</style>
