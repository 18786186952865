<template>
    <div v-if="content" class="s-form-change" :class="className">
        <div v-if="content.title" class="s-form-change__title">
            <p v-for="(text, i) in content.title" :key="i" class="s-form-change__title-item">
                {{ text }}
            </p>
        </div>
        <a-picture v-if="content.picture" class="s-form-change__picture" :link="content.picture" />
        <div v-if="content.description" class="s-form-change__description">
            <p v-for="(text, i) in content.description" :key="i" class="s-form-change__description-item">
                {{ text }}
            </p>
        </div>
        <el-form ref="form" :model="form" :rules="rules">
            <el-row>
                <el-col>
                    <el-form-item prop="businessEmail">
                        <el-input
                            ref="businessEmail"
                            v-model="form.businessEmail"
                            autofocus
                            name="businessEmail"
                            :label="content.form.label"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <a-button
                        class="s-form-change__button"
                        :type="content.form.buttonType || 'action'"
                        v-bind="content.form"
                        :text="content.form.button"
                        :event="{ doNotSendGA: true }"
                        @click="submit"
                    />
                </el-col>
            </el-row>
        </el-form>
        <p class="s-form-change__skip">
            <a-link :text="content.form.link" @click="$emit('submit', email)" />
        </p>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'TrialFormChangeEmail',

    components: {
        AButton,
        ALink,
        APicture,
        ElCol: () => import('@uikit/ui-kit/packages/col'),
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElRow: () => import('@uikit/ui-kit/packages/row'),
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },

        email: {
            type: String,
            default: '',
        },
    },

    emits: ['submit'],

    data: () => ({
        firstClick: true,
        form: {
            businessEmail: '',
        },
    }),

    computed: {
        rules() {
            return {
                businessEmail: [
                    {
                        trigger: 'submit',
                        isBusinessEmail: true,
                        validator: this.validateEmail,
                        errorMessageBusiness: this.setErrorMessage('emailFree'),
                    },
                ],
            };
        },

        className() {
            return `s-form-change-lang-${this.language}`;
        },
    },

    mounted() {
        this.form.businessEmail = this.email;
    },

    methods: {
        async submit() {
            try {
                this.prepareFormToValidate();
                await this.$refs.form.validate();
                this.$emit('submit', this.form.businessEmail);
            } catch (error) {
                this.setFocusErrorField();
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-change {
    width: 100%;
    display: flex;
    margin: 0 0 auto;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 72px 16px 44px;
    justify-content: center;
    background: var(--av-inversed-primary);

    @media (--viewport-mobile-wide) {
        border-radius: 4px;
        padding: 48px 48px 42px;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
    }

    @media (--viewport-tablet) {
        margin: 56px auto 0;
    }

    @media (--viewport-desktop) {
        margin: 0 auto;
    }

    &__title {
        @mixin title-accent;
        color: var(--av-nav-primary);
    }

    &__description {
        @mixin paragraph;
        margin: 16px 0 0;
        color: var(--av-nav-primary);
    }

    &__title-item,
    &__description-item {
        &:not(:first-child) {
            margin: 8px 0 0;
        }
    }

    &-lang-en {
        .s-form-change {
            &__title,
            &__description {
                max-width: 448px;
            }
        }
    }

    .el-form {
        width: 100%;
        margin: 32px 0 0;
        text-align: start;
    }

    &__button {
        width: 100%;
        margin: 16px 0 0;
    }

    &__skip {
        margin: 24px 0 0;
        text-align: center;

        &:deep(.a-link) {
            cursor: pointer;
        }
    }
}
</style>
