/* eslint-disable max-len */

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=46214-83511&t=YdHBHy6Bx0MHVIeQ-4';

export const Default = {
    args: {
        title: 'Features',
        link: { text: 'All Features', to: '#' },
        cards: [
            {
                icon: '1',
                title: 'Absolute VM Protection',
                content: 'Defend more hypervisors, including RedHat, KVM, Oracle VM, Citrix Xen, VMware and Hyper-V, with simple licensing per host allowing protection for an unlimited number of VMs',
                to: '#',
            },
            {
                icon: '2',
                title: 'Acronis Instant Restore',
                content: 'Reduce outage recovery time with best-in-industry RTO by starting any physical or virtual Windows or Linux system directly from the backup storage',
                label: 'Advanced',
                to: '#',
            },
            {
                icon: '3',
                title: 'Acronis Universal Restore',
                content: 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
            },
            {
                icon: '4',
                title: 'Automated Bare-metal Recovery',
                content: 'Recover up to two times faster with a complete system image available and smart technology that automatically detects boot requirements',
            },
            {
                icon: '5',
                title: 'Acronis Universal Restore',
                content: 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
            },
            {
                icon: '6',
                title: 'Acronis Universal Restore',
                content: 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
            },
        ],
    },
};
