/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=57964-1880&t=JMJ2fbzoLIVoGwai-4';

export const ValueCard = {
    args: {
        title: 'Cards Highlight + Value Card',
        lead: 'Convert customers and reap commission rewards: our globally available selection of consumer and corporate products makes it easy.',
        addText: `
            <p>Have questions? <a href="#">Contact us</a></p>
            <p>Already Acronis user? <a href="#">Login now</a></p>
        `,
        centered: false,
        type: '0',
        valueCard: {
            title: 'Start Growing Your Business with Acronis',
            text: 'Apply for the Acronis Cyber Partner Program and start earning money with Acronis.',
            icon: '131',
            to: '#',
            buttonText: 'Join Program',
            type: 'action',
        },
        background: 'light-blue',
    },
};

export const ProductCard = {
    args: {
        title: 'Cards Highlight + Product Card + List',
        lead: 'Technical documentation',
        centered: false,
        type: '1',
        productCard: {
            title: 'Live demo webinar',
            text: 'Join our regular demo sessions to learn how you and your team can leverage the Acronis Cyber Cloud solution.',
            learnLink: { text: 'Learn More', to: '#try' },
            tryLink: { text: 'Register for the webinar', to: '#learn' },
        },
        list: [
            {
                text: 'Acronis Cyber Cloud - Partner Guide',
                to: '#list1',
            },
            {
                text: 'Acronis Cyber Protect Cloud - Cyber Protection Guide',
                to: '#list2',
            },
            {
                text: 'Acronis Cyber Cloud - Management Portal Administrator Guide',
                glyph: 'download-arrow',
                to: '#list3',
            },
        ],
        background: 'light-blue',
    },
};

export const SmallCards = {
    args: {
        title: 'Cards Highlight + Small Cards',
        lead: 'Convert customers and reap commission rewards: our globally available selection of consumer and corporate products makes it easy.',
        addText: `
            <p>Have questions? <a href="#">Contact us</a></p>
            <p>Already Acronis user? <a href="#">Login now</a></p>
        `,
        centered: false,
        type: '0',
        smallCardArr: [
            {
                title: 'Sell Products',
                text: 'Apply for a new reseller partnership and start earning money with Acronis',
                to: '#try',
                icon: '76',
                iconSmall: true,
            },
            {
                title: 'Sell Services',
                text: 'Acronis resellers can add cloud services to their portfolio.',
                to: '#learn',
                icon: '131',
                iconSmall: true,
            },
        ],
        background: 'light-blue',
    },
};
