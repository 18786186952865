export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=58202-114712&t=LWcbKICEQRRYVaDf-4';

export const Default = {
    args: {
        version: 'dark',
        title: 'Organize Your Hard Disk and Its Volumes Fast and Easy',
        productName: 'True Image 2020',
        infoFlag: 'Had jet lag and changed the name',
        buttons: [
            {
                text: 'Buy Now',
                to: '#',
            },
            {
                text: 'Try Now',
                context: 'No credit card required',
                to: '#',
            },
        ],
        actionLinks: [
            {
                text: '+1 781 782 9000',
                to: 'tel:+17817829000',
            },
            {
                text: 'Find a Reseller',
                to: '#',
            },
        ],
        productPageCTA: {
            productId: 64,
            promo: {
                glyph: null,
                label: 'Save up to 30%',
                description: '<del>@price</del> <b>@priceFull</b>',
                cleverBridgeCartList: [236611],
                coupon: 'basic',
            },
        },
        id: 'cta',
    },
};
