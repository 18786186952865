<template>
    <div class="s-header-academy" :class="className">
        <div class="main a-container">
            <div class="main-inner">
                <div class="main-content">
                    <div v-if="section?.title" class="section-name">
                        <a-dynamic-link :to="section.to || '/'">
                            {{ section.title }}
                        </a-dynamic-link>
                    </div>
                    <div v-if="labels" class="labels">
                        <a-label v-for="(label, labelIndex) in labels" :key="`label-${labelIndex}`" v-bind="label">
                            {{ label.text }}
                        </a-label>
                    </div>
                    <h1 class="title">
                        {{ title }}
                    </h1>
                    <p v-if="subtitleProduct" class="subtitle">
                        {{ subtitleProduct }}
                    </p>
                    <div class="bottom">
                        <div class="buttons">
                            <a-button
                                v-for="(button, buttonIndex) in buttons"
                                :key="`button-${buttonIndex}`"
                                class="button"
                                v-bind="button"
                                :size="button.size || 's'"
                                :type="button.type || getButtonType(buttonIndex)"
                                :event="event"
                            />
                        </div>
                        <div v-if="actionLinks.length && !isShort" class="action-links">
                            <a-link
                                v-for="(link, i) in actionLinks"
                                :key="`link-${i}`"
                                class="action-link"
                                v-bind="link"
                                :size="link.size || 'paragraph'"
                                :type="link.type || 'light'"
                            />
                        </div>
                    </div>
                </div>
                <slot name="video" />
            </div>
        </div>
        <div v-if="background" class="bg-container a-container">
            <div v-if="background?.src" class="picture">
                <img :alt="background?.alt || ''" :src="background.src" />
            </div>
            <a-picture
                v-else
                class="bg"
                :class="{ 'bg_subpage': isSubpage }"
                :background="background"
                is-background
                fit="contain"
            />
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SHeaderAcademy',

    components: {
        AButton,
        ADynamicLink,
        ALabel,
        ALink,
        APicture,
    },

    props: {
        background: {
            type: Object,
            required: true,
        },

        event: {
            type: Object,
            default: () => ({
                category: 'Conversions',
                location: 'Top CTA',
            }),
        },

        title: {
            type: String,
            default: '',
        },

        subtitle: {
            type: String,
            default: null,
        },

        settings: {
            type: Object,
            default: undefined,
        },

        section: {
            type: Object,
            default: undefined,
        },

        labels: {
            type: Array,
            default: undefined,
        },

        hasStickyBar: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        actionLinks() {
            return this.settings?.actionLinks || this.product?.actionLinks || [];
        },

        buttons() {
            return this.settings?.buttons || this.product?.buttons || [];
        },
    },

    methods: {
        getButtonType(index) {
            if (index === 0) {
                if (this.buttons.length === 1) return 'action';
                return 'main';
            }

            return 'action';
        },
    },
};
</script>

<style lang="postcss" scoped>

.s-header-academy {
    position: relative;

    /* should be over anchor-menu when dropdown is opened */
    z-index: 121;
    display: flex;
    flex-direction: column;
    padding-top: 88px;
    padding-bottom: 48px;
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);

    @media (--viewport-mobile-wide) {
        min-height: 600px;
    }

    @media (--viewport-desktop) {
        padding-top: 85px;
    }

    @media (--viewport-desktop-wide) {
        padding-top: 96px;
    }

    @media (--viewport-desktop-large) {
        padding-top: 88px;
    }

    .main {
        flex-grow: 1;
        display: flex;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .main-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        position: relative;
    }

    .labels {
        gap: 16px;
        display: flex;
    }

    .section-name {
        @mixin display;
        margin-bottom: 64px;
        color: var(--av-inversed-primary);
    }

    .title {
        @mixin display;

        @media (--viewport-mobile-wide) {
            @mixin hero;
        }

        @media (--viewport-desktop) {
            max-width: 50%;
        }

        @media (--viewport-desktop-wide) {
            font-size: 48px;
            line-height: 56px;
        }
    }

    .bottom {
        margin-top: 40px;
    }

    .buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (--viewport-mobile-wide) {
            display: inline-grid;
            grid-auto-columns: 1fr;
            grid-column-gap: 16px;
        }

        .button {
            width: 100%;
            margin-bottom: 16px;
            padding: 11px 15px;

            + .button {
                margin-bottom: 0;
                margin-inline-start: 0;
            }

            @media (--viewport-mobile-wide) {
                grid-row: 1;
                margin-bottom: 0;
            }
        }
    }

    .button {
        padding: 7px 15px;

        + .button {
            margin-inline-start: 16px;
        }
    }

    .action-links {
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;
    }

    .action-link {
        @mixin paragraph;

        color: var(--av-brand-secondary);
        text-decoration: none;
        margin-inline-end: 24px;
    }

    .bg-container.a-container {
        position: relative;
        width: 100%;
        height: 240px;
        margin-top: 24px;
        margin-bottom: -24px;

        @media (--viewport-desktop) {
            top: 85px;
            position: absolute;
            inset-inline-start: 0;
            inset-inline-end: 0;
            width: 100%;
            margin: auto;
            height: calc(100% - 85px);
        }

        @media (--viewport-desktop-wide) {
            top: 96px;
            height: calc(100% - 96px);
        }

        @media (--viewport-desktop-large) {
            top: 88px;
            height: calc(100% - 88px);
        }
    }

    .bg {
        position: relative;
        width: 100%;
        height: 100%;

        @media (--viewport-desktop) {
            &:deep(.a-picture__img) {
                object-position: center bottom !important;
            }

            &_subpage {
                &:deep(.a-picture__img) {
                    object-position: right bottom !important;
                }
            }
        }
    }
}

</style>
