<template>
    <div ref="container" :class="{ ...className }">
        <div
            v-if="isLoading"
            v-loading="true"
            class="s-form-container__loading"
            el-loading-size="48"
            :el-loading-text="form?.registration?.loading || 'Loading'"
        />
        <form-support-confirmation
            v-if="isActive('support')"
            :content="form.support"
            :form="userData"
        />
        <form-constructor
            v-if="isActive('registration')"
            :user-data="userData"
            :settings-form="settingsForm"
            :content="formRegistrationContent"
            :use-phone-input="form.registration.usePhoneInput"
            :mailgun-error="mailgunError"
            :error-message-a-p-i="errorMessageAPI"
            @loading="isLoading = $event"
            @submit="isSubmittedRegistration"
            @submit-error="onSubmitError"
            @resize="$emit('resize')"
        />
        <form-login
            v-if="isActive('login')"
            :content="form.login"
            @error="showForm('error')"
            @loading="isLoading = $event"
            @submit="getUserData()"
        />
        <form-constructor
            v-if="isActive('update')"
            :content="formUpdateContent"
            :settings-form="settingsForm"
            :use-phone-input="formUpdateContent.usePhoneInput"
            @showForm="showForm"
            @submit="isSubmittedUpdate"
            @resize="$emit('resize')"
        />
        <form-change
            v-if="isActive('change')"
            :content="form.change"
            :email="userData.email"
            @submit="isSubmittedChangeEmail($event)"
        />
        <form-upsell
            v-if="isActive('upsell')"
            :content="form.upsell"
            @showNotification="showNotification($event)"
            @submit="isSubmittedUpsell()"
        />
        <form-error v-if="isActive('error')" :content="errorContent" @submit="showForm(errorContent.activeForm)" />
        <form-success
            v-if="isActive('success')"
            :content="form.success"
            :user-data="userData"
            @onClickButtonSuccess="$emit('onClickButtonSuccess')"
            @backToForm="showForm"
        />
        <form-notification v-if="isActive('notification')" :content="notification" />
        <div v-if="hasRecaptchaInvisible" ref="recaptcha" class="g-recaptcha" />
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import { get, mapValues } from 'lodash';
import { mapState, mapActions } from 'vuex';
import cleverbridge from '@core/mixins/cleverbridge.js';
import currency from '@core/mixins/currency.js';
import form from '@core/mixins/form.js';
import recaptcha from '@core/mixins/recaptcha.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import commonUtils from '@utils/common';
import utils from '@utils/form.js';

import { db } from '../../db.js';

import formChange from './form-change.vue';
import formConstructor from './form-constructor.vue';
import formError from './form-error.vue';
import formLogin from './form-login.vue';
import formNotification from './form-notification.vue';
import formSuccess from './form-success.vue';
import formSupportConfirmation from './form-support-confirmation.vue';
import formUpsell from './form-upsell.vue';

const EVENT_FORM_SUCCESS = 'form-submit-success';
const EVENT_FORM_ERROR = 'form-submit-error';

export default {
    name: 'SFormContainer',

    components: {
        formChange,
        formConstructor,
        formError,
        formLogin,
        formNotification,
        formSupportConfirmation,
        formSuccess,
        formUpsell,
    },

    directives: {
        Loading,
    },

    mixins: [form, recaptcha, currency, cleverbridge],

    props: {
        form: {
            type: Object,
            default: null,
        },

        isActiveSuccess: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['errorAccount', 'onClickButtonSuccess', 'submit', 'resize'],

    data: () => ({
        ck: '',
        abbr: '',
        formType: '',
        activeForm: '',
        userData: {},
        settingsForm: {},
        isLoading: true,
        serverErrors: [],
        notification: {},
        backTo: 'registration',
        FORMS: ['registration', 'login', 'update'],
        errorContent: null,
        mailgunError: {},
        extraMessage: '',
        redirectLink: '',
        redirectSegmented: '',
        segmentRules: null,
        response: {},
        sendLeadTo: '',
        formInstance: null,
        formEventContext: '',
        errorMessageAPI: null,
    }),

    computed: {
        ...mapState({
            copyright: (state) => get(state.slices.items, 's-global-footer.copyright', ''),
            legalLinks: (state) => get(state.slices.items, 's-global-footer.legalLinks', []),
        }),

        className() {
            return {
                's-form-container': this.form,
                's-form-container-sem-ebook': this.form.isEbook,
                [`s-form-container-version-${this.form.version}`]: this.form.version,
            };
        },

        hasFormID() {
            return Boolean(this.form?.flow?.marketo?.form_id);
        },

        hasSuccessPage() {
            return get(this, 'form.flow.successPage', false);
        },

        submitDelay() {
            return get(this, 'form.settings.submitDelay', 0);
        },

        isContactsRenewals() {
            return Boolean(this.form?.flow?.sendContactsRenewals);
        },

        hasNotification() {
            return get(this, 'form.flow.notification', false);
        },

        formRegistrationContent() {
            return {
                ...this.form.registration,
                sliceID: this.form.sliceID,
                isEbook: this.form.isEbook,
                version: this.form.version,
                isFormExpressSignup: Boolean(this.form?.flow?.isFormExpressSignup),
                isFormEvents: Boolean(this.form?.settings?.isFormEvents),
                hasHoneypot: Boolean(this.form?.settings?.hasHoneypot),
                hasAsterisks: this.form?.settings?.hasAsterisks,
                is46514VariantB: this.form?.settings?.is46514VariantB,
            };
        },

        formUpdateContent() {
            return {
                ...this.settingsFormUpdate,
                subtitles: [this.accountData?.email],
                hasAsterisks: this.form?.settings?.hasAsterisks,
            };
        },

        isCloudTrialFormID() {
            return this.form?.flow?.marketo?.form_id === 'cloud_trial_form';
        },

        isContactSalesFormID() {
            return this.form?.flow?.marketo?.form_id === 'CONTACT_SALES';
        },

        needToRegisterAccount() {
            return this.form?.flow?.registerAccount || false;
        },

        needToRegisterPartnerCompany() {
            return this.userData.dont_send_field_services_2 === 'registerPartnerCompany';
        },

        isResourceCenter() {
            return Boolean(this.form?.flow?.isResourceCenter);
        },

        listProductID() {
            return this.form?.flow?.registerProducts || [762];
        },
    },

    watch: {
        accountData(data) {
            if (this.activeForm === 'update' && !data) {
                this.showForm('login');
            }
        },
    },

    beforeDestroy() {
        this.$refs.container.removeEventListener('click', this.handleClick);
    },

    beforeMount() {
        this.activeForm = this.isActiveSuccess ? 'success' : '';
    },

    mounted() {
        this.sendLeadTo = this.form?.flow?.sendLeadTo || '';
        this.redirectLink = this.form?.flow?.redirectLink || '';
        this.redirectSegmented = this.form?.flow?.redirectSegmented || '';
        this.segmentRules = this.form?.flow?.segmentRules || null;
        this.$refs.container.addEventListener('click', this.handleClick);

        this.ck = utils.getCookies(utils.MRKTO_COOKIE_NAME) || utils.createMarketoToken();

        this.abbr = get(this, 'form.registration.abbr', 'ABR');
        this.formType = get(this, 'form.registration.type', 'register');

        if (this.hasRecaptchaInvisible) {
            this.recaptchaInject();
        }

        this.getUserData();
    },

    serverPrefetch() {
        if (this.form?.registration) {
            const locale = this.$route?.params.locale || LOCALE_DEFAULT;
            this.$store.dispatch('slices/getSyncedData', { slice: 's-data-centers', locale });
        }
    },

    methods: {
        ...mapActions({
            accountUpdate: 'trial/accountUpdate',
            accountAgreement: 'trial/accountAgreement',
            accountLogin: 'trial/accountLogin',
            accountRegister: 'trial/accountRegister',
            accountTrial: 'trial/accountTrial',
            sendLead: 'trial/sendLead',
            generateSessionURL: 'cleverbridge/get',
        }),

        handleClick(e) {
            const hash = e.target.hash?.replace('#', '');
            if (hash && this.FORMS.includes(hash)) {
                e.preventDefault();
                if (hash === 'registration') {
                    this.userData = {};
                    this.settingsForm = {};
                }
                this.showForm(hash);
            }
        },

        isActive(name) {
            const hasContent = this.form[name] || name === 'notification';
            return hasContent && this.activeForm === name;
        },

        showNotification(data) {
            this.notification = data;
            this.showForm('notification');
        },

        isProsumer() {
            return this.userData?.company_type === 'Prosumer End-Customer';
        },

        isSegmented() {
            if (!this.segmentRules) return false;
            return Object.entries(this.segmentRules).every(([key, value]) => this.userData[key] === value);
        },

        showForm(name) {
            this.isLoading = false;
            this.activeForm = name;
        },

        setErrorContent(error) {
            const defaultError = this.formElementsDefault?.errors?.apiErrorCodes?.default || 'Error';
            let title = this.formElementsDefault?.errors?.apiErrorCodes?.[error.code] || defaultError;
            let code = error.code;

            const isCloudTrialAborted = this.isCloudTrialFormID && ['ERR_NETWORK', 'ECONNABORTED'].includes(code);
            if (error.code === 'third_party_api_exception' || isCloudTrialAborted) {
                const text = this.formElementsDefault?.errors?.third_party_api_exception?.['500'] || {};
                this.errorContent = { ...this.form.error, ...text };
                return;
            }

            if (error.ex && ['invalid_email', 'mailbox_is_disposable_address'].includes(error.code)) {
                this.settingsForm = {
                    focus: 'email',
                };
                const isTypo = error.ex.reason === 'failed custom grammar check' || error.ex.didYouMean;
                const errorKey = isTypo ? 'invalidMailTypo' : 'invalidMailGeneral';
                title = this.formElementsDefault.errors?.eventsMailGunErrors?.[errorKey] || title;
                code = null;
                this.mailgunError = {
                    message: title,
                    email: this.userData.email,
                };

                if (this.isActive('update')) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'Acronis',
                        eventAction: 'Error',
                        eventCategory: 'Form',
                        eventLabel: error.code,
                    });
                }
            } else {
                this.handleError(error);
            }

            this.errorContent = {
                ...this.form.error,
                title,
                code,
                activeForm: this.isActive('update') ? 'update' : 'registration',
            };
        },

        async isSubmittedUpdate(data, formInstance) {
            this.formInstance = formInstance;

            this.userData = {
                ...data,
                ck: this.ck,
                email: this.accountData?.email || '',
                first_name: this.accountData?.first || '',
                last_name: this.accountData?.last || '',
                country: this.accountData?.country || '',
                state: this.accountData?.state_province || '',
                postal_code: this.accountData?.zip || '',
            };

            if (this.form?.upsell && this.isProsumer()) {
                this.backTo = 'update';
                this.showForm('upsell');
                return;
            }

            if (this.hasRecaptchaInvisible) {
                await this.recaptchaActive().execute(this.recaptchaWidgetId);
            }

            try {
                this.isLoading = true;
                await this.accountUpdate({ phone: this.userData.phone });
                await this.accountTrial({ product_id: this.listProductID });
                await this.trySendLead();
            } catch (e) {
                this.sendVwoEvent(EVENT_FORM_ERROR);
                if (e) {
                    this.setErrorContent(e);
                    this.showForm('error');
                }
                return;
            }

            this.isLoading = true;
            const events = this.form?.update?.sendEventsGA || this.settingsFormUpdate?.sendEventsGA || [];
            this.trySendEventsGA(events);
            this.sendVwoEvent(EVENT_FORM_SUCCESS);

            const redirectLink = this.settingsFormUpdate?.redirectLink;
            const redirectSegmented = this.settingsFormUpdate?.redirectSegmented;
            if (this.isSegmented() && redirectSegmented) {
                window.location.assign(this.replaceLocale(redirectSegmented));
                return;
            }
            if (redirectLink) window.location.assign(this.replaceLocale(redirectLink));
        },

        async getUserDataDB() {
            if (this.accountData) return {};
            const last = await db.fields.orderBy('id').last();
            const result = await db.fields.get(last?.id || '');
            return Object.assign(...result || [{}]);
        },

        async getUserData() {
            await this.$store.dispatch('user/get');

            if (this.accountData && this.form.login && this.settingsFormUpdate) {
                this.showForm('update');
                return;
            }
            this.userData = await this.getUserDataDB();
            this.showForm(this.activeForm || 'registration');
        },

        async setUserData() {
            if (!this.form?.registration?.fields) return;
            const userData = this.form.registration.fields
                .filter((item) => !['password', 'textarea'].includes(item.type))
                .map((item) => {
                    const value = this.userData?.[item.name] || '';
                    return value ? { [item.name]: value } : '';
                })
                .filter(Boolean);

            const last = await db.fields.orderBy('id').last();
            if (last?.id) {
                const userDataSaved = await db.fields.get(last.id);
                const userDataMerged = {
                    ...Object.assign(...userDataSaved),
                    ...Object.assign(...userData),
                };
                const userDataUpdated = Object.keys(userDataMerged).map((key) => ({ [key]: userDataMerged[key] }));
                db.fields.update(last.id, userDataUpdated);
                return;
            }
            db.fields.add(userData);
        },

        isSubmittedRegistration(data, formInstance, formEventContext) {
            this.formEventContext = formEventContext;
            this.formInstance = formInstance;

            this.userData = {
                ...data,
                ck: this.ck,
            };

            if (this.isBusinessEmail() && this.isFreeEmail(data.email)) {
                this.showForm('change');
                return;
            }

            if (this.isProsumer()) {
                if (this.form?.upsell) {
                    this.backTo = 'registration';
                    this.showForm('upsell');
                    return;
                }
                if (this.form?.support) {
                    this.showForm('support');
                    return;
                }
            }

            if (this.userData?.company_type) {
                const fields = this.form?.registration?.fields || [];
                const options = fields.find((item) => item.name === 'company_type')?.options || [];
                const option = options.find((item) => item.code === this.userData.company_type) || {};
                this.redirectLink = option?.redirectLink || this.form?.flow?.redirectLink || '';
                this.redirectSegmented = option?.redirectSegmented || this.form?.flow?.redirectSegmented || '';
            }

            if (this.isContactSalesFormID) {
                this.extraMessage = ['dont_send_field_services_1', 'dont_send_field_services_2']
                    .map((name) => {
                        const value = this.userData[name] || '';
                        const field = this.form.registration.fields.find((item) => item.name === name) || {};
                        const option = (field.options || []).find((item) => item.code === value) || {};
                        return option.text || '';
                    })
                    .filter(Boolean)
                    .join('; ');

                if (this.extraMessage && !this.needToRegisterPartnerCompany) {
                    this.userData.message = `${this.extraMessage}; Message: ${this.userData.message}`;
                }

                if (this.userData.dont_send_field_services_1 === 'show_dont_send_field_services_2') {
                    this.userData.company_type = 'Reseller';
                }
            }

            if (this.isResourceCenter) {
                this.userData.terms = true;
            }

            this.submit();
        },

        isSubmittedChangeEmail(email) {
            this.userData.email = email;
            this.submit();
        },

        isSubmittedUpsell() {
            this.userData = {
                ...this.userData,
                company: '',
                industry: '',
                company_type: 'Corporate End-Customer',
            };

            this.settingsForm = {
                validate: true,
                focus: 'company',
            };

            this.showForm(this.backTo);
        },

        trySendEventsGA(events) {
            events.forEach((item) => {
                // WEB-44753, WEB-45411
                const event = mapValues(item, (value) => {
                    if (value === 'setCountry') {
                        return this.userData?.country || '';
                    }
                    if (value === 'setState') {
                        return this.userData?.state || '';
                    }
                    if (value === 'setDataCenter') {
                        return this.userData?.dc_location || '';
                    }
                    if (value === 'setCompanyType') {
                        return this.userData?.company_type || '';
                    }
                    if (value === 'setServiceSelector') {
                        const conf = this.formRegistrationContent?.fields?.find((f) => f.name === 'service-selector');
                        const opt = conf?.options?.find((o) => o.code === this.userData?.['service-selector']);
                        return opt?.name || '';
                    }

                    return value;
                });

                if (event.eventAction === 'submit_form_success') {
                    if (!event.emailAddress) {
                        event.emailAddress = commonUtils.toSHA256String(this.userData?.email || ''); // WEB-45304
                    }
                    event.formTime = utils.getSecondsOnPage(); // WEB-44946
                }

                this.sendDataLayer({
                    eventContext: this.formEventContext || '',
                    eventContent: this.isFreeEmail(this.userData?.email || '') ? 'FreeEmail' : 'BusinessEmail',
                    ...event,
                });
            });
        },

        async tryRegisterBeta() {
            if (!get(this, 'form.isBeta', false)) {
                return true;
            }

            try {
                this.isLoading = true;
                this.$store.dispatch('user/logout');
                const response = await this.accountLogin({
                    email: this.userData.email,
                    password: this.userData.password,
                });

                if (response.status === 200) {
                    return true;
                }

                const msg = 'Wrong email or password';
                const message = get(this, 'form.registration.messages.error', msg);
                this.$emit('errorAccount', message);
                return Promise.reject();
            } catch (e) {
                this.setErrorContent(e);
                this.showForm('error');
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        async tryRegisterAccount() {
            if (!this.needToRegisterAccount) return true;

            try {
                this.isLoading = true;

                const response = await this.accountRegister(this.payloadAccount);

                if (response.data?.errors?.email) {
                    const message = this.form?.registration?.messages?.accountExist || '';
                    this.$emit('errorAccount', message || 'This email address is already in use.');
                    this.showForm('registration');
                    return Promise.reject();
                }

                if (response.status !== 204) {
                    throw new Error('Unsuccessful');
                }
            } catch (e) {
                this.setErrorContent(e);
                this.showForm('error');
                return Promise.reject();
            }

            await this.accountLogin({
                email: this.userData.email,
                password: this.userData.password,
            });

            await this.accountAgreement({ accept: true });

            await this.accountTrial({ product_id: this.listProductID });

            this.isLoading = false;
            return true;
        },

        async mailgunValidation() {
            if (!this.needToRegisterAccount) return true;

            if (this.hasRecaptchaInvisible) {
                await this.recaptchaActive().execute(this.recaptchaWidgetId);
            }

            try {
                this.isLoading = true;
                const payload = this.payloadMarketo();
                await this.sendLead({
                    mailgun_check: true,
                    email: payload.email,
                    form_id: payload.form_id,
                    // eslint-disable-next-line no-underscore-dangle
                    _recaptchaResponse: payload._recaptchaResponse,
                    actionURL: this.sendLeadTo || '/svc/v1/marketing/forms/leads',
                });
                return true;
            } catch (error) {
                this.setErrorContent(error);
                this.showForm('error');
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        async trySendLead() {
            if (!this.hasFormID && !this.sendLeadTo) {
                return true;
            }

            try {
                this.isLoading = true;

                const payload = {
                    ...this.payloadMarketo(),
                    actionURL: this.sendLeadTo || '/svc/v1/marketing/forms/leads',
                };

                if (this.needToRegisterPartnerCompany) {
                    payload.firstName = this.userData.first_name;
                    payload.lastName = this.userData.last_name;
                    payload.actionURL = '/svc/v1/partners-portal/join-us/case';
                }

                if (this.formRegistrationContent.isFormEvents) {
                    // WEB-46729
                    payload.attend_training = payload.attend_training === 'true';
                }

                this.response = await this.sendLead(payload);

                return true;
            } catch (error) {
                if (error.code === 'third_party_api_exception' && error.ex?.gatewayStatus?.toString().startsWith(4)) {
                    this.errorMessageAPI = this.formElementsDefault?.errors?.[error.code]?.['400'] || 'Error';
                    this.errorMessageAPI = this.errorMessageAPI.replace('@userEmail', encodeURIComponent(this.userData.email));
                    this.settingsForm = { validate: true, focus: 'email' };
                    this.showForm('registration');
                    return Promise.reject();
                }
                this.setErrorContent(error);
                this.showForm('error');
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        async trySendContactsRenewals() {
            if (!this.isContactsRenewals) {
                return true;
            }

            try {
                this.isLoading = true;
                const payload = this.payloadMarketo();

                await this.sendLead({
                    ...payload,
                    Origin: 'web',
                    termsAgreement: true,
                    countryCode: payload.country,
                    contactEmail: payload.email,
                    contactPhone: payload.phone,
                    Subject: `${payload.companyName} - Renewal Request`,
                    actionURL: '/svc/v1/acronis-contacts/renewals/case',
                });

                return true;
            } catch (e) {
                this.setErrorContent(e);
                this.showForm('error');
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        async trySendContactUsForm() {
            if (!this.form.flow.isContactUsForm) {
                return true;
            }
            try {
                this.isLoading = true;
                const payload = this.payloadMarketo();
                await this.sendContactUsForm(payload);
                return true;
            } catch (e) {
                this.setErrorContent(e);
                this.showForm('error');
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        onSubmitError() {
            this.sendVwoEvent(EVENT_FORM_ERROR);
        },

        async submit() {
            this.guid = utils.getGUID();

            try {
                await this.mailgunValidation();

                if (this.hasRecaptchaInvisible) {
                    await this.recaptchaActive().execute(this.recaptchaWidgetId);
                }

                await this.trySendContactUsForm();
                await this.tryRegisterAccount();
                await this.trySendLead();
                await this.trySendContactsRenewals();
                await this.tryRegisterBeta();
                await this.setUserData();
            } catch (e) {
                this.sendVwoEvent(EVENT_FORM_ERROR);
                return;
            }

            this.isLoading = true;
            this.sendEventMarketingSubscription(this.userData.gdpr_opt_in || this.userData.receiveNews || this.userData.opt_in);
            this.trySendEventsGA(this.form?.flow?.sendEventsGA || []);
            this.sendVwoEvent(EVENT_FORM_SUCCESS);

            if (this.submitDelay) await commonUtils.sleep(this.submitDelay);

            if (this.isSegmented() && this.redirectSegmented) {
                window.location.assign(this.replaceLocale(this.redirectSegmented));
                return;
            }

            if (this.redirectLink) {
                window.location.assign(this.replaceLocale(this.redirectLink));
                return;
            }

            if (this.form?.flow?.redirectToCleverbridge) {
                this.redirectToCleverbridge(this.payloadPartnerSignupCloud);
                return;
            }

            if (this.form?.flow?.getExpressSignupFormSURL) {
                this.redirectToCleverbridge(this.payloadExpressSignup);
                return;
            }

            if (this.hasSuccessPage) {
                this.showForm('success');
            }

            if (this.hasNotification) {
                this.notification = this.form?.flow.notification;
                this.showForm('notification');
            }

            this.$emit('submit', {
                userData: this.userData,
                response: this.response,
            });
        },

        sendVwoEvent(type) {
            if (typeof window === 'undefined') return;
            if (!this.formInstance) return;
            const eventValue = type === EVENT_FORM_SUCCESS ? 1 : 0;
            window.VWO = window.VWO || [];
            window.VWO.push(['nls.formAnalysis.markSuccess', this.formInstance, eventValue]);
            delete this.formInstance;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-container {
    position: relative;
    margin: 40px -16px auto;
    color: var(--av-fixed-secondary);

    @media (--viewport-mobile-wide) {
        width: 100%;
        margin: 56px auto auto;
    }

    @media (--viewport-tablet) {
        width: 648px;
    }

    @media (--viewport-desktop) {
        margin: 0 auto auto;
        width: 552px;
    }

    @media (--viewport-desktop-wide) {
        width: 648px;
    }

    &__loading {
        top: 2px;
        left: 2px;
        gap: 16px;
        z-index: 10;
        display: flex;
        padding: 0 48px;
        text-align: center;
        position: absolute;
        border-radius: 4px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        inset-inline-start: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        color: var(--av-fixed-primary);
        background: var(--av-inversed-secondary);
    }

    &:deep(.el-form) {
        &-item {
            padding: 0;

            .el-input {
                &__icon {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &:deep(.el-input) {
        &__container {
            text-align: start;
        }

        &__placeholder {
            @mixin paragraph;
        }

        &__label {
            @mixin paragraph;

            &.is-active {
                @mixin caption;
                padding-top: 4px;
            }
        }
    }

    &:deep(.el-textarea) {
        &__editor {
            @mixin body;
            max-height: 134px;
        }
    }

    &:deep(.el-checkbox) {
        @mixin body;
        display: block;
        text-align: start;
        color: var(--av-fixed-secondary);

        @media (--viewport-tablet) {
            @mixin paragraph;
        }

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &:deep(input),
    &:deep(select),
    &:deep(textarea) {
        @mixin paragraph;
    }

    .g-recaptcha {
        &:deep(> div) {
            margin: 0 auto;
        }

        &:deep(.grecaptcha-badge) {
            display: none;
        }
    }
}

.s-form-container.s-form-container-sem-ebook {
    width: 328px;
    min-height: 300px;
    margin: 48px auto 32px;

    @media (--viewport-tablet) {
        width: 328px;
        min-width: 344px;
        margin: 40px 0 0 16px;
    }

    @media (--viewport-desktop) {
        width: 309px;
        min-width: auto;
        margin: -280px 0 0;
    }

    @media (--viewport-desktop-wide) {
        width: 309px;
        min-width: 373px;
    }

    @media (--viewport-desktop-large) {
        min-width: 427px;
    }
}

.s-form-container.s-form-container-version-42019 {
    margin: 40px 0 auto;

    @media (--viewport-tablet) {
        width: 50%;
        margin-top: 0;
    }

    @media (--viewport-desktop) {
        max-width: 472px;
    }

    @media (--viewport-desktop-wide) {
        max-width: 568px;
    }

    @media (--viewport-desktop-large) {
        max-width: 648px;
    }
}
</style>

<style lang="postcss">
.el-select-dropdown {
    &.el-select-dropdown__form-registration {
        .el-text--strong {
            font-weight: 500;
        }
        .sublabel {
            font-size: 12px;
            line-height: 16px !important;
        }
        .el-select-group {
            .el-select-dropdown__item {
                height: auto;
                padding: 0 16px;

                &.is-multi-line {
                    padding: 8px 16px;
                }
            }
        }
        .el-select-group__title {
            @mixin note-heading;
            padding: 8px 16px;
            color: var(--av-fixed-light);
        }
        .el-select-group__wrap:not(:last-of-type) {
            padding-bottom: 16px;

            &::after {
                bottom: 8px;
                background: var(--av-brand-accent);
            }
        }
    }
}
</style>
