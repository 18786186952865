<template>
    <s-basic-slice :id="id" class="s-cta-simple" :class="basicClassName">
        <div class="a-slice-header__title">
            {{ title }}
        </div>
        <div class="s-cta-simple__wrapper">
            <div class="a-slice-header__lead">
                <p v-if="lead">
                    {{ lead }}
                </p>
                <div v-if="paragraph?.length" class="a-slice-header__paragraph">
                    {{ paragraph }}
                </div>
                <a-product-cta :settings="{ hasDescription: true, ...productPageCTA }" />
            </div>
            <div class="s-cta-simple__button-wrapper">
                <template v-if="buttons">
                    <a-button
                        v-for="(button, buttonIndex) in buttons"
                        :key="`button-${buttonIndex}`"
                        class="s-cta-simple__button"
                        v-bind="button"
                        :type="button.type || 'main'"
                        :glyph="button.glyph || 'arrow'"
                        @click="handleClick(button.optionsSURL)"
                    />
                </template>

                <template v-else>
                    <a-button
                        class="s-cta-simple__button"
                        :to="buttonTo"
                        :text="buttonText"
                        :type="buttonType"
                        glyph="arrow"
                    />

                    <a-button
                        v-if="actionButtonAvailable"
                        class="s-cta-simple__button"
                        :to="actionButtonTo"
                        :text="actionButtonText"
                        :type="actionButtonType"
                        glyph="arrow"
                    />
                </template>
            </div>
        </div>
        <a-loader :is-visible="isVisibleSURL" />
    </s-basic-slice>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import { TYPES } from '@core/components/button/constants.js';
import ALoader from '@core/components/loader/loader.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCtaSimple',

    components: {
        AButton,
        ALoader,
        AProductCta,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin, purchasing, analytics, cleverbridge],

    props: {
        /**
         * CTA Button to
         */
        buttonTo: {
            type: String,
            default: '',
        },

        /**
         * CTA Button text
         */
        buttonText: {
            type: String,
            required: true,
        },

        /**
         * CTA Button type
         */
        buttonType: {
            type: String,
            default: 'main',
            validator: (value) => TYPES.includes(value),
        },

        actionButtonTo: {
            type: String,
            default: '',
        },

        actionButtonText: {
            type: String,
            default: '',
        },

        actionButtonType: {
            type: String,
            default: 'main',
            validator: (value) => TYPES.includes(value),
        },

        productPageCTA: {
            type: Object,
            default: undefined,
        },

        paragraph: {
            type: String,
            default: '',
        },

        buttons: {
            type: Array,
            default: null,
        },
    },

    computed: {
        actionButtonAvailable() {
            return this.actionButtonTo.length && this.actionButtonText.length;
        },
    },

    methods: {
        handleClick(optionsSURL) {
            if (optionsSURL) {
                const options = this.storeOptionsSURL(optionsSURL);
                this.redirectToCleverbridge(options);
            }
        },
    },

    async serverPrefetch() {
        await this.getLicenses(this.productPageCTA);
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-simple {
    .a-slice-header {
        &__title {
            margin-bottom: 24px;
            text-align: start;
            word-break: break-word;

            @media (--viewport-desktop) {
                @mixin colls 9;
            }
        }

        &__lead {
            margin-top: 0;
            margin-bottom: 40px;
            text-align: start;

            @media (--viewport-desktop) {
                @mixin colls 9;
                margin-bottom: 0;
            }

            p {
                word-break: break-word;
            }
        }

        &__lead {
            p:not(:first-child) {
                margin: 24px 0 0;
            }
        }

        &__paragraph {
            font-size: 14px;
            line-height: 18px;
            color: var(--av-nav-primary);
            margin-top: 24px;
            word-break: break-word;

            @media (--viewport-desktop) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    &__wrapper {
        @media (--viewport-desktop) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &__button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (--viewport-mobile-wide) {
            flex-direction: row;
        }

        @media (--viewport-tablet) {
            @mixin colls 4;
        }

        @media (--viewport-desktop) {
            flex-direction: column;
            @mixin colls 3;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 2;
        }

        &:deep(.a-glyph) {
            display: none;

            @media (--viewport-mobile-wide) {
                display: block;
            }
        }

        &:deep(.a-button) {
            &__content {
                display: block;
                width: 100%;
                text-align: center;

                @media (--viewport-mobile-wide) {
                    text-align: start;
                }
            }
        }
    }

    &__button {
        width: 100%;
        flex-shrink: 0;
        min-width: 205px;

        @media (--viewport-mobile-wide) {
            flex-shrink: 1;
            max-width: 205px;
        }
    }

    &:deep(.cta) {
        @mixin lead;
        margin-top: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin title;
        }
    }

    &:deep(.cta-description) {
        margin: 0;
    }
}

[dir='rtl'] {
    .s-cta-simple__button {
        &:deep(.a-glyph) {
            transform: rotate(180deg);
        }
    }
}
</style>
