<template>
    <div class="blog-container" :class="className">
        <div v-if="width === 'default'" class="container">
            <slot />
        </div>
        <div v-else class="container">
            <slot name="outer" />
            <div class="inner">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import * as c from './constants.js';

export default {
    name: 'BlogContainer',
    props: {
        theme: {
            type: String,
            default: 'light',
            validator: (prop) => c.BLOG_CONTAINER_THEME_LIST.includes(prop),
        },

        width: {
            type: String,
            default: 'default',
            validator: (prop) => c.BLOG_CONTAINER_WIDTH_LIST.includes(prop),
        },
    },
    computed: {
        className() {
            return {
                'dark-navy': this.theme === 'dark-navy',
                navy: this.theme === 'navy',
                grey: this.theme === 'grey',
                'light-grey': this.theme === 'light-grey',
                short: this.width === 'short',
                narrow: this.width === 'narrow',
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-container {
    .container {
        margin-inline-start: auto;
        margin-inline-end: auto;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        max-width: 1440px;

        @media (--viewport-tablet) {
            padding-inline-start: 32px;
            padding-inline-end: 32px;
        }

        @media (--viewport-desktop-wide) {
            padding-inline-start: 64px;
            padding-inline-end: 64px;
        }
    }

    &.dark-navy {
        background: rgba(4, 28, 62, 1);
    }

    &.navy {
        background: var(--av-nav-primary);
    }

    &.grey {
        background-color: var(--av-solid-brand-accent);
    }

    &.light-grey {
        background-color: var(--av-solid-brand-lightest);
    }

    &.short {
        .container {
            @media (--viewport-desktop) {
                display: grid;
                column-gap: 16px;
                grid-template-columns: 1fr 10fr 1fr;
            }
        }

        .inner {
            @media (--viewport-desktop) {
                grid-column-start: 2;
                grid-column-end: 3;
            }
        }
    }

    &.narrow {
        .container {
            align-items: start;
            @media (--viewport-desktop) {
                display: grid;
                column-gap: 16px;
                grid-template-columns: 1fr 10fr 1fr;
            }

            @media (--viewport-desktop-wide) {
                grid-template-columns: 1fr 58% 1fr;
            }
        }

        .inner {
            @media (--viewport-desktop) {
                grid-column-start: 2;
                grid-column-end: 3;
            }
        }
    }
}
</style>
