<template>
    <s-basic-slice class="s-features-large" :class="className" v-bind="$attrs">
        <div class="s-features-large__content">
            <div class="s-features-large__cards">
                <ul class="s-features-large__list">
                    <li
                        v-for="(card, i) in cards"
                        :key="i"
                        class="s-features-large__item"
                    >
                        <a-feature-card v-bind="card" :title-tag="$attrs.title && 'h3'" />
                    </li>
                </ul>
            </div>
            <a-link
                v-if="link"
                class="s-features-large__link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </s-basic-slice>
</template>

<script>
import AFeatureCard from '@core/components/feature-card/feature-card.vue';
import ALink from '@core/components/link/link.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SFeaturesLarge',
    components: {
        SBasicSlice,
        AFeatureCard,
        ALink,
    },
    inheritAttrs: false,
    props: {
        link: {
            type: Object,
            default: undefined,
        },
        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 1 && cards.length < 7,
        },
    },
    computed: {
        className() {
            return {
                's-features-large_layout_4-cards': this.cards.length === 4,
                's-features-large_layout_without-link': !this.link,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-features-large {
    &__list {
        margin-bottom: -56px;
        width: 100%;

        @media (--viewport-mobile-wide) {
            display: flex;
            flex-wrap: wrap;
            margin-inline-start: -8px;
            margin-inline-end: -8px;
        }

        @media (--viewport-desktop) {
            display: flex;
            flex-wrap: wrap;
            margin-inline-start: -16px;
            margin-inline-end: -16px;
        }
    }

    &__item {
        margin-bottom: 56px;

        @media (--viewport-mobile-wide) {
            width: 50%;
            padding: 0 16px;
        }

        @media (--viewport-desktop) {
            width: 33.33%;
        }
    }

    &:deep(.a-feature-card__text) {
        margin-top: 16px;
    }

    &__link {
        margin-top: 56px;

        @media (--viewport-desktop) {
            position: absolute;
            top: 8px;
            inset-inline-end: 32px;
            margin-top: 0;
            max-width: 25%;
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: 64px;
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: 0;
        }
    }

    &_layout {
        @media (--viewport-desktop) {
            &_4-cards {
                .s-features-large {
                    &__content {
                        display: flex;
                        align-items: flex-start;
                    }

                    &__cards {
                        width: 66.6%;
                        margin-inline-end: 150px;
                    }

                    &__list {
                        padding-inline-end: 0;
                        margin-bottom: 0;
                        margin-inline-end: 0;
                        position: relative;

                        &::after {
                            content: '';
                            width: 1px;
                            height: 100%;
                            background: var(--av-brand-secondary-accent);
                            position: absolute;
                            inset-inline-end: -108px;
                            top: 0;
                        }
                    }

                    &__item {
                        width: 50%;

                        &:not(:nth-child(-n + 2)) {
                            margin-bottom: 0;
                        }
                    }

                    &__link {
                        margin-top: 112px;
                        margin-inline-start: auto;
                        position: static;
                    }
                }
            }

            &_without-link {
                .s-features-large {
                    &__list {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        @media (--viewport-desktop-wide) {
            &_4-cards {
                .s-features-large {
                    &__cards {
                        margin-inline-end: 205px;
                    }

                    &__list {
                        &::after {
                            inset-inline-end: -117px;
                        }
                    }
                }
            }
        }

        @media (--viewport-desktop-large) {
            &_4-cards {
                .s-features-large {
                    &__cards {
                        margin-inline-end: 236px;
                    }

                    &__list {
                        &::after {
                            inset-inline-end: -130px;
                        }
                    }
                }
            }
        }
    }
}
</style>
