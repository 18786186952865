<template>
    <s-basic-slice class="s-features-small" :class="className" v-bind="$attrs">
        <ul class="s-features-small__list">
            <li v-for="(card, i) in cards" :key="i" class="s-features-small__item">
                <a-feature-card
                    v-bind="card"
                    size="xs"
                    icon-small
                    :title-tag="$attrs.title && 'h3'"
                />
            </li>
        </ul>
        <a-link
            v-if="link"
            class="s-features-small__link"
            v-bind="link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import AFeatureCard from '@core/components/feature-card/feature-card';
import ALink from '@core/components/link/link';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice';

export default {
    name: 'SFeaturesSmall',
    components: {
        SBasicSlice,
        ALink,
        AFeatureCard,
    },
    inheritAttrs: false,
    props: {
        cards: {
            type: Array,
            required: true,
        },

        link: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    computed: {
        className() {
            return {
                's-features-small_layout_4': [4, 7, 8].includes(this.cards.length),
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-features-small {
    display: block;

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin-bottom: 56px;
    }

    &:deep(.a-slice-header) {
        margin-bottom: 56px;
    }

    @media (--viewport-mobile-wide) {
        &__list {
            margin-inline-end: -32px;
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__item {
            width: 49%;
            padding-inline-end: 32px;
        }
    }

    @media (--viewport-desktop) {
        .a-slice-header {
            max-width: 66.66%;
        }

        &__link {
            position: absolute;
            top: 8px;
            inset-inline-end: 23px;
            max-width: 25%;
        }

        &__list {
            margin-bottom: -56px;
            margin-inline-start: 0;
            margin-inline-end: -32px;
        }

        &__item {
            width: 33%;
            padding-inline-end: 32px;
        }

        &_layout {
            &_4 {
                .s-features-small {
                    &__list {
                        margin-bottom: -56px;
                        margin-inline-start: 0;
                        margin-inline-end: -32px;
                    }

                    &__item {
                        width: 24.5%;
                        padding-inline-end: 32px;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__link {
            top: 7px;
            inset-inline-end: 64px;
        }
    }

    @media (--viewport-desktop-large) {
        &__link {
            inset-inline-end: 0;
        }
    }
}
</style>
