<template>
    <section :id="id" :class="{ ...basicClassName }">
        <s-purchasing-version-2 v-if="isVersion2" :page="page" :purchase-page-c-t-a="purchasePageCTA" />
        <div v-else ref="purchasing" :class="['s-slice s-purchasing-acp', { 's-purchasing-acp-promo': hasPromo }]">
            <template v-if="loaded">
                <section class="s-purchasing-acp__wrapper" :class="className">
                    <div class="a-container">
                        <navigation :settings="settings" />
                        <a-slice-header class="s-purchasing-acp__header" :title="settings.title">
                            <template v-if="isFirstStep && !microsoft365Added">
                                <p class="s-purchasing-acp__buttom-addons">
                                    <a-button
                                        type="main"
                                        glyph="arrow"
                                        :text="setTranslate('Buy cloud storage and more')"
                                        :event="{ doNotSendGA: true }"
                                        @click="showAddons()"
                                    />
                                </p>
                            </template>
                            <template v-else-if="hasPromo">
                                <p class="s-purchasing-acp__workload-description-promo-summary">
                                    {{ promoSummary }}
                                </p>
                            </template>
                        </a-slice-header>
                    </div>
                    <div v-if="isFirstStep" class="a-container">
                        <s-step-1 :settings="settings" />
                        <comparison v-if="isComparisonActive" :settings="settings" />
                    </div>
                    <div v-else class="a-container">
                        <s-step-2 :settings="settings" />
                    </div>
                </section>
                <section v-if="settings.faq" class="s-purchasing-acp__faq">
                    <s-faq v-bind="settings.faq" />
                </section>
            </template>
            <template v-else>
                <div
                    v-loading="true"
                    class="loader"
                    el-loading-size="48"
                    el-loading-color="brand-primary"
                />
            </template>
        </div>
    </section>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { cloneDeep, forEach, get, max, merge, mapValues } from 'lodash';
import AButton from '@core/components/button/button.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import SFaq from '@core/slices/pages/faq/faq.vue';
import mixin from './components/mixin.js';
import navigation from './components/navigation.vue';
import comparison from './components/step-1/comparison.vue';
import sStep1 from './components/step-1/index.vue';
import sStep2 from './components/step-2/index.vue';
import SPurchasingVersion2 from './version-2/index.vue';

export default {
    name: 'SPurchasingCyberProtect',

    components: {
        AButton,
        ASliceHeader,
        SFaq,
        sStep1,
        sStep2,
        comparison,
        navigation,
        SPurchasingVersion2,
    },

    directives: {
        Loading,
    },

    mixins: [mixin, contentMixin, styleMixin, purchasing, analytics, cleverbridge],

    props: {
        page: {
            type: Object,
            default: null,
        },

        purchasePageCTA: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        settings: {},
        loaded: false,
        STORE_SLICE_NAME: 's-purchasing-cyber-protect',
        searchParams: null,
    }),

    computed: {
        className() {
            return {
                [`s-purchasing-acp-${this.language}`]: this.language,
                's-purchasing-acp__wrapper-background': this.isFirstStep,
            };
        },

        isFirstStep() {
            return this.stepActive === 1;
        },

        promoSummary() {
            return this.settings?.summary || '';
        },

        slice() {
            return get(this.$store.state.slices.items, this.STORE_SLICE_NAME, {});
        },

        hasPromo() {
            return this.settings?.promo?.isPromoActive || false;
        },

        isVersion2() {
            return this.slice?.['version-2'];
        },
    },

    watch: {
        '$route.hash': {
            handler(to, from) {
                if (from !== '') {
                    this.changeStep(to.replace(/.*step=(\d).*/g, '$1'));
                }
            },
        },
    },

    destroy() {
        if (!this.isVersion2) {
            this.$refs.purchasing.removeEventListener('click', this.changeTab);
        }
    },

    mounted() {
        if (this.isVersion2) {
            return;
        }

        document.body.classList.add('page-purchasing-acp');
        this.$refs.purchasing.addEventListener('click', this.changeTab);

        if (!this.slice) {
            return;
        }

        const settings = cloneDeep(this.slice);
        forEach(settings.sections, (section, s) => {
            forEach(get(section, 'products', []), (product, p) => {
                const current = settings.sections[s].products[p];
                settings.sections[s].products[p] = {
                    ...product,
                    ...this.productClear,
                };

                // Workload Edition
                forEach(get(product, 'editions', []), (edition, e) => {
                    current.editions[e] = {
                        added: false,
                        quantity: {
                            min: 1,
                            max: 99,
                            title: settings.dictionary.Quantity || 'Quantity',
                        },
                        ...edition,
                        ...this.editionClear,
                    };
                });
            });
        });

        const isPurchase = true;
        const cta = merge(this.productCTA({ isPurchase }), this.purchasePageCTA);
        const isPromoActive = !cta.doNotApplyPromo && this.checkPromo({ ...cta.promo, isPurchase });

        this.settings = { ...settings, isPurchase, promo: { ...cta.promo, isPromoActive } };

        this.init();
    },

    async serverPrefetch() {
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-loader', locale: this.locale });
        await this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: this.locale });

        const listCartID = this.slice?.specialOffer?.licenseID || {};
        const listCleverBridgeCartID = mapValues(listCartID, (item) => ({ cleverBridgeCartID: item }));
        const slice = { ...this.slice, ...listCleverBridgeCartID };
        const settings = { ...this.purchasePageCTA, isPurchase: true };

        await this.findCleverBridgeCart(slice, settings);
    },

    methods: {
        setPeriod(str) {
            return Number(str.replace(/p(\d)/i, '$1')) || 0;
        },

        setQuantity(str) {
            return Number(str.replace(/q(\d)/i, '$1')) || 1;
        },

        subscriptionID(subscriptions, period, type) {
            return subscriptions.standard.findIndex((subscription) => subscription[type || 'period'] === period);
        },

        init() {
            this.searchParams = new URL(document.location).searchParams;
            const search = this.searchParams.get('products');

            if (search) {
                const products = search.split(';').reverse();

                products.forEach((product, i) => {
                    let added = false;
                    const productID = product.replace(/\(.*/, '');
                    const current = this.currentProduct(productID);

                    if (/cloud-storage|disaster-recovery/.test(productID)) {
                        this.settings.sectionsActive = 1;
                    }

                    if (/(onboarding|professional)-service/.test(productID)) {
                        this.settings.sectionsActive = 2;
                    }

                    if (!current) {
                        return;
                    }

                    if (current.editions) {
                        const editions = product.replace(/.*\((.*)\)/, '$1').split(',');
                        editions.forEach((item) => {
                            const options = item.split(':');
                            const editionID = options[0];
                            const currentEdition = current.editions.find((e) => (e.id === editionID ? e : ''));

                            if (!currentEdition) {
                                return;
                            }

                            if (currentEdition.package) {
                                currentEdition.package.selected = 0;
                            }

                            options.forEach((option) => {
                                if (option === 'custom' && currentEdition.package) {
                                    currentEdition.package.selected = 1;
                                }

                                if (/^q/.test(option)) {
                                    currentEdition.selectedQuantity = this.setQuantity(option);
                                }

                                if (/^p/.test(option)) {
                                    const period = this.setPeriod(option);
                                    const subscriptions = currentEdition.subscriptions;
                                    const selected = this.subscriptionID(subscriptions, period);
                                    if (selected !== -1) {
                                        currentEdition.selectedPeriod = selected;
                                    }
                                }
                            });

                            added = true;
                            this.editionAdd(current, currentEdition);
                        });
                        this.settings.sectionsActive = 0;
                    }

                    if (productID === 'cloud-storage') {
                        const cloudQuantity = [];
                        added = true;
                        product
                            .replace(/.*\((.*)\)/, '$1')
                            .split(':')
                            .forEach((option) => {
                                if (option === 'pds') {
                                    current.addons[0].added = true;
                                }

                                if (/^c/.test(option)) {
                                    const quantity = Number(option.replace(/c(\d)/i, '$1')) || 1;
                                    cloudQuantity.push(quantity);
                                }

                                if (/^p/.test(option)) {
                                    const period = this.setPeriod(option);
                                    const subscriptions = current.subscriptions;
                                    const selected = this.subscriptionID(subscriptions, period);
                                    if (selected !== -1) {
                                        current.selectedPeriod = selected;
                                    }
                                }
                            });

                        const cloud = current.cloud;
                        const cloudMax = max(cloudQuantity);
                        const selected = cloud.standard[cloud.selected].findIndex((c) => c.size === cloudMax);
                        if (selected !== -1) {
                            current.selectedCloud = selected;
                        }
                    }

                    if (productID === 'disaster-recovery') {
                        added = true;
                        const size = product.replace(/.*\((.*)\)/, '$1');
                        const subscriptions = current.subscriptions;
                        const selected = this.subscriptionID(subscriptions, size, 'size');
                        if (selected !== -1) {
                            current.selectedPeriod = selected;
                        }
                    }

                    if (productID === 'onboarding-service') {
                        product
                            .replace(/.*\((.*)\)/, '$1')
                            .split(':')
                            .forEach((option) => {
                                if (option === 'adv') {
                                    current.perpetual.isAdvanced = true;
                                }
                                if (/^q/.test(option)) {
                                    added = true;
                                    current.selectedQuantity = this.setQuantity(option);
                                }
                            });
                    }

                    merge(current, {
                        added,
                        selected: !/professional-services/.test(productID),
                        time: this.time() + i,
                        opened: i === products.length - 1,
                    });

                    this.settings.steps.active = 2;
                });
            }

            this.loaded = true;
            this.changeStep(this.settings?.steps?.active || 1);
        },

        showAddons() {
            this.changeActiveSection(1);
            this.changeStep(2);
        },

        changeTab(e) {
            const id = e.target.dataset?.showTab;
            if (id) {
                this.changeActiveSection(id);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp {
    padding: 0;
    margin: 0 auto;
    min-height: 600px;
    min-width: 1214px;
    position: relative;

    .a-container {
        margin: 0 auto;
    }

    .loader {
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        position: absolute;
        background: var(--av-inversed-primary);
    }

    .a-slice-header {
        margin: 40px 0 0 0;
        width: 100%;

        &__title {
            @mixin hero;
        }

        &__lead {
            @mixin display;
            margin: 32px 0 -8px;
        }

        &__body-text {
            @mixin title;
        }
    }

    &:deep(.a-tabs) {
        &__header {
            user-select: none;
        }

        &__item:not(:last-of-type) {
            margin-inline-end: 24px;
        }
    }

    &:deep(.el-num-picker) {
        &__container {
            display: flex;
            height: 40px;
            width: 110px;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            background-clip: padding-box;
            border: 1px solid var(--av-brand-light);
            background-color: var(--av-inversed-primary);

            &:active {
                border-color: var(--av-brand-primary);
            }
        }

        &__editor {
            flex: 1 1 0;

            .el-input {
                &__container {
                    height: 100%;
                    border: none;
                    border-radius: 0;
                }

                &__editor {
                    color: var(--av-fixed-primary);
                    text-align: center;
                }

                &__wrapper {
                    padding-top: 8px;
                }
            }
        }

        &__decrease {
            border-inline-end: 1px solid var(--av-brand-light);
        }

        &__increase {
            border-inline-start: 1px solid var(--av-brand-light);
        }

        &__decrease,
        &__increase {
            display: flex;
            flex: 0 1 auto;
            padding: 0 8px;
            user-select: none;
            align-items: center;
            color: var(--av-brand-primary);

            &:hover {
                cursor: pointer;
                background-color: var(--av-brand-accent);
            }

            &:active {
                color: #4d94e0;
                border-color: var(--av-brand-primary);
            }

            &.is-disabled {
                color: var(--av-brand-light);
            }
        }
    }

    &__wrapper {
        padding: 128px 0 0;
        border-bottom: 1px solid var(--av-brand-light);
    }

    &__wrapper-background {
        background: var(--av-gradient-to-top-lightest);
    }

    &__header {
        position: relative;
    }

    &__buttom-addons {
        top: 0;
        inset-inline-end: 0;
        position: absolute;
    }

    &__workload-description-promo-summary {
        @mixin paragraph-accent;
        color: var(--av-fixed-success);
    }

    &__faq {
        border-top: 1px solid var(--av-brand-light);

        .s-slice {
            padding-top: 64px;
        }

        &:deep(.s-faq) {
            &__content {
                display: flex;
                width: 100%;
            }

            &__left {
                width: 100%;
            }

            &__right {
                min-width: 373px;
                margin-inline-start: 16px;
                white-space: nowrap;
            }

            &__text-title {
                @mixin title-accent;
            }

            .a-accordion {
                &__content-wrapper {
                    ul {
                        li {
                            padding-inline-start: 24px;

                            &:before {
                                margin: 0 8px 2px;
                                margin-inline-start: -16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="postcss">
.page-purchasing-acp {
    min-width: 1214px;
}
</style>
