<template>
    <s-basic-slice class="s-values" v-bind="$attrs">
        <div class="s-values__content">
            <ul class="s-values__list" :class="className">
                <li v-for="(card, i) in cards" :key="i" class="s-values__item">
                    <a-value-card v-bind="card" title-tag="h3">
                        {{ card.text }}
                    </a-value-card>
                </li>
            </ul>
            <a-link
                v-if="link"
                class="s-values__link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AValueCard from '@core/components/value-card/value-card.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SValueCards',
    components: {
        ALink,
        AValueCard,
        SBasicSlice,
    },
    inheritAttrs: false,
    props: {
        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 1 && cards.length < 7,
        },

        link: {
            type: [Object, Boolean],
            default: false,
        },
    },
    computed: {
        className() {
            return {
                's-values__list_layout_short': this.cards.length === 2 || this.cards.length === 4,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-values {
    &__list {
        @media (--viewport-mobile-wide) {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 16px;
            margin: -8px -8px -24px;
            width: 100%;
        }

        &_layout_short {
            @media (--viewport-desktop) {
                margin: -8px 0;
                justify-content: space-between;
            }

            @media (--viewport-desktop-wide) {
                width: calc(((100% - 32px) / 3) * 2 + 16px);
            }
        }
    }

    &__item {
        &:not(:first-child) {
            margin-top: 16px;

            @media (--viewport-mobile-wide) {
                margin-top: 8px;
            }
        }

        @media (--viewport-mobile-wide) {
            width: calc(50% - 16px);
            margin: 8px;
        }

        @media (--viewport-desktop) {
            width: calc((100% - 48px) / 3);
        }

        .s-values__list_layout_short & {
            @media (--viewport-desktop) {
                width: calc(50% - 8px);
                margin: 8px 0;
            }
        }
    }

    &__link {
        margin: 56px 0 0;

        @media (--viewport-desktop) {
            margin: 0;
            position: absolute;
            top: 16px;
            inset-inline-end: var(--container-paddings-mobile-wide);
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: var(--container-paddings-desktop-wide);
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: var(--container-paddings-desktop-large);
        }
    }
}
</style>
