<template>
    <section :id="id" class="s-requirements-combo" :class="{ ...className, ...basicClassName }">
        <div v-if="isMobile" class="a-container">
            <h2 v-if="title" class="a-slice-header__title">
                {{ title }}
            </h2>
            <template v-for="(item, index) in slices">
                <s-requirements
                    :key="index"
                    v-bind="item"
                    class="s-requirements-combo__item"
                />
            </template>
        </div>
        <template v-else>
            <div class="a-container">
                <h2 v-if="title" class="a-slice-header__title">
                    {{ title }}
                </h2>
            </div>
            <s-requirements
                v-for="(item, index) in slices"
                v-bind="item"
                :key="index"
                class="s-requirements-combo__item"
                :layout="'row'"
                :columns-count="columnsCount"
            />
        </template>

        <div class="a-container">
            <a-link
                v-if="link"
                class="optional-link"
                v-bind="link"
                :size="link.size || 'title'"
                :type="link.type || 'regular'"
                :glyph="link.glyph || 'arrow'"
                accent
            />
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import Breakpoints from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SRequirements from '@core/slices/pages/requirements/requirements.vue';
import SWrapperSlice from '@core/slices/pages/wrapper-slice/wrapper-slice.vue';

export default {
    name: 'SRequirementsCombo',

    components: {
        ALink,
        SRequirements,
    },

    mixins: [contentMixin, styleMixin, SWrapperSlice, Breakpoints],

    props: {
        slices: {
            type: Array,
            required: true,
        },

        columnsCount: {
            type: Number,
            required: true,
        },

        indent: {
            type: String,
            default: 'small',
        },

        link: {
            type: Object,
            default: undefined,
        },

        innerBorderBottom: {
            type: String,
            default: 'short',
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-requirements-combo {
    @mixin basic-slice-paddings;
    @mixin border-top;
    @mixin border-bottom;

    .a-slice-header__title,
    .optional-link {
        padding-inline-start: var(--container-paddings-mobile);
        padding-inline-end: var(--container-paddings-mobile);

        @media(--viewport-tablet) {
            padding-inline-start: 32px;
        }
    }

    .optional-link {
        margin-top: 56px;
    }

    &:deep(.a-container) {
        padding: 0;

        @media(--viewport-desktop-wide) {
            padding-inline: 32px;
        }

        @media(--viewport-desktop-large) {
            max-width: 1376px;
            padding: 0;
        }
    }

    &:deep(.a-accordion .a-accordion-item) {
        &.active {
            padding: 0;
        }
        .content-wrapper {
            padding: 8px 0;
        }
    }

    .a-slice-header__title {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
    }

    &__accordion {
        .a-requirement:not(:last-child) {
            margin-bottom: 56px;
        }
    }

    &__item {
        padding: 0;

        &:after {
            height: 0;
        }

        &:not(:last-of-type):deep(.a-container) {
            border-bottom: 1px solid var(--av-brand-secondary-accent);
        }

        &:deep(.a-container) {
            padding-block: 56px;
        }

        &:deep(.s-requirements__content) {
            flex-shrink: 1 !important;
        }

        &:deep(.a-slice-header) {
            &__lead {
                @mixin title-accent;
                margin: 0;
            }

            @media (--viewport-desktop) {
                margin-bottom: 0;
            }
        }
    }
}

@mixin indents-borders;
</style>
