<template>
    <div v-if="subscription" class="s-dropdown-subscription">
        <template v-if="list.length > 1">
            <div class="s-dropdown-subscription__title">
                {{ subscription.title }}
            </div>
            <div class="s-dropdown-subscription__wrapper" :class="className">
                <div class="s-dropdown-subscription__selector" @click="open()">
                    <div class="s-dropdown-subscription__selector-title">
                        {{ title() }}
                    </div>
                    <div class="s-dropdown-subscription__price" v-html="text(selected)" />
                    <div class="s-dropdown-subscription__suffix">
                        <a-glyph class="s-dropdown-subscription__glyph" name="chevron-down" />
                    </div>
                </div>
                <div class="s-dropdown-subscription__options">
                    <div v-for="(item, optionIndex) in list" :key="`option-${optionIndex}`" class="s-dropdown-subscription__option">
                        <label class="s-dropdown-subscription__option-label">
                            <input
                                v-model="selectedOption"
                                class="s-dropdown-subscription__option-input"
                                name="subscription"
                                type="radio"
                                :value="optionIndex"
                                @change="setProductPeriod({ productID: product.id, quantity: optionIndex })"
                            />
                            <div class="s-dropdown-subscription__option-title">{{ title(item) }}</div>
                            <div class="s-dropdown-subscription__option-price" v-html="text(optionIndex)" />
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="!hasPromo && selectedOptionLabel" class="s-dropdown-subscription__selector-label">
                {{ selectedOptionLabel }}
            </div>
        </template>
        <template v-else>
            <div class="s-dropdown-subscription__title">
                {{ subscription.title }}
            </div>
            <div class="s-dropdown-subscription__description" v-html="selectorDescription" />
        </template>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SDropdownSubscription',

    components: {
        AGlyph,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        isActive: false,
        selectedOption: 0,
        hasPromo: false,
        pricePerYear: 0,
    }),

    computed: {
        className() {
            return {
                's-dropdown-subscription__opened': this.isActive,
            };
        },

        cloud() {
            return this.product.cloud;
        },

        cloudSelected() {
            return this.product.selectedCloud || 0;
        },

        subscription() {
            return this.product.subscriptions;
        },

        type() {
            return this.subscription.isAdvanced ? 'advanced' : 'standard';
        },

        list() {
            return this.subscription[this.type];
        },

        selected() {
            return this.product?.selectedPeriod || 0;
        },

        selectedOptionLabel() {
            return this.dictionary?.subscriptionTitle?.long?.[this.selected]?.label || '';
        },

        selectorDescription() {
            const price = this.setCurrency(this.text(this.selected));
            return this.subscription?.description?.replace('$XX.XX', price);
        },
    },

    mounted() {
        this.selectedOption = this.product.selectedPeriod;
    },

    methods: {
        open() {
            this.isActive = true;
            window.addEventListener('click', this.close, true);
        },

        close() {
            this.isActive = false;
            window.removeEventListener('click', this.close, true);
        },

        text(i) {
            const cloud = this.cloud[this.type];
            const cleverBridgeCartID = cloud[i][this.cloudSelected].cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });
            const priceCurrent = license.price || 0;

            if (i === 0) {
                this.pricePerYear = priceCurrent;
            }

            let priceFull = this.pricePerYear * this.list[i].period;

            if (this.hasLicensePromo({ cleverBridgeCartID: license.cleverbridge_id, promo: this.settings.promo })) {
                this.hasPromo = true;
                priceFull = priceCurrent + license.discount_absolute;
            }

            const text = this.settings?.promo?.label || `${this.setTranslate('Save XX%')}`;
            const percent = Math.round((100 * (priceFull - priceCurrent)) / priceFull);
            const label = this.hasPromo || i ? `<span class="label">${text.replace('XX', percent)}</span>` : '';
            const discount = this.hasPromo || i ? `<span class="discount">${this.setCurrency(priceFull)}</span>` : '';
            const price = `<span>${this.setCurrency(priceCurrent)}</span>`;

            return `${label} ${discount} ${price}`;
        },

        title(current) {
            const period = this.list[this.selected].period || 0;
            const comparator = (i) => i.period === (current?.period || period);
            const result = this.dictionary.subscriptionTitle.short.find(comparator);
            return result.title;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-dropdown-subscription {
    &:not(:first-child) {
        width: 100%;
        margin-inline-start: 24px;
    }

    &__title {
        @mixin body;
        color: var(--av-fixed-secondary);
    }

    &__wrapper {
        height: 40px;
        border-radius: 4px;
        position: relative;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-light);
    }

    &__selector {
        display: flex;
        padding: 7px 0;
        cursor: pointer;
        flex-wrap: nowrap;
        align-items: flex-start;
        color: var(--av-fixed-primary);
        justify-content: space-between;

        &-title {
            @mixin paragraph-accent;
            white-space: nowrap;
            padding: 0 16px;
        }
    }

    &__price {
        @mixin body;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: var(--av-fixed-secondary);
    }

    &__suffix {
        @mixin paragraph;
        padding-inline-end: 11px;
        padding-inline-start: 8px;

        .a-glyph {
            margin: 0 0 2px;
            transition: none;
            vertical-align: middle;
            fill: var(--av-brand-primary);
        }
    }

    &__selector-label {
        @mixin paragraph-accent;
        color: var(--av-fixed-success);
        overflow: hidden;
        max-width: 364px;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (--viewport-desktop-large) {
            max-width: 440px;
        }
    }

    &__options {
        z-index: 2;
        display: none;
        padding: 8px 0;
        margin: 0 -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--av-brand-primary);
        border-top: 1px solid var(--av-brand-primary);
    }

    &__option {
        position: relative;

        &:hover {
            background: var(--av-brand-secondary-accent);
        }

        &-title {
            @mixin paragraph-accent;
            white-space: nowrap;
            padding-inline-end: 8px;
            padding-inline-start: 16px;
        }

        &-price {
            @mixin body;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            color: var(--av-fixed-secondary);
        }

        &-label {
            width: 100%;
            display: flex;
            cursor: pointer;
            padding: 8px 0;
            padding-inline-end: 34px;
            justify-content: space-between;
        }

        &-input {
            top: 0;
            inset-inline-start: 0;
            opacity: 0;
            width: 1px;
            height: 1px;
            border: none;
            position: absolute;
        }
    }

    &__opened {
        border-radius: 4px 4px 0 0;
        border-color: var(--av-brand-primary);

        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .s-dropdown-subscription {
            &__selector {
                cursor: default;
            }

            &__suffix {
                .a-glyph {
                    transform: rotate(180deg);
                }
            }

            &__options {
                inset-inline-start: 0;
                top: 100%;
                display: block;
                position: absolute;
                background: var(--av-inversed-primary);
            }
        }
    }

    &:deep(.label) {
        @mixin paragraph-accent;
        width: 100%;
        overflow: hidden;
        max-width: 108px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--av-fixed-success);

        @media (--viewport-desktop-large) {
            min-width: 140px;
        }
    }

    &:deep(.discount) {
        width: 100%;
        text-align: end;
        font-weight: 400;
        margin-inline-end: 8px;
        margin-inline-start: 8px;
        color: var(--av-fixed-lighter);
        text-decoration: line-through;
    }
}
</style>
