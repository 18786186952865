/* eslint-disable max-len */
import { MOCK_SMALL_CARD_COMPANY } from '@core/components/small-card/mocks.js';

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=52463-66130&t=LWcbKICEQRRYVaDf-4';

export const title = 'Partner benefits';
export const lead = 'As you grow your partnership with Acronis, you’ll unlock additional perks. Gain access to special benefits and programs designed to delight your customers and help your business thrive.';

export const Default = {
    name: 'Default',
    args: {
        title,
        lead,
        leadIsVisible: true,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 12 }).map((_, i) => ({
            ...MOCK_SMALL_CARD_COMPANY,
            to: i % 3 === 0 ? '#' : null,
            icon: '149',
            iconAlt: 'Icon Title',
            iconVariant: true,
            logo: '@71003d4ee3c6ac84146db1bf58873776',
            logoAlt: 'Logo Alt',
            image: '@3ebd5147cf98e12eea516427eed4aaa0',
            imageAlt: 'Image Alt',
            rate: [
                { points: 1, title: 'Financial' },
                { points: 2, title: 'Marketing' },
                { points: 3, title: 'Enablement' },
                { points: 4, title: 'Support' },
            ],
        })),
        link: { text: 'Learn more', to: '#' },
        downloadFileArr: [
            {
                title: 'Security at Acronis',
                desc: 'PDF, 0.9 MB',
                link: '#',
            },
        ],
    },
};

export const Icon = {
    args: {
        title,
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            ...MOCK_SMALL_CARD_COMPANY,
            company: null,
            labels: null,
            to: i % 3 === 0 ? '#' : null,
            icon: (76 + i).toString(),
        })),
    },
};

export const Image = {
    args: {
        title: 'Partner benefits',
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            title: 'Backup as a Service',
            text: '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
            to: i % 3 === 0 ? '#' : null,
            image: '/images/small-card/card-01',
            imageAlt: 'Alt',
        })),
    },
};

export const Logo = {
    args: {
        title: 'Partner benefits',
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            title: 'Backup as a Service',
            text: '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
            to: i % 3 === 0 ? '#' : null,
            logo: '/images/logo/microsoft',
        })),
    },
};

export const Rate = {
    args: {
        title,
        lead,
        leadIsVisible: true,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: true,
        cards: [
            {
                title: 'Registered',
                text: 'Start by unlocking immediate discounts, training, and solution and technical support',
                rate: [
                    { points: 2, title: 'Financial' },
                    { points: 2, title: 'Marketing' },
                    { points: 2, title: 'Enablement' },
                    { points: 2, title: 'Support' },
                ],
            },
            {
                title: 'Authorized',
                text: 'All of the benefits of a Registered Partner plus deeper discounts, marketing automation tools, a dedicated partner account manager, and more',
                rate: [
                    { points: 3, title: 'Financial' },
                    { points: 3, title: 'Marketing' },
                    { points: 3, title: 'Enablement' },
                    { points: 3, title: 'Support' },
                ],
            },
            {
                title: 'Gold',
                text: 'All of the benefits of an Authorized Partner, with bigger discounts, virtual credits, access to the MDF program, sports benefits, and more',
                rate: [
                    { points: 4, title: 'Financial' },
                    { points: 4, title: 'Marketing' },
                    { points: 4, title: 'Enablement' },
                    { points: 4, title: 'Support' },
                ],
            },
            {
                title: 'Platinum',
                text: 'Full-service from top to bottom with the best discounts, enhanced support, and a dedicated team',
                rate: [
                    { points: 5, title: 'Financial' },
                    { points: 5, title: 'Marketing' },
                    { points: 5, title: 'Enablement' },
                    { points: 5, title: 'Support' },
                ],
            },
        ],
        link: { text: 'Learn more', to: '#' },
    },
};

export const Company = {
    args: {
        title,
        lead,
        leadIsVisible: true,
        isCompanyLayout: true,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 4 }).map(() => ({
            ...MOCK_SMALL_CARD_COMPANY,
            title: 'Backup as a Service',
            text: '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
        })),
        link: { text: 'Learn more', to: '#' },
    },
};
