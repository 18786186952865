<template>
    <section id="price-calculator">
        <transition name="vroi-fade-in">
            <div v-if="cartBackdropVisible" class="backdrop" />
        </transition>
        <div
            v-if="isLoading && !greetModalShown && !gated"
            id="loading"
            v-loading="true"
            el-loading-size="48"
        >
            <span v-html="loadingText" />
        </div>
        <a-picture v-if="gated || greetModalShown || isServerError" :link="gatedBackground" class="gated-image" />
        <div v-if="!isLoading && !gated" class="a-container">
            <div id="main-content" class="main">
                <div id="content" class="content">
                    <section class="main-section">
                        <h1 v-if="sections.main.title" class="title">
                            {{ sections.main.title }}
                        </h1>
                        <p v-if="sections.main.lead" class="lead" v-html="sections.main.lead" />
                    </section>
                    <s-main-section
                        id="cloud"
                        :section="sections.cloud"
                        :requirements="cartRequirements"
                    >
                        <template #numberOptions>
                            <a-number-input
                                v-for="(numberInput, index) in cloudSectionNumberInputs"
                                :key="`cloud_section_number_inputs_${index}`"
                                :field="findField(numberInput)"
                                @change="changeCloudInputs(numberInput, $event)"
                            />
                            <a-select-input
                                v-if="hasMsSeats"
                                :field="findField('cloudMsStorage')"
                                has-input
                                :trigger-error="!cartRequirements.msStorage"
                                @change="changeInput('cloudMsStorage', $event)"
                            />
                            <a-select-input
                                v-if="hasGoogleSeats"
                                :field="findField('cloudGoogleWorkspaceStorage')"
                                has-input
                                :trigger-error="!cartRequirements.googleStorage"
                                @change="changeInput('cloudGoogleWorkspaceStorage', $event)"
                            />
                        </template>
                        <template #selectOptions>
                            <a-select-input
                                v-bind="labelSelect"
                                filterable
                                :required="true"
                                :field="findField('dataCenterInput')"
                                :trigger-error="!cartRequirements.dataCenter"
                                :default="preselectDataCenter()"
                                @change="changeInput('globalsDataCenter', $event)"
                            >
                                <el-option
                                    v-for="item in dataCenters"
                                    :key="item.code"
                                    :value="item.code"
                                    :label="item.name"
                                />
                            </a-select-input>
                            <a-select-input
                                :required="true"
                                :default="form.cloudStorageOption"
                                :field="findField('cloudStorageOption')"
                                @change="changeInput('cloudStorageOption', $event)"
                            />
                            <template v-if="individualStorageOption">
                                <a-select-input
                                    ref="storageAmountInput"
                                    :key="individualStorageOption"
                                    :field="findField('cloudStorageAmount')"
                                    :trigger-error="!cartRequirements.storage"
                                    has-input
                                    @change="changeStorageOptionValue(individualStorageOption, $event)"
                                />
                            </template>
                        </template>
                    </s-main-section>
                    <div v-if="addons" class="addons">
                        <h3 class="title">
                            {{ addons.title }}
                        </h3>
                        <p v-if="addons.lead" class="lead" v-html="addons.lead" />
                    </div>
                    <s-collapse-section
                        id="backup"
                        :section="sections.backup"
                        @collapseToggled="onCollapse($event)"
                    >
                        <template #numberOptions>
                            <a-number-input
                                v-for="(numberInput, index) in backupSectionNumberInputs"
                                :ref="`backupInputs_${numberInput.field}`"
                                :key="`backup_section_number_inputs_${index}`"
                                :field="findField(numberInput.field)"
                                :max="numberInput.max"
                                :disabled="numberInput.max === 0"
                                @change="changeInput(numberInput.field, $event)"
                            />
                        </template>
                    </s-collapse-section>
                    <s-collapse-section
                        id="security"
                        :section="sections.security"
                        @collapseToggled="onCollapse($event)"
                    >
                        <template #numberOptions>
                            <a-number-input
                                :field="findField('securityWorkloads')"
                                @change="changeInput('securityWorkloads', $event)"
                            />
                        </template>
                    </s-collapse-section>
                    <s-collapse-section
                        id="emailSecurity"
                        :section="sections.emailSecurity"
                        @collapseToggled="onCollapse($event)"
                    >
                        <template #numberOptions>
                            <a-number-input
                                :field="findField('emailSecurityMailboxes')"
                                @change="changeInput('emailSecurityMailboxes', $event)"
                            />
                        </template>
                    </s-collapse-section>
                    <s-collapse-section
                        id="management"
                        :section="sections.management"
                        @collapseToggled="onCollapse($event)"
                    >
                        <template #numberOptions>
                            <a-number-input
                                :field="findField('managementWorkloads')"
                                @change="changeInput('managementWorkloads', $event)"
                            />
                        </template>
                    </s-collapse-section>
                    <s-collapse-section
                        id="additional"
                        :section="sections.additional"
                        @collapseToggled="onCollapse($event)"
                    >
                        <template #additional>
                            <div v-if="sections.additional.options" class="additional-inputs">
                                <a-additional-input
                                    v-for="additionalInput in additionalInputs"
                                    :key="`additional_inputs_${additionalInput}`"
                                    class="additional-input"
                                    :labels="sections.additional.labels"
                                    :title="sections.additional.options[additionalInput].title"
                                    :description="sections.additional.options[additionalInput].description"
                                    @change="changeAdditional(additionalInput, $event)"
                                />
                            </div>
                        </template>
                    </s-collapse-section>
                    <div
                        v-if="bottomContact"
                        class="bottom-contact"
                        @click="contactAdvisor($event)"
                        v-html="bottomLinkFormatted"
                    />
                </div>
                <div id="sidebar" class="sidebar">
                    <div class="sidebar__inner">
                        <transition name="vroi-slide-up" appear mode="out-in">
                            <section
                                v-if="!requirementsMet"
                                key="validation"
                                class="validation"
                                :class="{ mobile: isMobile }"
                            >
                                <div class="not-filled">
                                    <h5 v-if="!isMobile && cart.notFilled.title" class="title">
                                        {{ cart.notFilled.title }}
                                    </h5>
                                    <div v-if="isMobile" class="mobileTitle">
                                        <h5 v-if="cart.notFilled.title">
                                            {{ cart.notFilled.title }} {{ requirementsCounter }}
                                        </h5>
                                        <a data-has-no-href="true" @click="toggleExpandedCart()">
                                            <a-glyph :name="expandedCart ? 'chevron-down' : 'chevron-up'" class="icon" />
                                        </a>
                                    </div>
                                    <ul v-if="!isMobile || showOnMobile" class="requirements">
                                        <li class="requirement" :class="{'checked': cartRequirements.workloads }">
                                            <a-glyph :name="cartRequirements.workloads ? 'check-square' : 'bullet'" />
                                            {{ cart.notFilled.requirements.workloads }}
                                        </li>
                                        <li
                                            v-if="showMsStorage"
                                            class="requirement"
                                            :class="{'checked': cartRequirements.msStorage }"
                                        >
                                            <a-glyph :name="cartRequirements.msStorage ? 'check-square' : 'bullet'" />
                                            {{ cart.notFilled.requirements.msStorage }}
                                        </li>
                                        <li
                                            v-if="showGoogleStorage"
                                            class="requirement"
                                            :class="{'checked': cartRequirements.googleStorage }"
                                        >
                                            <a-glyph :name="cartRequirements.googleStorage ? 'check-square' : 'bullet'" />
                                            {{ cart.notFilled.requirements.googleStorage }}
                                        </li>
                                        <li class="requirement" :class="{'checked': cartRequirements.dataCenter }">
                                            <a-glyph :name="cartRequirements.dataCenter ? 'check-square' : 'bullet'" />
                                            {{ cart.notFilled.requirements.dataCenter }}
                                        </li>
                                        <li class="requirement" :class="{'checked': cartRequirements.storage }">
                                            <a-glyph :name="cartRequirements.storage ? 'check-square' : 'bullet'" />
                                            {{ cart.notFilled.requirements.storage }}
                                        </li>
                                    </ul>
                                    <p
                                        v-if="!isMobile || showOnMobile"
                                        class="assistance"
                                        @click="contactAdvisor($event)"
                                        v-html="assistanceLinkFormatted"
                                    />
                                </div>
                            </section>
                            <!-- Not Filled Cart END -->
                            <!-- Qualified for discount: When requirements are met and sum is above 900 -->
                            <section v-if="requirementsMet && isDiscount" key="discount" class="discount">
                                <div class="discount-wrapper">
                                    <a-picture v-if="!isMobile && cart.discount.image" :link="cart.discount.image" class="image" />
                                    <h5 v-if="!isMobile && cart.discount.title" class="title">
                                        {{ cart.discount.title }}
                                    </h5>
                                    <div v-if="isMobile" class="mobileTitle">
                                        <a-picture v-if="cart.discount.image" :link="cart.discount.image" class="image" />
                                        <h5 v-if="cart.discount.title">
                                            {{ cart.discount.title }}
                                        </h5>
                                        <a data-has-no-href="true" @click="toggleExpandedCart()">
                                            <a-glyph :name="expandedCart ? 'chevron-down' : 'chevron-up'" class="icon" />
                                        </a>
                                    </div>
                                    <template v-if="!isMobile || showOnMobile">
                                        <p
                                            v-for="(desc, index) in cart.discount.description_blocks"
                                            :key="`cart_discount_description_${index}`"
                                            class="description"
                                            v-html="desc"
                                        />
                                    </template>
                                    <a-button
                                        v-if="cart.discount.button"
                                        class="button"
                                        v-bind="cart.discount.button"
                                        @click="contactAdvisor($event)"
                                    />
                                </div>
                            </section>
                            <!-- Qualified for discount END -->
                            <!-- Cart when requirements are met and pricing is below 900 -->
                            <section v-if="requirementsMet && !isDiscount" key="cartContent" class="cart-content">
                                <div v-if="isMobile" class="mobileOrder">
                                    <div class="label">
                                        <span>{{ cart.labels?.total }}</span>
                                        <span class="perMonth">{{ monthlyTotal.formatted }}</span>
                                        <a-tooltip
                                            v-if="cart.labels?.taxes"
                                            :text="cart.labels?.taxes"
                                            placement="top"
                                        >
                                            <a-glyph name="tooltip-o" class="tooltip-icon" />
                                        </a-tooltip>
                                        <a data-has-no-href="true" class="expand" @click="toggleExpandedCart()">
                                            <a-glyph :name="expandedCart ? 'chevron-down' : 'chevron-up'" class="icon" />
                                        </a>
                                    </div>
                                    <a-button
                                        v-if="cart.order?.button"
                                        v-bind="cart.order.button"
                                        class="button"
                                        @click="contactAdvisor($event)"
                                    />
                                    <a-link
                                        v-if="cart.order?.link"
                                        v-bind="cart.order.link"
                                        class="link"
                                        :size="cart.order.link?.size || 'paragraph'"
                                        target="_blank"
                                    />
                                </div>

                                <div v-if="!isMobile || showOnMobile" class="pricing" :class="{mobile: isMobile}">
                                    <h4 v-if="cart.title" class="main-title">
                                        {{ cart.title }}
                                    </h4>
                                    <div class="licensing-model">
                                        <div class="head">
                                            <h4 class="title">
                                                {{ cart.pricingMethods.title }}
                                            </h4>
                                            <a-tooltip
                                                v-if="cart.pricingMethods.tooltip"
                                                :text="cart.pricingMethods.tooltip"
                                                placement="top"
                                            >
                                                <a-glyph name="tooltip-o" class="tooltip-icon" />
                                            </a-tooltip>
                                        </div>
                                        <div class="pricing-methods">
                                            <button
                                                v-for="(method, index) in cart.pricingMethods.items"
                                                :key="`pricing_method_${index}`"
                                                class="method"
                                                :class="{
                                                    'selected': pricingMethod === method.id,
                                                    'recommended': recommendedPricingMethod === method.id
                                                }"
                                                @click="changePricingMethod(method.id, true)"
                                            >
                                                <a-glyph
                                                    v-if="pricingMethod === method.id"
                                                    :name="selectedPricingMethod[pricingState].glyph"
                                                    class="icon"
                                                />
                                                <span>{{ method.title }}</span>
                                            </button>
                                        </div>
                                        <span
                                            v-if="recommendedPricingMethod !== selectedPricingMethod.id"
                                            class="pricing-recommendation"
                                            v-html="selectedPricingMethod[pricingState].message"
                                        />
                                    </div>
                                    <div class="cart-line" />
                                    <div class="order" :class="{compact: !detailedSummary}">
                                        <div v-if="showOrderGroup('cloud')" class="order-group">
                                            <div class="head">
                                                <h5 v-if="cart.labels.productTitles.cloud">
                                                    {{ cart.labels.productTitles.cloud }}
                                                </h5>
                                                <span>
                                                    {{ formatPrice(allOutputs.cloud) }}
                                                </span>
                                            </div>
                                            <div v-show="detailedSummary" class="options">
                                                <template v-for="orderLine in cloudOrderLineFields">
                                                    <div
                                                        v-if="showOrderItem(orderLine.reference)"
                                                        :key="orderLine.reference"
                                                        class="order-item"
                                                    >
                                                        <h5>{{ getOrderItemQty(orderLine.reference) }}</h5>
                                                        <span v-if="orderLine.item">{{ formatPrice(orderLine.item) }}</span>
                                                        <span v-else>{{ cart.labels.freeLabel }}</span>
                                                    </div>
                                                </template>
                                                <template v-for="orderLine in cloudStorageOrderLineFields">
                                                    <div
                                                        v-if="showOrderItem(orderLine.reference)"
                                                        :key="orderLine.reference"
                                                        class="order-item"
                                                    >
                                                        <h5>{{ getOrderItemQty(orderLine.reference, orderLine.suffix) }}</h5>
                                                        <span v-if="orderLine.item">{{ formatPrice(orderLine.item) }}</span>
                                                        <span v-else>{{ cart.labels.freeLabel }}</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <!-- Section Cloud END / Section Backup Start -->
                                        <div v-if="showOrderGroup('backup')" class="order-group">
                                            <div class="head">
                                                <h5 v-if="cart.labels.productTitles.backup">
                                                    {{ cart.labels.productTitles.backup }}
                                                </h5>
                                                <span>{{ formatPrice(allOutputs.backup) }}</span>
                                            </div>
                                            <div v-show="detailedSummary" class="options">
                                                <template v-for="orderLine in backupOrderLineFields">
                                                    <div
                                                        v-if="showOrderItem(orderLine.reference)"
                                                        :key="orderLine.reference"
                                                        class="order-item"
                                                    >
                                                        <h5>{{ getOrderItemQty(orderLine.reference) }}</h5>
                                                        <span v-if="orderLine.item">{{ formatPrice(orderLine.item) }}</span>
                                                        <span v-else>{{ cart.labels.freeLabel }}</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div v-if="showOrderGroup('security')" class="order-group">
                                            <div class="head">
                                                <h5 v-if="cart.labels.productTitles.security">
                                                    {{ cart.labels.productTitles.security }}
                                                </h5>
                                                <span>{{ formatPrice(allOutputs.security) }}</span>
                                            </div>
                                            <div v-show="detailedSummary" class="options">
                                                <div v-if="showOrderItem('securityWorkloads')" class="order-item">
                                                    <h5>{{ getOrderItemQty('securityWorkloads') }}</h5>
                                                    <span>
                                                        {{ formatPrice(allOutputs.securityWorkloads) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="showOrderGroup('emailSecurity')" class="order-group">
                                            <div class="head">
                                                <h5 v-if="cart.labels.productTitles.emailSecurity">
                                                    {{ cart.labels.productTitles.emailSecurity }}
                                                </h5>
                                                <span>{{ formatPrice(allOutputs.emailSecurity) }}</span>
                                            </div>
                                            <div v-show="detailedSummary" class="options">
                                                <div v-if="showOrderItem('emailSecurityMailboxes')" class="order-item">
                                                    <h5>{{ getOrderItemQty('emailSecurityMailboxes') }}</h5>
                                                    <span>
                                                        {{ formatPrice(allOutputs.emailSecurityMailboxes) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="showOrderGroup('management')" class="order-group">
                                            <div class="head">
                                                <h5 v-if="cart.labels.productTitles.management">
                                                    {{ cart.labels.productTitles.management }}
                                                </h5>
                                                <span>{{ formatPrice(allOutputs.management) }}</span>
                                            </div>
                                            <div v-show="detailedSummary" class="options">
                                                <div v-if="showOrderItem('managementWorkloads')" class="order-item">
                                                    <h5>{{ getOrderItemQty('managementWorkloads') }}</h5>
                                                    <span>
                                                        {{ formatPrice(allOutputs.managementWorkloads) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="minimumCommitAdjustment" class="order-group">
                                            <div class="head">
                                                <h5 v-if="cart.labels.minimumCommitAdjustment">
                                                    {{ cart.labels.minimumCommitAdjustment }}
                                                </h5>
                                                <span>{{ formatPrice(minimumCommitAdjustment) }}</span>
                                            </div>
                                            <div class="minimum-warning">
                                                <div class="icon-wrapper">
                                                    <a-glyph name="warning-o" class="icon" />
                                                </div>
                                                <div class="text">
                                                    <span
                                                        v-if="cart.labels.minimumWarning"
                                                        v-html="minimumWarningComputed"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="detailedSummary" class="cart-line short" />
                                    <a
                                        v-if="detailedSummary && !pdfUrl"
                                        :class="{'in-progress': pdfInProgress}"
                                        class="download-detailed-summary"
                                        :data-has-no-href="true"
                                        @click="exportPdf()"
                                    >
                                        <a-glyph
                                            name="pdf"
                                            class="icon"
                                            :class="{ animate: pdfInProgress }"
                                        />
                                        <span v-if="!pdfError">{{ pdfTitle }}</span>
                                        <span v-else>{{ cart.labels?.export.error }}</span>
                                    </a>
                                    <a
                                        v-if="detailedSummary && pdfUrl"
                                        ref="exportButton"
                                        class="download-detailed-summary"
                                        :href="pdfUrl"
                                        @click="downloadPdfClick()"
                                    >
                                        <a-glyph name="download-arrow" class="icon" />
                                        <span>{{ cart.labels?.export.download }}</span>
                                    </a>
                                    <a
                                        class="toggle-detailed-summary"
                                        :data-has-no-href="true"
                                        :class="{active: !detailedSummary}"
                                        @click="toggleDetailedSummary()"
                                    >
                                        <a-glyph :name="`chevron-${detailedSummary ? 'up' : 'down'}`" />
                                        <span>{{ detailedSummaryTitle }}</span>
                                    </a>
                                    <div v-if="!isMobile" class="cart-line" />
                                    <div v-if="!isMobile" class="totals">
                                        <div class="label">
                                            {{ cart.labels?.total }}
                                        </div>
                                        <div class="sums">
                                            <span class="perMonth">{{ monthlyTotal.formatted }}</span>
                                            <p class="taxes">
                                                {{ cart.labels?.taxes }}
                                            </p>
                                        </div>
                                    </div>
                                    <div v-if="!isMobile" class="actions">
                                        <a-button
                                            v-if="cart.order?.button"
                                            v-bind="cart.order.button"
                                            class="button"
                                            @click="contactAdvisor($event)"
                                        />
                                        <a-link
                                            v-if="cart.order?.link"
                                            v-bind="cart.order.link"
                                            class="link"
                                            :size="cart.order.link?.size || 'paragraph'"
                                            target="_blank"
                                        />
                                    </div>
                                </div>
                            </section>
                            <!-- Cart when requirements are met END -->
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modals Start -->
        <s-error-modal
            v-if="isError || isServerError"
            :server-error="isServerError"
            :labels="computedErrorModal"
            @onClose="errorModalClosed()"
        />
        <s-contact-modal
            v-if="contactModalShown"
            :labels="contactModal"
            @onClose="contactModalShown = false"
        />
        <s-greet-modal
            v-if="greetModalShown"
            :is-loading="isLoading"
            :content="greetModal"
            :name="userData.first_name"
            @onClose="greetModalClosed()"
        />
        <s-gated-form v-if="gated" @gatedFormSubmit="gatedFormSubmit($event)" />
        <!-- Modals End -->
    </section>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { mapState } from 'vuex';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import currency from '@core/mixins/currency.js';
import form from '@core/mixins/form.js';
/* VROI Specific imports */
import AAdditionalInput from './components/additional-input.vue';
import SCollapseSection from './components/collapse-section.vue';
import SContactModal from './components/contact-modal.vue';
import SErrorModal from './components/error-modal.vue';
import SGatedForm from './components/gated-form.vue';
import SGreetModal from './components/greet-modal.vue';
import SMainSection from './components/main-section.vue';
import ANumberInput from './components/number-input.vue';
import ASelectInput from './components/select-input.vue';
import calculations from './utils/calculations.js';
import helpers from './utils/helpers.js';
import user from './utils/user.js';

export default {
    name: 'SPriceCalculator',
    components: {
        ALink,
        APicture,
        AButton,
        AGlyph,
        AAdditionalInput,
        ANumberInput,
        ATooltip,
        ASelectInput,
        SMainSection,
        SCollapseSection,
        SGatedForm,
        SContactModal,
        SErrorModal,
        SGreetModal,
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },
    directives: {
        Loading,
    },
    mixins: [calculations, helpers, user, breakpoint, currency, form],
    props: {
        sections: {
            type: Object,
            required: true,
        },
        additional: {
            type: Object,
            default: null,
        },
        contactButton: {
            type: Object,
            default: null,
        },
        bottomContact: {
            type: Object,
            default: null,
        },
        loadingText: {
            type: String,
            default: 'Please wait..<br/>While we prepare the calculator',
        },
        storage: {
            type: Object,
            default: null,
        },
        cart: {
            type: Object,
            default: null,
        },
        addons: {
            type: Object,
            default: null,
        },
        errorModal: {
            type: Object,
            default: null,
        },
        serverErrorModal: {
            type: Object,
            default: null,
        },
        greetModal: {
            type: Object,
            default: null,
        },
        contactModal: {
            type: Object,
            default: null,
        },
        gatedBackground: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            timer: null,
            resizeTimer: null,
            sidebar: null,
            isLoading: true,
            isClient: false,
            isError: false,
            isServerError: false,
        };
    },
    computed: {
        ...mapState({
            fields: (state) => state.vroi.items?.['s-vroi-fields'] || [],
            dataCenters: (state) => state.vroi.items?.['s-vroi-data-centers'] || [],
            productCodes: (state) => state.vroi.items?.['s-vroi-product-codes'] || {},
            settings: (state) => state.vroi.items?.['s-vroi-settings'] || {},
            currency: (state) => state.vroi.currency || 'USD',
            pricingMethod: (state) => state.vroi.pricingMethod || 1,
            group: (state) => state.vroi?.group,
            minimumCommit: (state) => state.vroi.minimumCommit || 250,
            discountAfter: (state) => state.vroi.discountAfter || 900,
            currentPriceList: (state) => state.vroi.currentPriceList,
        }),
        isMobile() {
            return ['mobile', 'mobileWide', 'tablet'].includes(this.currentBreakpoint);
        },
        computedErrorModal() {
            if (this.isServerError) {
                return this.serverErrorModal || this.errorModal;
            }
            return this.errorModal;
        },
        cloudSectionNumberInputs() {
            return [
                'cloudPhysicalServers',
                'cloudVirtualMachines',
                'cloudWorkstations',
                'cloudHostingServers',
                'cloudVmDirector',
                'cloudGoogleWorkspaceSeats',
                'cloudMobileDevices',
                'cloudWebsites',
                'cloudMsSeats',
            ];
        },
        cloudOrderLineFields() {
            return [
                { item: this.allOutputs.cloudPhysicalServers, reference: 'cloudPhysicalServers' },
                { item: this.allOutputs.cloudVirtualMachines, reference: 'cloudVirtualMachines' },
                { item: this.allOutputs.cloudWorkstations, reference: 'cloudWorkstations' },
                { item: this.allOutputs.cloudHostingServers, reference: 'cloudHostingServers' },
                { item: this.allOutputs.cloudVmDirector, reference: 'cloudVmDirector' },
                { item: this.allOutputs.cloudGoogleWorkspaceSeats, reference: 'cloudGoogleWorkspaceSeats' },
                { item: this.allOutputs.cloudMobileDevices, reference: 'cloudMobileDevices' },
                { item: this.allOutputs.cloudWebsites, reference: 'cloudWebsites' },
                { item: this.allOutputs.cloudMsSeats, reference: 'cloudMsSeats' },
            ];
        },
        cloudStorageOrderLineFields() {
            return [
                {
                    item: this.allOutputs.cloudMsStorage,
                    reference: 'cloudMsStorage',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudGoogleWorkspaceStorage,
                    reference: 'cloudGoogleWorkspaceStorage',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageAcronisCloud,
                    reference: 'cloudStorageAcronisCloud',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageGoogleAzure,
                    reference: 'cloudStorageGoogleAzure',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageThirdParty,
                    reference: 'cloudStorageThirdParty',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageLocal,
                    reference: 'cloudStorageLocal',
                    suffix: 'GB',
                },
            ];
        },
        backupOrderLineFields() {
            return [
                { item: this.allOutputs.backupPhysicalServers, reference: 'backupPhysicalServers' },
                { item: this.allOutputs.backupVirtualMachines, reference: 'backupVirtualMachines' },
                { item: this.allOutputs.backupWorkstations, reference: 'backupWorkstations' },
                { item: this.allOutputs.backupHostingServers, reference: 'backupHostingServers' },
            ];
        },
        individualStorageOption() {
            switch (this.form.cloudStorageOption) {
                case 1:
                    return 'cloudStorageAcronisCloud';
                case 2:
                    return 'cloudStorageGoogleAzure';
                case 3:
                    return 'cloudStorageThirdParty';
                case 4:
                    return 'cloudStorageLocal';
                default:
                    return false;
            }
        },
        backupSectionNumberInputs() {
            return [
                { field: 'backupPhysicalServers', max: this.form.cloudPhysicalServers },
                { field: 'backupVirtualMachines', max: this.form.cloudVirtualMachines },
                { field: 'backupWorkstations', max: this.form.cloudWorkstations },
                { field: 'backupHostingServers', max: this.form.cloudHostingServers },
            ];
        },
        additionalInputs() {
            return ['disasterRecovery', 'dlp'];
        },
    },
    beforeDestroy() {
        clearTimeout(this.timer);
        clearTimeout(this.resizeTimer);
        document.removeEventListener('visibilitychange', this.visibilityHiddenHandler);
    },
    async mounted() {
        document.addEventListener('visibilitychange', this.visibilityHiddenHandler);
        await import('sticky-sidebar');
        document.body.classList.add('price-calculator');
        await this.init();
        this.isLoading = false;
    },
    methods: {
        async init(initialCall = true) {
            if (initialCall) this.adjustPriceList();
            await this.$nextTick();
            const productCodes = this.fetchProductCodesForPriceBook();
            await this.$store.dispatch('vroi/getPricesForPriceBook', productCodes);
            this.calculate();
            if (initialCall) await this.initSidebar();
        },
        changeCloudInputs(reference, value) {
            this.changeInput(reference, value);
            this.checkBackupInputsBounds();
        },
        checkBackupInputsBounds() {
            const backupInputs = ['backupPhysicalServers', 'backupVirtualMachines', 'backupWorkstations', 'backupHostingServers'];
            const cloudInputs = ['cloudPhysicalServers', 'cloudVirtualMachines', 'cloudWorkstations', 'cloudHostingServers'];

            backupInputs.forEach((field, index) => {
                const cloudInputValue = this.form[cloudInputs[index]];
                if (!this.$refs[`backupInputs_${field}`]) return;
                const isOutOfBounds = this.$refs[`backupInputs_${field}`][0];
                if (cloudInputValue >= this.form[field] || !isOutOfBounds) return;

                isOutOfBounds.value = cloudInputValue;
                this.changeInput(field, cloudInputValue);
            });
        },
        checkSidebar() {
            if (!this.sidebar) return;

            if (this.isMobile) {
                this.sidebar.destroy();
                return;
            }

            this.sidebar.initialize();
        },
        async initSidebar() {
            await this.$nextTick();
            try {
                this.sidebar = new window.StickySidebar('#sidebar', {
                    containerSelector: '#main-content',
                    innerWrapperSelector: '.sidebar__inner',
                    topSpacing: 16,
                    bottomSpacing: -56,
                });

                if (!this.isMobile) return this.sidebar.initialize();
                return this.sidebar.destroy();
            } catch {
                // Empty return to avoid console errors
                return false;
            }
        },
        async visibilityHiddenHandler() {
            if (document.visibilityState !== 'hidden') return;
            if (!this.userData.osauid) return;

            await this.updateOrCreateUser();
        },
        changeInput(reference, value, calculate = true) {
            this.pdfUrl = null;
            this.pdfError = null;
            this.form[reference] = value;
            if (!calculate) return;
            this.calculate();
            this.checkRequirements();
            this.debouncedUpdateUser();
        },
        debouncedUpdateUser() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.updateOrCreateUser();
            }, 10000);
        },
        onCollapse(collapse) {
            if (this.isMobile) return false;
            if (collapse) return this.dispatchEmptyResize();
            this.sidebar.direction = 'down';
            this.sidebar.updateSticky();
            return this.dispatchEmptyResize();
        },
        preselectDataCenter() {
            const country = this.userData.location || this.userData.country;
            const userDataCenter = this.dataCenters.find((item) => item.code === country);
            return userDataCenter?.code || null;
        },
    },
    async serverPrefetch() {
        await this.$store.dispatch('vroi/getPriceLists');
        this.$store.dispatch('countries/getCountries');
        this.$store.dispatch('locales/getLocaleData', { locale: this.$route.params.locale });
        this.$store.dispatch('slices/getSyncedData', { slice: 'a-phone-number-input', locale: this.$route.params.locale });
        this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-gated-form', locale: this.$route.params.locale });
        this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-fields', locale: this.$route.params.locale });
        this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-data-centers', locale: this.$route.params.locale });
        this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-product-codes', locale: this.$route.params.locale });
        this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-price-books', locale: this.$route.params.locale });
    },
};
</script>
<style lang="postcss">
.price-calculator {
    .el-tooltip {
        &__popper {
            .text {
                @mixin caption;
                font-weight: 400;
                strong {
                    @mixin caption-accent;
                }
            }
            ul {
                list-style: disc;
                list-style-position: outside;
                padding-inline-start: 8px;
                margin-inline-start: 8px;
                li {
                    &::before { display: none; }
                    margin:0;
                }
            }
        }
    }
}
</style>

<style lang="postcss" scoped>
/* z-index guide:
    * Note: usage of some higher values is based on the fact that this
        slice is going to be used separately from others in our system.

    * Backdrop for the mobile cart: 600 (to hide main-menu)
    * mobile cart itself 700 on mobile and auto on desktop to hide everything except the backdrop
    * loader 901 - to hide everything else
    * modals (contact,error,gated,greet) all have 900 on the modal itself and 500 on the close button
*/
#price-calculator {
    margin-top: 96px;
    border-block: 1px solid var(--av-brand-light);
    background: rgb(245, 247, 253);
    @media (--viewport-desktop) {
        background: linear-gradient(to left, var(--av-inversed-primary) 40%, rgb(245, 247, 253) 60%);
    }
    .backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        backdrop-filter: blur(8px);
        background: rgba(36, 49, 67, 0.9);
        content: '';
        z-index: 600;
    }
    #loading {
        position: fixed;
        z-index: 901;
        inset-inline-start: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(255, 255, 255, 1);
        span {
            @mixin body;
            position:absolute;
            top: calc(50% + 80px);
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: var(--av-fixed-primary);
            &:first-line {
                @mixin title;
                font-weight: 500;
            }
        }
    }
    .gated-image {
        &:deep(img) {
            width: 100vw;
        }
    }
    #main-content {
        display:flex;
        flex-flow: row wrap;
        align-items:flex-start;
        color: var(--av-nav-primary);
        @media (--viewport-desktop) {
            flex-flow: row nowrap;
        }
        section {
            &.main-section {
                margin-bottom:40px;
                .title {
                    @mixin display-accent;
                    margin-bottom: 24px;
                }
                .lead {
                    @mixin paragraph;
                    color: var(--av-fixed-secondary);
                }
            }
            &.additional {
                .additional-input {
                    margin-bottom: 40px;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .bottom-contact {
            @mixin paragraph;
            user-select: none;
            margin-top: 40px;
            &:deep(span) {
                cursor: pointer;
                color: var(--av-brand-primary);
                &:hover {
                    color: var(--av-brand-secondary);
                }
            }
        }
        .addons {
            margin-bottom:40px;
            .title {
                @mixin title-accent;
                margin-bottom: 24px;
                @media (--viewport-desktop) {
                    @mixin display-accent;
                }
            }
            .lead {
                @mixin paragraph;
                color: var(--av-fixed-secondary);
            }
        }
        #content {
            @mixin basic-slice-paddings;
            width: 100%;
            background: rgb(245, 247, 253);

            @media (--viewport-desktop) {
                padding-inline-end: 48px;
                border-inline-end: 1px solid var(--av-brand-light);
                width: 60%;
            }
        }
        #sidebar {
            position: sticky;
            bottom: 0;
            width: 100vw;
            background: #fff;
            padding: 16px 16px 24px;
            margin: 0 -16px;
            z-index: 700;
            box-shadow: var(--av-shadow-vertical);
            border-top: 1px solid var(--av-brand-light);
            will-change: min-height;
            @media (--viewport-mobile-wide) {
                padding: 24px 32px 32px;
                margin: 0 -32px;
            }
            @media (--viewport-desktop) {
                padding: 0;
                margin: 0;
                width: 40%;
                box-shadow: none;
                border-top: none;
                z-index: auto;
                bottom: unset;
            }
            &.is-affixed {
                .sidebar__inner{
                    padding-top: 32px;
                }
            }
            .sidebar__inner{
                transform: translate(0, 0); /* For browsers don't support translate3d. */
                transform: translate3d(0, 0, 0);
                will-change: position, transform;
                @media (--viewport-desktop) {
                    padding: 64px 48px 104px;
                    padding-inline-end: 0;
                }
                .cart-line {
                    width: auto;
                    height: 1px;
                    margin: 24px 0;
                    background: var(--av-brand-light);
                    &.short {
                        margin-bottom: 8px;
                        width:80px;
                    }
                }
                .cart-content {
                    .mobileOrder {
                        display: flex;
                        flex-flow: row wrap;
                        user-select: none;
                        .label {
                            align-items: center;
                            width: 100%;
                            display: flex;
                            font-size: 20px;
                            line-height: 24px;
                            flex-flow: row nowrap;
                            font-weight: 700;
                            color: var(--av-nav-primary);
                            @media (--viewport-mobile-wide) {
                                font-size: 20px;
                            }
                            .perMonth {
                                margin-inline-start: 8px;
                            }
                            &:deep(.a-tooltip) {
                                display: flex;
                                margin-inline-start: 8px;
                            }
                            a.expand {
                                display: flex;
                                margin-inline-start: auto;
                            }
                        }
                        .button {
                            width: 100%;
                            margin-top: 16px;
                        }
                        .link {
                            margin-top: 24px;
                            font-weight: 700;
                            text-align: center;
                            width: 100%;
                        }
                    }
                    .pricing {
                        &.mobile {
                            padding: 48px 16px 24px;
                            transform: translateY(-100%);
                            top: -17px;
                            height: calc(100vh - 230px);
                            width: 100vw;
                            margin: 0 -16px;
                            position: fixed;
                            background: var(--av-inversed-primary);
                            border-top: 1px solid var(--av-brand-light);
                            box-shadow: inset 0px -5px 10px var(--av-fixed-lightest);
                            overflow: auto;
                            overflow: -moz-scrollbars-none;
                            -ms-overflow-style: none;
                            scrollbar-width: none;
                            animation: mobile-cart-appear 0.3s;
                            @media (--viewport-mobile-wide) {
                                padding: 48px 32px 24px;
                                margin: 0 -32px;
                                top: -25px;
                                height: calc(100vh - 240px);
                            }
                            @keyframes mobile-cart-appear {
                                0% {
                                    height: 0;
                                    opacity: 0;
                                }
                                100% {
                                    height: calc(100vh - 230px);
                                    opacity: 1;
                                    @media (--viewport-mobile-wide) {
                                        height: calc(100vh - 240px);
                                    }
                                }
                            }
                        }
                        .main-title {
                            @mixin title-accent;
                            margin-bottom: 24px;
                        }
                        .licensing-model {
                            margin-bottom: 24px;
                            .head {
                                @mixin colls 12;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                margin-bottom: 24px;
                                .title {
                                    @mixin paragraph-accent;
                                }
                            }
                            .pricing-methods {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                margin-bottom: 8px;
                                .method {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    @mixin body-accent;
                                    color: var(--av-fixed-secondary);
                                    text-align:center;
                                    padding: 4px 16px;
                                    background: var(--av-inversed-primary);
                                    border: 1px solid var(--av-brand-light);
                                    border-radius: 16px;
                                    cursor: pointer;
                                    transition: all 0.3s;
                                    &:hover {
                                        background: var(--el-secondary-hover);
                                    }
                                    &:active {
                                        background: var(--el-secondary-active);
                                    }
                                    &.selected {
                                        color: var(--av-fixed-primary);
                                        background: rgba(255, 249, 230, 1);
                                        border: 1px solid rgba(255, 129, 13, 1);
                                        &.recommended {
                                            color: var(--av-fixed-primary);
                                            background: var(--av-fixed-success-accent);
                                            border: 1px solid var(--av-fixed-success-dark);
                                        }
                                    }
                                }
                            }
                            .pricing-recommendation {
                                @mixin colls 12;
                                @mixin body;
                                color: rgba(255, 129, 13, 1);
                            }
                        }
                        .order {
                            .order-group {
                                display:flex;
                                flex-flow: row wrap;
                                margin-bottom: 24px;
                                transition: all 0.3s;
                                gap: 8px;
                                .head {
                                    @mixin colls 12;
                                    @mixin body;
                                    display:flex;
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                    color: var(--av-nav-primary);
                                    font-weight: 500;
                                }
                                .options {
                                    @mixin colls 12;
                                    gap: 8px;
                                    display:flex;
                                    flex-flow: row wrap;
                                    .order-item {
                                        @mixin colls 12;
                                        display:flex;
                                        flex-flow: row nowrap;
                                        justify-content: space-between;
                                        @mixin caption;
                                        color: var(--av-fixed-light);
                                    }
                                }
                            }
                            &.compact {
                                .order-group {
                                    margin-bottom: 8px;
                                    &:last-child {
                                        margin-bottom: 24px;
                                    }
                                }
                            }
                        }
                        .minimum-warning {
                            display: flex;
                            flex-flow: row wrap;
                            align-items: flex-start;
                            justify-content: space-between;
                            animation: vroi-up 0.3s ease-in-out both;
                            background: rgba(254, 237, 217, 1);
                            padding: 16px;
                            border-radius: 4px;
                            .icon-wrapper {
                                width: 16px;
                                &:deep(.a-glyph) {
                                    vertical-align: middle;
                                    height: 24px;
                                }
                            }
                            .text {
                                @mixin descriptor;
                                width: calc(100% - 24px);
                                color: var(--av-fixed-secondary);
                                &:deep(strong) {
                                    font-weight: 700;
                                }
                            }
                        }
                        .totals {
                            display:flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            margin-bottom: 24px;
                            .label {
                                @mixin title-accent;
                            }
                            .sums {
                                text-align: end;
                                display:flex;
                                flex-flow: row wrap;
                                .perMonth {
                                    @mixin colls 12;
                                    @mixin title-accent;
                                }
                                .taxes {
                                    @mixin colls 12;
                                    @mixin caption;
                                    color: var(--av-fixed-light);
                                }
                            }
                        }
                        .actions {
                            .button {
                                @mixin colls 12;
                                font-weight: 700;
                                margin-bottom: 24px;
                            }
                            .link {
                                @mixin colls 12;
                                text-align: center;
                                &:deep(.a-link__content) {
                                    @mixin body;
                                    @media (--viewport-mobile-wide) {
                                        @mixin paragraph;
                                    }
                                }
                            }
                        }
                        .download-detailed-summary {
                            color: var(--av-fixed-secondary);
                            margin-bottom: 24px;
                            &:hover {
                                color: var(--av-brand-primary);
                            }
                            &.in-progress {
                                color: var(--av-fixed-lighter);
                                pointer-events: none;
                            }
                            .icon {
                                &.animate {
                                    animation: vroi-pdf-rotate 6s linear infinite;
                                    transform-origin: center;
                                    transform-style: preserve-3d;
                                    @keyframes vroi-pdf-rotate {
                                        25% { transform:rotate(0deg);}
                                        50% { transform:rotate(360deg);}

                                        75% { transform:rotate(360deg);}
                                        100% { transform:rotate(0deg);}
                                    }
                                }
                            }
                        }
                        .toggle-detailed-summary {
                            color: var(--av-brand-primary);
                        }
                        .download-detailed-summary,
                        .toggle-detailed-summary {
                            @mixin body;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            &:deep(.a-glyph) {
                                fill: var(--av-brand-primary);
                            }
                        }
                    }
                }
                .validation {
                    .not-filled {
                        color: var(--av-fixed-light);
                        @mixin paragraph;
                        .mobileTitle {
                            @mixin colls 12;
                            display:flex;
                            align-items: center;
                            user-select: none;
                            color: var(--av-nav-primary);
                            h5 {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 700;
                                @media (--viewport-mobile-wide) {
                                    font-size: 20px;
                                }
                            }
                            a {
                                margin-inline-start: auto;
                                display: flex;
                            }
                        }
                        .title {
                            @mixin colls 12;
                            @mixin title-accent;
                            margin-bottom: 24px;
                            color: var(--av-nav-primary);
                            animation-delay: 0.2s;
                        }
                        .requirements {
                            @mixin paragraph;
                            font-weight: 500;
                            color: var(--av-fixed-light);
                            margin-bottom: 24px;
                            margin-top: 24px;
                            @media (--viewport-desktop) {
                                margin-top: 0;
                            }
                            .requirement {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                margin-bottom:8px;
                                &:deep(.a-glyph) {
                                    fill: var(--av-fixed-lighter);
                                }
                                &:last-child {
                                    margin: 0;
                                }
                                &.checked {
                                    font-weight: 700;
                                    color: var(--av-nav-primary);
                                    &:deep(.a-glyph) {
                                        fill: var(--av-fixed-success);
                                    }
                                }
                            }
                        }
                        .assistance {
                            user-select: none;
                            margin-top: 16px;
                            @media (--viewport-desktop) {
                                margin-top: 0;
                            }
                            &:deep(span) {
                                cursor: pointer;
                                color: var(--av-brand-primary);
                                &:hover {
                                    color: var(--av-brand-secondary);
                                }
                            }
                        }
                    }
                }

                .discount {
                    .discount-wrapper {
                        display: flex;
                        flex-flow: row wrap;
                        color: var(--av-nav-primary);
                        .image {
                            margin-bottom: 24px;
                        }
                        .mobileTitle {
                            @mixin colls 12;
                            display:flex;
                            align-items: center;
                            user-select: none;
                            .image {
                                display: none;
                                max-width: 72px;
                                margin:0;
                                @media (--viewport-mobile-wide) {
                                    display: block;
                                }
                            }
                            h5 {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 700;
                                @media (--viewport-mobile-wide) {
                                    margin-inline-start: 16px;
                                    font-size: 20px;
                                }
                            }
                            a {
                                margin-inline-start: auto;
                                display: flex;
                            }
                        }
                        .title {
                            @mixin colls 12;
                            @mixin title-accent;
                            animation-delay: 0.2s;
                        }
                        .description {
                            display: block;
                            @mixin paragraph;
                            color: var(--av-fixed-secondary);
                            font-weight: 500;
                            margin-top: 16px;
                            animation-delay: 0.5s;
                            &:deep(strong) {
                                font-weight: 700;
                                color: var(--av-nav-primary);
                            }
                            @media (--viewport-desktop) {
                                font-weight: 400;
                                margin-top: 24px;
                            }
                        }
                        .button {
                            @mixin colls 12;
                            @mixin paragraph-accent;
                            margin-top: 16px;
                            @media (--viewport-desktop) {
                                margin-top: 40px;
                            }
                        }
                    }
                }
            }
        }
    }

    &:deep(.a-glyph__tooltip-o) {
        fill: #949AA3;
    }
    &:deep(.a-glyph__success-o) {
        fill: #9BC225;
    }
    &:deep(.a-glyph__warning-o) {
        fill: #FFC107;
    }
    &:deep(.a-tooltip) {
        vertical-align: middle;
        .tooltip-wrapper {
            display: flex;
        }
    }
}

[dir="rtl"] {
    #price-calculator {
        @media (--viewport-desktop) {
            background: linear-gradient(to right, var(--av-inversed-primary) 40%, rgb(245, 247, 253) 60%);
        }
    }
}

.vroi-fade-in-enter-active,
.vroi-fade-in-leave-active {
    transition: 0.3s;
}
.vroi-fade-in-enter {
    opacity: 0;
}
.vroi-fade-in-leave-to {
    opacity: 0;
}

.vroi-slide-up-enter-active,
.vroi-slide-up-leave-active {
    transition: 0.3s;
}
.vroi-slide-up-enter {
    opacity: 0;
    transform: translateY(10%);
}
.vroi-slide-up-leave-to {
    opacity: 0;
    transform: translateY(5%);
}

@keyframes vroi-up {
    0% {
        opacity:0;
        transform: translateY(20px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}
</style>
